import React, { useState, useRef, useEffect, useContext} from "react";
import { useReactToPrint } from "react-to-print";
import { LightColorDarker, lightColorLighter, lightColor, darkColor, mainColor } from "./constants/constans";
import { Button, Jumbotron } from 'react-bootstrap';
import Context from "Context/context";
import { Ticket } from "./svgs/allSvgs";




const PrintButton = ({ data, handleAddTicket, handleSubmit, contentToPrint, label, style, handlePrintingError, handleAfterPrint, handleBeforePrint, handleOnBeforeGetContent }) => {
    const componentRef = useRef();
    const {ticketToPrint} = useContext(Context)
    const [print , setPrinting ] = useState(false)

 

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        onError: handlePrintingError,
        silent: true, 
    });

    const printHandler = async () => {

        console.log("data",ticketToPrint)

        const is_android = localStorage.getItem("is_android")

        if(ticketToPrint.iswindows == "0"){
          
                var innerUrl = `https://api-legacy.lacaisse.ma/PosWeb/getTicket.php?id_ticket=${ticketToPrint.id_ticket}&id_caisse=${localStorage.getItem("id_caisse")}&token_api=${localStorage.getItem("tokan")}`
                var encodedUrl = encodeURIComponent(innerUrl);
                 window.location.href = 'myapp://click?url=' + encodedUrl+`&host=${ticketToPrint?.imprimante}&port=9100`;
                 handleAfterPrint()
      

        } else {

            handlePrint();

        }
        
      
    
    };



    return (

        <div>
            {contentToPrint && (
                <div style={{ display: 'none' }}>
                    <div ref={componentRef}>
                        {contentToPrint}
                    </div>
                </div>
            )}
         {   label == "Imprimer_Ticket" && <Button type='button' style={{ background: darkColor, }} onClick={printHandler} variant='anger' className='text-white btn-rounded btn-block me-2 w-100 py-3 rounded-10 px-3'
            >{"Imprimer ticket"} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ height: "20px", width: "20px" }}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>



            </Button> }

            {   label == "Imprimer_Ticket_tele" &&    <div  onClick={printHandler} className='tele_button_item'>
                        <div className='tele_button_item_icon'>
                            <Ticket style={{ color: darkColor }} />

                        </div>
                        <div className='tele_button_item_text'>ticket</div>
                    </div> }
            
           {  label == "Imprimer_fermer" && 
                      <Button type='button' 
                        style={{
                            alignSelf: "center", justifyContent: "center",
                            borderRadius: "5px", background: darkColor, marginBottom: "20px", width:"250px"
                        }}
                        onClick={()=>{ handleSubmit()
                             handlePrint()
                        } }
                        variant='anger'
                        className='text-white btn-rounded btn-block me-2  py-3 rounded-10 px-3'
                       > 
                        <span style={{ fontWeight: 600, marginRight: "10px", fontSize: "20px" }}>
                           Fermer
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                             style={{ height: "25px", width: "25px" }}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                    </Button> }

           {  label == "Imprimer" && <Button onClick={handlePrint}>Imprimer</Button>}

        </div>
    );
};

export default PrintButton;
