import React from 'react';

import {toast,Slide ,ToastContainer} from 'react-toastify';
import ToastCard from "../ToastCard"
const toastType = {
    ADD_TOAST: 'ADD_TOAST',
    REMOVE_TOAST: 'REMOVE_TOAST',
    ERROR: 'error'
};
const defaultOptions = {
    config: {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: false,
        transition: Slide,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    }
};

const toastConfig = {...defaultOptions}

const notify = (options, toastsConfig) => {
    toastsConfig.config.toastId = toastsConfig.id;
    toast(<ToastCard {...options}/>, toastsConfig.config);
};

export const addToast = ({text}) => {
    toast(text)
    return  <ToastContainer />
};

export const removeToast = (id) => {
    return { type: toastType.REMOVE_TOAST, payload: id };
};

export const displayMessage = (message, success) => async (dispatch) => {
    success === 1 ? dispatch(addToast({text: message})) : dispatch(addToast({text: message, type: toastType.ERROR}));
};


