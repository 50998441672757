import React , {useContext , useEffect} from 'react';
import CustomerDropDown from "./pos-dropdown/CustomerDropdown";
import WarehouseDropDown from "./pos-dropdown/WarehouseDropDown";
import {Button, Row} from "react-bootstrap";

const PosHeader = React.memo((props) => {
    const { setSelectedCustomerOption, selectedCustomerOption, setSelectedOption, selectedOption, customerModel, updateCustomer, customersOptions } = props;
    return (
        <div className='top-nav'>
            <Row className="grp-select h-100">
                <CustomerDropDown setSelectedCustomerOption={setSelectedCustomerOption}
                                  selectedCustomerOption={selectedCustomerOption}
                                  customerModel={customerModel}
                                  customersOptions={customersOptions}
                                  updateCustomer={updateCustomer} />
                <WarehouseDropDown setSelectedOption={setSelectedOption}
                                   selectedOption={selectedOption} />
            </Row>
        </div>
    )
});

export default PosHeader
