import React from 'react';
import { Helmet } from 'react-helmet';

const TabTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>

    
  );
};

export default TabTitle;