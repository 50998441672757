import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Row, Table, Button, Jumbotron } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHand } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calculator from "./components/calculator"
import Context from './Context/context';
import ProducPanierPayment from "./components/panierPayment"
import { addTicket, handlePrint } from './Fetch/products';
import ButtonPanelPayment from "./components/ButtonPanelPayment"
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';
import { darkColor, lightColor, LightColorDarker } from "./components/constants/constans"
// import ReactToPrint from 'react-to-print';
import printJS from 'print-js'
import ReactToPrint from './components/ReactToPrint';
import PosTicket from 'components/TicketData';
import Lottie from 'react-lottie';
import printing_ from "./animaion/printing.json"
import { Next } from 'react-bootstrap/esm/PageItem';

function printSpecificHTML(htmlContent) {
  // Create a temporary element (e.g., a div)
  var tempElement = document.createElement('div');

  // Set the HTML content to the temporary element
  tempElement.innerHTML = htmlContent;

  // Generate a unique ID for the temporary element
  var tempId = 'printJS-' + Math.floor(Math.random() * 1000000);

  // Set the ID of the temporary element
  tempElement.id = tempId;

  // Append the temporary element to the document body (optional, depending on your use case)
  document.body.appendChild(tempElement);

  // Use print-js to print the HTML content by passing the element ID
  printJS({ printable: tempId, type: 'html', documentTitle: 'Lacaisse.ma' });

  // Remove the temporary element from the document (optional, depending on your use case)
  document.body.removeChild(tempElement);
}
// const ticketData = {
//   nom_boutique: "sushi club",
//   nemero: "05 050 050 0505 ",
//   id_ticket: "151916",
//   date_creation: "01-10-2023 21:40:10",
//   STATUS: "Payee",
//   sur_place: "Sur place",
//   montant: "251",
//   nom_client: "Hamza Hassouna",
//   patente: "222212333",
//   ice: "2343567867645",
//   article_vendus: [
//     {
//       id_ticket_cle: "922350",
//       price: "39.0",
//       uniq_price: "39.0",
//       quantity: "1.0",
//       STATUS: "Valide",
//       date_creation: "2023-10-01 21:40:10",
//       date_fin: "01-10-2023 23:02:36",
//       date_annulation: "1970-01-01 00:00:00",
//       last_update: "01-10-2023 23:02:51",
//       designation: "Gyoza (4 pi\u00e8ces) Poulet et champignons",
//       image: "image__1659112858849872.jpg",
//       remise_type: null,
//       remise_valeur: "",
//       remise_designation: "",
//       prix_article: "39",
//       prix_label: "Poulet et champignons",
//       prix_code_bar: "",
//       prix_achat: "7.63724",
//     },
//     {
//       id_ticket_cle: "922350",
//       price: "39.0",
//       uniq_price: "39.0",
//       quantity: "1.0",
//       STATUS: "Valide",
//       date_creation: "2023-10-01 21:40:10",
//       date_fin: "01-10-2023 23:02:36",
//       date_annulation: "1970-01-01 00:00:00",
//       last_update: "01-10-2023 23:02:51",
//       designation: "Gyoza (4 pi\u00e8ces) Poulet et champignons",
//       image: "image__1659112858849872.jpg",
//       remise_type: null,
//       remise_valeur: "",
//       remise_designation: "",
//       prix_article: "39",
//       prix_label: "Poulet et champignons",
//       prix_code_bar: "",
//       prix_achat: "7.63724",
//     },

//     // ... (other article entries)
//   ],
//   tva: [
//     {
//       TAUX: "10",
//       HT: "225.45",
//       TVA: "22.55",
//       TTC: "248",
//     },
//   ],
//   moyen_paiement: [
//     {
//       designation: "Esp\u00e8ces",
//       prix: "251",
//     },
//     {
//       designation: "Esp\u00e8ces",
//       prix: "251",
//     },
//   ],
//   frais_livraison: [
//     {
//       frais: "0",
//     },
//   ],
//   date_fin: "01-10-2023 23:02:36",
// };



function Caisse({ handleCloseModal }) {

  const {

    productsPanier, setProductPanier,
    typePayment, setTypePayment,
    total_price, setTotal_price,
    panierPricing, setPanierPricing,
    setSelectedCustomerOption, selectedCustomerOption, ticketToPrint, setTicketToPrint,total_price_copi,
    seller, setSeller, addToast, getIsCaisseOpen } = useContext(Context)

  const [printing, setPrinting] = useState(false)
  // const [ticketToPrint_, setTicketToPrint_] = useState(null)


  const changeTypePayment = (type) => {
    setTypePayment(type)

  }

  

  const handleOnBeforeGetContent = () => {
    console.log("handleOnBeforeGetContent" ,ticketToPrint)
  //  const res =  handleAddTicket()
  //  if(res){
    if(ticketToPrint){
      setPrinting(true)

    }
  
  //  }
    

  }
  const handleBeforePrint = () => {
    console.log("handleBeforePrint")
    if(ticketToPrint){
      setPrinting(true)

    }
    // const res = handleAddTicket()
    // if (res) {
    //   setPrinting(true)
    // }
  }

  const handlePrintingError = () => {
    handleCloseModal();
    addToast('error', "Erreur d'impression");
    setPanierPricing([]);
    setProductPanier([]);
    setSeller({});
    setSelectedCustomerOption({});

  }



  const handleAfterPrint = () => {
    console.log("handleAfterPrint")
    setPrinting(false)
    setProductPanier([]);
    setSeller(null);
    setSelectedCustomerOption();
    handleCloseModal();
    setTicketToPrint(null)
    setTicketToPrint(null)


  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: printing_,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const handleAddTicket = async () => {
    const isCaisseOpen = await getIsCaisseOpen()

    try {
      let res_imp
      const res = await addTicket(productsPanier, panierPricing, selectedCustomerOption?.value, total_price);
      console.log("res" , res)
      if (res) {
        setTicketToPrint(res)
        setProductPanier([]);
        setSeller(null);
        setSelectedCustomerOption(null);
        if(ticketToPrint){
        
          return res
        }}
      if (!res) {
        addToast('error', 'Erreur lors de la création du ticket.', { autoClose: 3000 });
        setPanierPricing([]);

      }

    } catch (error) {
      console.log("Ticket error", error);

    }
  };

  
  return (


    <Container className='' fluid>
      <Row>
        {!printing ? <Col lg={6} xxl={6} xs={6} className='ps-lg-0'>

          {/* { <Calculator total_price ={total_price}/> } */}
          {total_price - panierPricing.reduce((sum, item) => sum + item.price, 0) <= 0 ?

            <div style={{
              display: "flex",
              background: "white",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "15px",
              padding: "10px", marginTop: "20px",
              minHeight: "80%"
            }}>
              <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                  <h1  className="display_montant_dh" style={{ color: darkColor,marginBottom:"15px", fontWeight: 600, fontSize: "40px" ,textAlign:"center" }}>{total_price_copi} MAD</h1>
                  <p style={{ color: darkColor, fontWeight: 500, fontSize: "25px" }}>Cliquez sur 'Terminer' pour compléter la vente et passer à l'impression</p>
                  {/* <Button type='button' style={{ background: darkColor,}}  onClick={handleAddTicket} variant='anger' className='text-white btn-rounded btn-block me-2 w-100 py-3 rounded-10 px-3'
                  >Terminer <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{height:"20px" , width:"20px"}}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>


                
                 </Button> */}

          {  !ticketToPrint  ?  <Button type='button' style={{ background: darkColor,}}  onClick={handleAddTicket} variant='anger' className='text-white btn-rounded btn-block me-2 w-100 py-3 rounded-10 px-3'
                  >Terminer <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{height:"20px" , width:"20px"}}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>


                
                 </Button> :   
                   <ReactToPrint
                    contentToPrint={  <PosTicket data={ticketToPrint} /> } 
                    handleAfterPrint={handleAfterPrint}
                    handleBeforePrint={handleBeforePrint}
                    handleOnBeforeGetContent={handleOnBeforeGetContent}
                    handlePrintingError={handlePrintingError}
                    handleAddTicket={handleAddTicket}
                    style={{}} 
                    label="Imprimer_Ticket" />}
                  {/* <ReactToPrint /> */}
                </div>

              </Container>
            </div> :
            <div style={{ borderRadius: "15px", background: "white", padding: "10px", marginTop: "5px" }}>
              <Calculator />
            </div>
          }
        </Col> : <Col lg={6} xxl={6} xs={6} className='ps-lg-0'>

          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%", width: "100%" }}>

            <Lottie
              options={defaultOptions}
              height={250}
              width={250}



            />
          </div>
        </Col>}
        {/* <TopProgressBar /> */}

        <Col lg={6} xxl={6} xs={6} className="" style={{
          padding: "20px",
          heght: "fit-content",
          borderRadius: "30px",
          overflow: "hidden",
          border: `2px solid ${lightColor}`,
          maxHeight: "70vh",
          marginTop: "20px"
        }}>
          {/* <ButtonPanelPayment /> */}
          <ProducPanierPayment />
        </Col>

      </Row>
    </Container>


  );
}

export default Caisse;
