import React, { useContext } from "react";
import Context from "../Context/context";

import ButtonComponent from "./button.components";
import styled from 'styled-components';
import { darkColor, lightColor, LightColorDarker, mainColor, semiMainColor } from "./constants/constans";
import { Espece, CR, Cheque, Virement ,Valider , Cancel} from "./svgs/allSvgs"


const Section = styled.section`
  text-align: center;
  background-color: #D2E0FB;
  color: #C70039;
  padding: 20px;
`;

const List = styled.ul`
  padding: 0;
  margin: 2em 0;
  list-style: none;
`;

const ListItem = styled.li`
  transition: box-shadow 0.2s ease;
  backface-visibility: hidden;
  border-radius: 50%;
  color: #C70039;
  font-weight:600 ;

  display: inline-block;
  font-size: 1em;
  height: 100px;
   margin: 0 1em 1em;
  position: relative;
  text-align: center;
  text-transform: lowercase;
  width: 100px;
  line-height: 6em;


  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #C70039;
    text-decoration: none;

    &:hover {
      color: #C70039;
    }
  }

  &:hover {
    box-shadow: 0 0 0 7px #C70039;
  }

  &:before {
    transition: all 0.2s ease;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0 0 0 2px #C70039;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export default function App({mobile ,handleClickMethod , methohType}) {
    const [calculation, setCalculation] = React.useState([0]);
    const [initPrice, setInitPrice] = React.useState([0]);
    const [result, setResult] = React.useState(0);
    const [opera, setOpera] = React.useState(null);
    const [prevResult, setPrevResult] = React.useState(null);
    const { typePayment, setTypePayment, productsPanier, setProductPanier,
        total_price, setTotal_price,addToast,
        panierPricing, setPanierPricing , fideletePerClient , setFideletePerClient} = useContext(Context)

        const price =  parseFloat(
            productsPanier
                .map((object) => {
                    const price = object.id_remise ? (object.discountedPrice ) : object.price;
                    return price * object.quantity;
                })
                .reduce((acc, total) => acc + total, 0)
            // - (productsPanier[0]?.discountedValue || 0)
        ).toFixed(2)



    React.useEffect(() => {
        setCalculation([0]);
        setResult(0);
    }, []);


    React.useEffect(() => {
        // setInitPrice([total_price ? total_price : 0])
        // setInitPrice(String( parseFloat(
        //     productsPanier
        //         .map((object) => {
        //             const price = object.id_remise ? (object.discountedPrice || object.price) : object.price;
        //             return price * object.quantity;
        //         })
        //         .reduce((acc, total) => acc + total, 0)
        //     // - (productsPanier[0]?.discountedValue || 0)
        // ).toFixed(2)).split(''))
        setInitPrice(String( total_price - panierPricing.reduce((sum, item) => sum + item.price, 0)).split(''))


    }, [total_price, panierPricing])
    React.useEffect(() => {

        //console.log(opera);
        if (opera != null) {
            //console.log(calculation);
            //console.log("result --- " + result);
            let itemBeforeOperator = 0;
            let itemAfterOperator = 0;
            let operatorFound = false;
            const length = calculation.length;
            for (let i = length - 1; i >= 0; i--) {
                if (calculation[i] !== "$") {
                    if (opera === calculation[i]) {
                        operatorFound = true;
                        continue;
                    }
                    if (operatorFound) {
                        if (result !== 0) {
                            //console.log("Resultwokring")
                            itemBeforeOperator = result;
                        } else {
                            //console.log("Not Resultwokring")
                            itemBeforeOperator =
                                itemBeforeOperator === 0
                                    ? calculation[i]
                                    : calculation[i] + itemBeforeOperator;
                        }
                    } else {
                        itemAfterOperator =
                            itemAfterOperator === 0
                                ? calculation[i]
                                : calculation[i] + itemAfterOperator;
                    }
                } else {
                    if (result !== 0) {
                        //console.log("Resultwokring")
                        itemBeforeOperator = result;
                    }
                    break;
                }
            }

            const lastItem = calculation[calculation.length - 1];
            // console.log("lastItem :: " + lastItem);
            // console.log("itemBeforeOperator :: " + itemBeforeOperator);
            // console.log("itemAfterOperator :: " + itemAfterOperator);
            if (lastItem !== opera) {
                setPrevResult(prevResult);
                let updatedResult = 0;
                switch (opera) {
                    case "%":
                        updatedResult = itemBeforeOperator % itemAfterOperator;
                        setResult(updatedResult);
                        break;
                    case "/":
                        updatedResult = itemBeforeOperator / itemAfterOperator;
                        setResult(updatedResult);
                        break;
                    case "*":
                        updatedResult = itemBeforeOperator * itemAfterOperator;
                        setResult(updatedResult);
                        break;
                    case "+":
                        updatedResult =
                            parseInt(itemBeforeOperator) + parseInt(itemAfterOperator);
                        setResult(updatedResult);
                        break;
                    case "-":
                        updatedResult = itemBeforeOperator - itemAfterOperator;
                        setResult(updatedResult);
                        break;
                    default:
                        return;
                }
            }
        } else {
            return;
        }
    }, [opera, calculation]);

    // const num_handle = value => {
    //     if (calculation.length === 1 && calculation.indexOf(0) === 0) {
    //         setCalculation([value]);
    //     } else {
    //         setCalculation([...calculation, value]);
    //     }
    //     //console.log("num_handle :: " + value);
    // };
    const num_handle = value => {
        if (value === "supp") {
            // If the value is "supp," remove the last item from the calculation array
            setCalculation(prevCalculation => prevCalculation.slice(0, -1));
        }
         else if (calculation.length === 0 && calculation[0] === 0) {
            setCalculation([value]);
        } 
        
        else {
            setCalculation([...calculation, value]);
        }
    };

    const spcl_handle = value => {
        if (calculation.length === 1 && calculation[calculation.length - 1] === 0) {
            return;
        }
        switch (value) {
            case "C":
                setCalculation([0]);
                setResult(0);
                setOpera(null);
                return;
            case "%":
                updateCalculation("%");
                setOpera("%");
                break;
            case "/":
                updateCalculation("/");
                setOpera("/");
                break;
            case "+":
                updateCalculation("+");
                setOpera("+");
                break;
            case "-":
                updateCalculation("-");
                setOpera("-");
                break;
            case "*":
                updateCalculation("*");
                setOpera("*");
                break;
            case "supp":
                if (calculation.length === 1) {
                    if (result === calculation[calculation.length - 1]) {
                        setCalculation([0]);
                        setResult(0);
                    }
                }
                const newCalculation = calculation.pop();
                if (newCalculation.length) {
                    setResult(prevResult);
                    setCalculation([...calculation]);
                } else {
                    setCalculation([0]);
                    setResult(0);
                }

                return;
            case ".":
                setCalculation([...calculation, "."]);
                return;
            case "=":
                setCalculation([result]);
                setOpera(null);
                return;
            default:
                return;
        }
    };

    const changeTypePayment = (type) => {
         setCalculation([0]);
      
        if (mobile) {
          const newObject = {
            type: type,
            price: parseInt(calculation.filter((item) => item !== "$").join(''), 10) || (total_price - panierPricing.reduce((sum, item) => sum + item.price, 0)),
          };
          console.log("newObject", newObject);
      
          // Find and replace an existing item with the same type
          const existingIndex = panierPricing.findIndex((item) => item.type === type);
          if (existingIndex !== -1) {
            // Replace the existing item
            panierPricing[existingIndex] = newObject;
          } else {
            // Add the new item if it doesn't exist
            panierPricing.push(newObject);
          }
      
          setPanierPricing([...panierPricing]);
      
          const remainingAmount = total_price - panierPricing.reduce((sum, item) => sum + item.price, 0);
          setInitPrice(String(remainingAmount).split(''));
        } else {
          // Your existing logic for non-mobile case
          let newObject;

          if (type == 5 ) {
            const price_total = parseInt(calculation.filter(item => item !== "$").join(''), 10) == 0 ? total_price : parseInt(calculation.filter(item => item !== "$").join(''), 10)
            if(price_total <= fideletePerClient){
               

                  if (parseInt(calculation.filter(item => item !== "$").join(''), 10) == 0) {
                    newObject = {
                      type: type,
                      price: total_price - panierPricing.reduce((sum, item) => sum + item.price, 0),
                    };
                  } else {
                    newObject = {
                      type: type,
                      price: parseInt(calculation.filter(item => item !== "$").join(''), 10),
                    };
                  }

                  setFideletePerClient(fideletePerClient - price_total)
                // setPanierPricing([...panierPricing, newObject]);
                // const remainingAmount = total_price - [...panierPricing, newObject].reduce((sum, item) => sum + item.price, 0);
                // setInitPrice(String(remainingAmount).split(''));
                console.log("hello is good " ,fideletePerClient , parseInt(calculation.filter(item => item !== "$").join(''), 10))

            }else {
                addToast("warning", "Votre solde ne permet pas cette opération")
                return

            }
           
          } else {
            if (parseInt(calculation.filter(item => item !== "$").join(''), 10) == 0) {
                newObject = {
                  type: type,
                  price: total_price - panierPricing.reduce((sum, item) => sum + item.price, 0),
                };
              } else {
                newObject = {
                  type: type,
                  price: parseInt(calculation.filter(item => item !== "$").join(''), 10),
                };
              }
          }

        
         
      
          setPanierPricing([...panierPricing, newObject]);
          const remainingAmount = total_price - [...panierPricing, newObject].reduce((sum, item) => sum + item.price, 0);
          setInitPrice(String(remainingAmount).split(''));
          
        //   setResult(0);
        //   setOpera(null);
        }
      };
      
      

    const updateCalculation = value => {
        if (result !== 0) {
            setPrevResult(result);
            setCalculation([...calculation, "$", value]);
        } else {
            setCalculation([...calculation, value]);
        }
    };

    const showArrayCalculation = (calculation) => {
        // const calculationToShow = calculation.filter(item => item !== "$");
      
        // Check if the first element is 0 and slice it
        if (calculation.length > 1 && calculation[0] == 0) {
          return calculation.slice(1);
        } else {
          return calculation;
        }
      };

    const showCalculation = () => {
        console.log("showArrayCalculation(calculation)",showArrayCalculation(calculation))


        if (showArrayCalculation(calculation).filter(item => item !== "$") != 0) {
            return showArrayCalculation(calculation).filter(item => item !== "$");
        } else {

            return showArrayCalculation(initPrice).filter(item => item !== "$");
        }
    };

    const buttonStyle = {
        background: "#F38484"
    }

    const handleValiderMobile = ()=>{
         changeTypePayment(methohType)
         handleClickMethod()
    }

    return (

        <div>
            <div className="App_calculation">
                <div className="show-calculation">

         {        mobile ?   <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ fontSize: "25px", color: darkColor, fontWeight: 600, marginBottom: "15px" }}>Montant à encaisser :</div>

                        <div style={{ fontSize: "25px", color: darkColor, fontWeight: 600, marginBottom: "15px" }}>{total_price} MAD</div>
                    </div> :   
                    
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ fontSize: "25px", color: darkColor, fontWeight: 600,  display:"flex" , flexDirection:"column" , justifyContent:"center" }}>Montant à encaisser :</div>

                        <div style={{ fontSize: "18px", color: darkColor, fontWeight: 600, display:"flex" , flexDirection:"column" , justifyContent:"center" }}>{total_price} MAD</div>
                    </div>}

                    <div style={{ display: "flex", color: darkColor, justifyContent: "space-between" }}>
                        <div className="display_montant">Reste a payer :</div>

                        <div style={{ fontSize: "18px", color: darkColor, fontWeight: 600, display:"flex" , flexDirection:"column" , justifyContent:"center" }}>{initPrice.filter(item => item !== "$")} MAD</div>
                    </div>

                    {/* <span className="dashed-line" /> */}

                    <div style={{ display: "flex", color: darkColor, justifyContent: "space-between", paddingTop: "20px" }}>
                        <div className="display_montant">montant saisi :</div>

                        <div className="display_montant_dh">{showCalculation()} MAD</div>
                    </div>
                </div>
                <div className="button-layout button-container ">
                    <ButtonComponent handleClick={value => spcl_handle(value)} styleCustomised={{ background: "pink", color: "#9A3B3B" }}>
                        C
                    </ButtonComponent>
                    {/* <ButtonComponent handleClick={value => spcl_handle(value)}>
                        ≠
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => spcl_handle(value)}>
                        %
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => spcl_handle(value)}>
                        /
                    </ButtonComponent> */}
                    <ButtonComponent handleClick={value => num_handle(value)} styleCustomised={buttonStyle}>
                        7
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)} >
                        8
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)}>
                        9
                    </ButtonComponent>
                    {/* <ButtonComponent handleClick={value => spcl_handle(value)}>
                        ,
                    </ButtonComponent> */}
                    <ButtonComponent handleClick={value => num_handle(value)} >
                        0
                    </ButtonComponent>

                    <ButtonComponent handleClick={value => num_handle(value)}>
                        4
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)} styleCustomised={buttonStyle}>
                        5
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)}>
                        6
                    </ButtonComponent>
                    {/* <ButtonComponent handleClick={value => spcl_handle(value)}>
                        -
                    </ButtonComponent> */}
                    <ButtonComponent handleClick={value => num_handle(value)}>
                        00
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)} styleCustomised={buttonStyle}>
                        1
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)}>
                        2
                    </ButtonComponent>
                    <ButtonComponent handleClick={value => num_handle(value)}>
                        3
                    </ButtonComponent>


                    <ButtonComponent handleClick={value => num_handle(value)} styleCustomised={{ width: "100%", flexGrow: 3, ...buttonStyle }} >
                        supp
                    </ButtonComponent>


                    {/* <ButtonComponent handleClick={value => num_handle(value)}>
                        00
                    </ButtonComponent> */}
                    {/* <ButtonComponent handleClick={value => spcl_handle(value)}>
                     del
                    </ButtonComponent> */}
                    {/* <ButtonComponent handleClick={value => spcl_handle(value)}>
                        =
                    </ButtonComponent> */}


                </div>


            </div>


          { !mobile ?  <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="product-list-block_">
                    <div className="product-list-block__product">

                        <div className="button_Encaisse" onClick={() => { changeTypePayment(0) }}>
                            <div className="button_Encaisse_icon"><Espece /></div>
                            <div className="button_Encaisse_text" >Espèces</div>
                        </div>

                    </div>
                    <div className="product-list-block__product">
                        <div className="button_Encaisse" onClick={() => { changeTypePayment(1) }}>
                            <div className="button_Encaisse_icon"><CR /></div>
                            <div className="button_Encaisse_text" >CB</div>
                        </div>

                    </div>
                    <div className="product-list-block__product">
                        <div className="button_Encaisse" onClick={() => { changeTypePayment(2) }}>
                            <div className="button_Encaisse_icon"><Cheque /></div>
                            <div className="button_Encaisse_text" >Chèque</div>
                        </div>
                    </div>
                    <div className="product-list-block__product">
                        <div className="button_Encaisse" onClick={() => { changeTypePayment(4) }}>
                            <div className="button_Encaisse_icon"><Virement /></div>
                            <div className="button_Encaisse_text" >Virement</div>
                        </div>
                    </div>
                    <div className="product-list-block__product">
                        <div className="button_Encaisse" onClick={() => { changeTypePayment(5) }}>

                        <div className="button_Encaisse_icon" style={{color:darkColor , fontWeight:700}}>{fideletePerClient} DH</div>
                            <div className="button_Encaisse_text" >Fidélité</div>
                        </div>
   
                    </div>
 
                </div>
            </div> 
            :  
            <div className='tele_button' >

            <div className='tele_button_item' onClick={handleClickMethod}>
                <div className='tele_button_item_icon'>
                    {/* <Ticket style={{ color: darkColor }} /> */}
                    <Cancel style={{ color: darkColor }}/>

                </div>
                <div className='tele_button_item_text'>Annuler</div>
            </div>

            <div className='tele_button_item' onClick={handleValiderMobile }>
                <div className='tele_button_item_icon'>
                    {/* <Partage style={{ color: darkColor }} /> */}
                    <Valider style={{ color: darkColor }}/>
                </div>
                <div className='tele_button_item_text'>Valider</div>
            </div>



        </div>
            
           }

        </div>



    );
}
