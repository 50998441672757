// import React, { useState } from 'react';
// import { login } from './Fetch/products';

// const styles = {
//   gradientBackground: {
//     backgroundImage: 'linear-gradient(140deg, rgb(219, 98, 65) 0%, rgb(229, 91, 141) 100%)',
//     height: '100vh',
//     overflow: 'hidden',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   appContainer: {
//     background: '#27282d',
//     height:"460px",
//     maxHeight: '500px',
//     width: '520px',
//     // margin: '0 auto',
//     margin:"20px",
    
//     borderRadius: '5px',
//     boxShadow: '0px 4px 30px -5px rgba(0, 0, 0, 0.65)',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   inputContainer: {
//     position: 'relative',
//     marginBottom: '30px',
//     width: '340px'
//   },
//   input: {
//     fontFamily: 'Dosis, sans-serif',
//     letterSpacing: '1.3px',
//     boxSizing: 'border-box',
//     background: 'transparent',
//     width: '100%',
//     border: 'none',
//     borderBottom: '1px solid #5a5c62',
//     color: '#a6a9b5',
//     padding: '12px 6px 12px 36px',
//     fontSize: '19px',
//     outline: 'none',
//     caretColor: 'rgb(229, 91, 141)',
//     '&:focus': {
//       borderBottom: '1px solid transparent',
//       borderImage: 'linear-gradient(140deg, rgb(219, 98, 65) 0%, rgb(229, 91, 141) 100%)',
//       borderImageSlice: '1'
//     }
//   },
//   icon: {
//     color: '#5a5c62',
//     position: 'absolute',
//     left: '6px',
//     top: '50%',
//     transform: 'translateY(-50%)',
//     transition: 'color .3s'
//   },
//   button: {
//     background: '#31333a',
//     border: 'none',
//     borderRadius: '30px',
//     color: '#a6a9b5',
//     fontSize: '16px',
//     padding: '16px 36px',
//     cursor: 'pointer',
//     transition: 'all .3s',
//     marginTop: '20px',
//     marginBottom: '-30px',
//     boxShadow: '0px 4px 15px -5px rgba(0, 0, 0, 0.65)',
//     '&:hover': {
//       background: '#363940',
//       boxShadow: '0px 5px 20px -3px rgba(0, 0, 0, 0.65)'
//     }
//   },
//   errorText: {
//     color: 'red',
//     fontSize: '14px',
//     marginTop: '5px',
//   },
// };

// const Login = () => {
//   const [formData, setFormData] = useState({ email: '', password: '' });
//   const [errors, setErrors] = useState({ email: '', password: '' });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const validateForm = () => {
//     let isValid = true;
//     const newErrors = { email: '', password: '' };

//     if (!formData.email) {
//       isValid = false;
//       newErrors.email = 'Please enter your email.';
//     }

//     if (!formData.password) {
//       isValid = false;
//       newErrors.password = 'Please enter your password.';
//     }

//     setErrors(newErrors);
//     return isValid;
//   };

//   const handleLogin = async () => {
//     if (!validateForm()) {
//       return;
//     }

//     try {
//       const response = await login(formData.email, formData.password);

//       if (response.data[0].token_api && response.data[0].id_caisse) {
//         // Successful login, perform any necessary actions with the response
//         // such as storing tokens, navigating to a new page, etc.
//         setFormData({ email: '', password: '' });
//         setErrors({ email: '', password: '' });
//       } 
//     } catch (error) {
//       setErrors({ ...errors, password: 'Login failed. Please try again.' });
//       // Handle the error appropriately, e.g., display an error message or log the error
//     }
//   };

//   return (
//     <div style={styles.gradientBackground}>
//       <div style={styles.appContainer}>
//         <div style={styles.inputContainer}>
//           <input
//             type="text"
//             placeholder="Email"
//             name="email"
//             value={formData.email}
//             style={{
//               ...styles.input,
//               borderBottomColor: errors.email ? 'red' : styles.input.borderBottomColor,
//             }}
//             onChange={handleChange}
//           />
//           <i className="zmdi zmdi-account zmdi-hc-lg" style={styles.icon}></i>
//           {errors.email && <p style={styles.errorText}>{errors.email}</p>}
//         </div>

//         <div style={styles.inputContainer}>
//           <input
//             type="password"
//             placeholder="Password"
//             name="password"
//             value={formData.password}
//             style={{
//               ...styles.input,
//               borderBottomColor: errors.password ? 'red' : styles.input.borderBottomColor,
//             }}
//             onChange={handleChange}
//           />
//           <i className="zmdi zmdi-lock zmdi-hc-lg" style={styles.icon}></i>
//           {errors.password && <p style={styles.errorText}>{errors.password}</p>}
//         </div>

//         <button type="submit" onClick={handleLogin} style={styles.button}>
//           Log In
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Login;
import React, { useState } from 'react';
import { Logo } from './components/svgs/allSvgs';
import { login } from './Fetch/products';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    minWidth :"100vw",
     background: 'url(https://images.unsplash.com/photo-1556740772-1a741367b93e?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D) no-repeat',
    //background:"#b01310",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  errorText: {
        color: 'red',
        fontSize: '14px',
        margin: '5px 0 5px 0',
      },
  wrapper: {
    width: '420px',
    background: 'transparent',
    border: '2px solid white',
    backdropFilter: 'blur(60px)',
    boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
    color: '#fff',
    borderRadius: '10px',
    padding: '30px 40px',
  },
  heading: {
    fontSize: '36px',
    textAlign: 'center',
  },
  inputBox: {
    width: '100%',
    height: '60px',
    position: 'relative',
    marginTop: '20px',
  },
  input: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    border: '2px solid white',
    borderRadius: '40px',
    fontSize: '16px',
    color: '#fff',
    padding: '20px 40px 20px 20px',
  },
  inputPlaceholder: {
    color: '#fff',
  },
  rememberForgot: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14.5px',
    margin: '15px 5px 15px',
  },
  rememberCheckbox: {
    accentColor: '#fff',
    marginRight: '3px',
  },
  forgotPasswordLink: {
    color: '#fff',
    textDecoration: 'none',
  },
  forgotPasswordLinkHover: {
    textDecoration: 'none',
    color: 'black',
  },
  loginButton: {
    width: '100%',
    height: '45px',
    background: '#fff',
    border: 'none',
    outline: 'none',
    borderRadius: '40px',
    boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
    cursor: 'pointer',
    color: '#333',
    fontWeight: '700',
  },
  registerLink: {
    fontSize: '14.5px',
    textAlign: 'center',
    margin: '20px 0 15px',
  },
  registerLinkText: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: '600',
  },
  registerLinkTextHover: {
    textDecoration: 'none',
    color: 'black',
  },
};

function LoginForm() {
    const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: '', password: '' };

    if (!formData.email) {
      isValid = false;
      newErrors.email = 'Please enter your email.';
    }

    if (!formData.password) {
      isValid = false;
      newErrors.password = 'Please enter your password.';
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!validateForm()) {
      return;
    }
  
    try {
      const response = await login(formData.email, formData.password);
      console.log("login" , response)
  
      if (response[0].token_api && response[0].id_caisse) {

        setFormData({ email: '', password: '' });
        setErrors({ email: '', password: '' });
        window.location.replace("/caisseLogin")
      } else if(!response){
        // If the response doesn't contain the expected data for a successful login, show a login failure message.
        setErrors({ ...errors, password: 'Login failed. Please try again.' });
      }
    } catch (error) {
      console.log(error);
      if(error){
        setErrors({ ...errors, password: 'Login failed. Please try again.' });

      }
     
      // Handle the error appropriately, e.g., display an error message or log the error
    }
  };
  return (
    <div style={styles.container} onKeyPress={handleKeyPress}>
      <img src=""/>

      <div style={styles.wrapper}>
        <div style={{display:"flex",justifyContent:"center"}}>
        <Logo />
        </div>
       <h1 style={styles.heading}>Login</h1>
        <div style={styles.inputBox}>
          <input type="text" placeholder="Username" 
      
          name="email"
            onChange={handleChange}
          style={styles.input} required />
          
        </div>
        {errors.email && <p style={styles.errorText}>{errors.email}</p>}
        <div style={styles.inputBox}>
          <input type="password" 
              autoComplete="new-password"
          placeholder="Password" name="password"
            onChange={handleChange}
          style={styles.input} required />
         
        </div>
        {errors.password && <p style={styles.errorText}>{errors.password}</p>}
        <div style={styles.rememberForgot}>
          <label>
            <input type="checkbox" style={styles.rememberCheckbox} />
            Remember me
          </label>
          {/* <a href="#" style={styles.forgotPasswordLink}>Forgot password?</a> */}
        </div>
        <button type="submit" className="btn" style={styles.loginButton} onClick={handleLogin}>Login</button>
        <div style={styles.registerLink}>
          <p>
           Hello again !
            {/* <a href="#" style={styles.registerLinkText}> Register</a> */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;

