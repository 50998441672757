// Modal.js

import React from 'react';
import styled from 'styled-components';
import { darkColor, LightColorDarker, mainColor, semiDarkColor, semiLightColor, semiMainColor } from '../constants/constans';


const ModalContainer = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height:100%;
  background-color: white;
  border-radius: 20px;
  padding: 2px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
   box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  border:2px solid ${LightColorDarker};
`;

const Overlay = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 5;
`;

const CloseButton = styled.button`
  position: absolute;
 
  top: -1rem;
  right: 2rem;
  font-size: 2rem;
  font-weight:700;
  height:50px;
   width:50px;
  border-radius:50%;
  background:${mainColor};
  
  cursor: pointer;
  border: solid 2px ${darkColor};
  color:white;
  transform: translateX(100%);
  text-align:center;
`;

const Modal = ({ show, onClose, children }) => {
  return (
    <>
      <Overlay show={show} onClick={onClose} />
      <ModalContainer show={show}>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContainer>
    </>
  );
};

export default Modal;
