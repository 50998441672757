import React, { useCallback, useContext, useEffect, useState, useRef, memo } from 'react';
import { Card } from 'react-bootstrap';
import { FixedSizeGrid as Grid } from 'react-window';
import useSound from 'use-sound';
import Context from '../Context/context';
import { mainColor } from './constants/constans';

const cardWidth = 150; // Fixed width of each card
const cardHeight = 200; // Fixed height of each card// Example fixed width of each card
const getRandomColor = () => {
  const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#00bcd4', '#009688', '#4caf50', '#ffeb3b', '#ff9800', '#ff5722'];
  return colors[Math.floor(Math.random() * colors.length)];
}

const ProductRow = memo(({ columnIndex, rowIndex, style, data }) => {
  const { products, addToCart, isProductExistInCart, gapSize } = data;
  const productIndex = rowIndex * data.columnCount + columnIndex;

  // Initialize imgError state at the top level
  const [imgError, setImgError] = useState(false);
  const [bgColor, setBgColor] = useState(getRandomColor());

  if (productIndex >= products.length) return null;

  const product = products[productIndex];

  // Error handler for image
  const handleImgError = () => {
      setImgError(true);
      setBgColor(getRandomColor()); 
  };

  // Function to extract initials from product name
  const getInitials = (name) => {
      return name.split(' ').slice(0, 2).map(word => word[0]).join('');
  };

  // Adjust for gap
  const modifiedStyle = {
      ...style,
      left: parseFloat(style.left) + (columnIndex * gapSize),
      display: 'flex',
      justifyContent: 'center',
      padding: '1px'
  };

  return (
      <div style={modifiedStyle} className='product-custom-card' onClick={() => addToCart(product)}>
          <Card className={`position-relative h-100 `}>
              <div className="card-image-container">
                  {!imgError ? (
                      <img
                          src={product.attributes.image}
                          alt={product.attributes.name}
                          className='card-image'
                          onError={handleImgError}
                      />
                  ) : (
                    <div className="image-placeholder card-image" style={{ backgroundColor: bgColor }}>
                    {getInitials(product.attributes.name)}
                </div>
                  )}
              </div>
              <Card.Body className='px-2 pt-3 pb-1 custom-card-body'>
                  <h6 className='product-title mb-0'>{product.attributes.name}</h6>
                  <div className='product-custom-card-price' style={{ color: mainColor, fontWeight: 600 }}>
                      {product.declinaison.length && parseFloat(product.declinaison[0].price).toFixed(2)} MAD
                  </div>
              </Card.Body>
          </Card>
      </div>
  );
});



const Product = (props) => {
    const {
        posAllProducts,
        addToCart,
        cartProductIds
    } = props;

    const [containerWidth, setContainerWidth] = useState(0);
    const [gridHeight, setGridHeight] = useState(0);
    const containerRef = useRef();

    useEffect(() => {
        const updateGridHeight = () => {
            const height = window.innerHeight - 220;
            setGridHeight(height);
        };

        window.addEventListener('resize', updateGridHeight);
        updateGridHeight();

        return () => window.removeEventListener('resize', updateGridHeight);
    }, []);

    useEffect(() => {
      const updateSize = () => {
          if (containerRef.current) {
              const computedStyle = window.getComputedStyle(containerRef.current);
              const totalWidth = containerRef.current.offsetWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight);
              setContainerWidth(totalWidth);

              const height = window.innerHeight - 220; // Adjust as per your layout
              setGridHeight(height);
          }
      };

      window.addEventListener('resize', updateSize);
      updateSize();

      return () => window.removeEventListener('resize', updateSize);
  }, []);

  const getColumnCount = (width) => Math.floor(width / cardWidth);

  // let columnCount = getColumnCount(containerWidth ) -1 ;
  // let totalWidthOfItems = columnCount * cardWidth;
  // let availableSpaceForGap = containerWidth - totalWidthOfItems;
  // let numberOfGaps = columnCount +1 ;
  // let gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
  // gapSize = gapSize + (columnCount / cardWidth)
  // let actualColumnWidth = cardWidth + gapSize;
  // let rowCount = Math.ceil(posAllProducts.length / columnCount);
  // let totalHeightOfItems = rowCount * cardHeight;
  // let actualRowHeight = totalHeightOfItems / rowCount;

    let columnCount
  let totalWidthOfItems 
  let availableSpaceForGap 
  let numberOfGaps 
  let gapSize 
  let actualColumnWidth 
  let rowCount 
  let totalHeightOfItems
  let actualRowHeight 

  if(containerWidth > 1600){
     columnCount = getColumnCount(containerWidth ) -1;
     totalWidthOfItems = columnCount * cardWidth;
     availableSpaceForGap = containerWidth - totalWidthOfItems;
     numberOfGaps = columnCount +1 ;
     gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
    gapSize = gapSize + (columnCount / cardWidth) +2
     actualColumnWidth = cardWidth + gapSize;
     rowCount = Math.ceil(posAllProducts.length / columnCount);
     totalHeightOfItems = rowCount * cardHeight;
     actualRowHeight = totalHeightOfItems / rowCount;

  }else if(containerWidth > 1500 &&  containerWidth < 1600){
    columnCount = getColumnCount(containerWidth ) -1;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount +1 ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (columnCount / cardWidth) +2
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;
    actualRowHeight = totalHeightOfItems / rowCount;

  }else if(containerWidth < 1500 &&  containerWidth > 1300){
    columnCount = getColumnCount(containerWidth ) -1;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount +1 ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (columnCount / cardWidth) +2
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;
    actualRowHeight = totalHeightOfItems / rowCount;

  }else if(containerWidth < 1300 &&  containerWidth > 1200){
    columnCount = getColumnCount(containerWidth ) -1;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount +1 ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (columnCount / cardWidth) + 4
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;
    actualRowHeight = totalHeightOfItems / rowCount;  

  } else if(containerWidth < 1200 &&  containerWidth > 1100){
    columnCount = getColumnCount(containerWidth ) -1;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount +1 ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (columnCount / cardWidth) + 6
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;
    actualRowHeight = totalHeightOfItems / rowCount;  

  } else if(containerWidth < 1100 &&  containerWidth > 1000){
    columnCount = getColumnCount(containerWidth ) -1;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount +1 ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (columnCount / cardWidth) + 12
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;
    actualRowHeight = totalHeightOfItems / rowCount;  

  }else if(containerWidth < 1000 &&  containerWidth > 800){
    columnCount = getColumnCount(containerWidth ) -1;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount +1 ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (columnCount / cardWidth) + 13
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;
    actualRowHeight = totalHeightOfItems / rowCount; 

  }else if(containerWidth < 800 &&  containerWidth > 600){
    columnCount = getColumnCount(containerWidth ) ;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (containerWidth / cardWidth)  + 18
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;

  }
  else if(  containerWidth < 600 &&  containerWidth > 420){
    columnCount = getColumnCount(containerWidth ) + 1 ;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (containerWidth / cardWidth) +22 
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;

  }else if(  containerWidth < 420 &&  containerWidth > 350){
    columnCount = getColumnCount(containerWidth ) + 1 ;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (containerWidth / cardWidth) + 40
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;

  }else if(  containerWidth < 350 ){
    columnCount = getColumnCount(containerWidth ) + 1 ;
    totalWidthOfItems = columnCount * cardWidth;
    availableSpaceForGap = containerWidth - totalWidthOfItems;
    numberOfGaps = columnCount ;
    gapSize = numberOfGaps > 0 ? availableSpaceForGap / numberOfGaps : 0;
   gapSize = gapSize + (containerWidth / cardWidth) + 45
    actualColumnWidth = cardWidth + gapSize;
    rowCount = Math.ceil(posAllProducts.length / columnCount);
    totalHeightOfItems = rowCount * cardHeight;

  }



    return (
        <div ref={containerRef} className='product-list-block pt-1'>
            {containerWidth > 0 && (
                <Grid
                    columnCount={columnCount -1  }
                    columnWidth={actualColumnWidth}
                    height={gridHeight}
                    rowCount={Math.ceil(posAllProducts.length / columnCount)}
                    rowHeight={200}
                    width={containerWidth }
                    itemData={{
                        products: posAllProducts,
                        addToCart,
                      
                        columnCount,
                        gapSize
                    }}
                >
                    {ProductRow}
                </Grid>
            )}
        </div>
    );
};

export default Product;

