import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Row, Table, Button, Jumbotron } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHand } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calculator from "./components/calculator_Lock"
import Context from './Context/context';
import ProducPanierPayment from "./components/panierPayment"
import { addCaisseOverture, addTicket, handlePrint } from './Fetch/products';
import ButtonPanelPayment from "./components/ButtonPanelPayment"
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';
import { darkColor, lightColor, LightColorDarker, semiDarkColor } from "./components/constants/constans"
import ReactPrint from "./components/ReactToPrint"
import TableComponent from 'components/TableJourneToPrint';


function Caisse() {

    const {

        ticketZ,
        seller, setSeller, addToast, setMontantAppLock, getIsCaisseOpen, isCaisseOpen_state, addCaisseClose, setIsCloseCaisse } = useContext(Context)

    const handleSubmit = async () => {
        if (!isCaisseOpen_state) {
            const res = await addCaisseClose()

            if (res) {
                setIsCloseCaisse(false)
                setMontantAppLock(0)
                addToast("success", "Votre caisse a été fermée avec succès")

            }



        } else {
            const res = await addCaisseClose()
            if (res) {
                setIsCloseCaisse(false)
                setMontantAppLock(0)
                addToast("success", "Votre caisse a été ouverte avec succès")
            }
        }

    }

    return (

        <Container className='' fluid>
            <Row>

                {/* { <Calculator total_price ={total_price}/> } */}

                <div style={{
                    display: "flex",
                    background: "white",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRadius: "15px",
                    padding: "10px", marginTop: "20px",
                    minHeight: "0%"
                }}>
                    <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <h1 style={{ color: darkColor, fontWeight: 600, fontSize: "40px", textAlign: "center" }}>{!isCaisseOpen_state ? "Cloturer ma caisse *" : "Ouvrir ma caisse * "}</h1>
                            <p style={{ color: darkColor, fontWeight: 400, fontSize: "15px" , textAlign:"center"}}>Le fonds de caisse a avant tout pour but de clarifier le montant réel dont vous disposez en espèces dans votre tiroir caisse. Cette opération consiste à compter le montant en espèce dans votre tiroir, et de le saisir. Lacaisse.ma vous permettra ensuite de vérifier que le montant que vous avez saisie correspondant bien à ce que vous êtes sensé avoir. </p>

                        </div>
                    </Container>
                </div>
                <div style={{ borderRadius: "15px", background: "white", padding: "10px", marginTop: "5px" }}>
                    <Calculator Applock={true} />
                </div>

                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    {!isCaisseOpen_state ? <ReactPrint contentToPrint={<TableComponent data={ticketZ} />} handleSubmit={handleSubmit} label="Imprimer_fermer" /> :
                        <Button type='button'
                            style={{
                                alignSelf: "center", justifyContent: "center",
                                borderRadius: "5px", background: darkColor, marginBottom: "20px", width: "40%"
                            }}
                            onClick={handleSubmit}
                            variant='anger'
                            className='text-white btn-rounded btn-block me-2  py-3 rounded-10 px-3'
                        >
                            <span style={{ fontWeight: 600, marginRight: "10px", fontSize: "20px" }}>
                                Ouvrir
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                style={{ height: "25px", width: "25px" }}>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                        </Button>}
                </div>
                {/* <TopProgressBar /> */}
            </Row>
        </Container>
    );
}

export default Caisse;
