import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {  Button } from 'react-bootstrap';

import { addClient ,fetchClient} from '../../Fetch/products';
import Context from '../../Context/context';
const CustomerForm = (props) => {
    const { show, hide, singleCustomer, addCustomer } = props
 
    
    const [customerValue, setCustomerValue] = useState({
        name: singleCustomer ? singleCustomer[0].name : '',
        prenom: singleCustomer ? singleCustomer[0].prenom : '',
        email: singleCustomer ? singleCustomer[0].email : '',
        phone: singleCustomer ? singleCustomer[0].phone : '',
        country: singleCustomer ? singleCustomer[0].country : '',
        city: singleCustomer ? singleCustomer[0].city : '',
        address: singleCustomer ? singleCustomer[0].address : '',
        dateOfBirth: singleCustomer ? singleCustomer[0].dateOfBirth : ''
    });
    const {allClients , setAllClients,addToast ,setSelectedCustomerOption } = useContext(Context)
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        address: ''
    });

    const disabled = singleCustomer && singleCustomer[0].phone === customerValue.phone && singleCustomer[0].name === customerValue.name && singleCustomer[0].country === customerValue.country && singleCustomer[0].city === customerValue.city && singleCustomer[0].email === customerValue.email && singleCustomer[0].address === customerValue.address

    const getAllClients = async ()=>{
    
        const data = await fetchClient()
        const customerOption = data && data.map((customer) => {
            return { value: customer.id_client, label: `${customer.nom_client} ${customer?.prenom_client} (${customer?.tel_client || ""}) `};
          });
    
    
       
          setAllClients(customerOption)
          return true
    
       }

    const onChangeInput = (e) => {
        console.log(e.target.name , e.target.value)
        e.preventDefault();
        setCustomerValue(inputs => ({...inputs, [e.target.name]: e.target.value}))
        setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' }));
        setErrors('');
    };

    // const addCustomerData = (formValue) => {
    //     addCustomer(formValue, hide);
    // };

    const validateForm = () => {
        let validationErrors = {};
      
        // Validate name field
        if (!customerValue.name.trim()) {
          validationErrors.name = 'Name is required';
        }
      
        // Validate email field
        if (!customerValue.email.trim()) {
          validationErrors.email = 'Email is required';
        } else if (!customerValue.email) {
          validationErrors.email = 'Invalid email format';
        }
      
        // Validate phone field
        if (!customerValue.phone.trim()) {
          validationErrors.phone = 'Phone number is required';
        } else if (!customerValue.phone) {
          validationErrors.phone = 'Invalid phone number format';
        }
      
        // Validate other fields as needed
     
      
        setErrors(validationErrors);
        const hasErrors = Object.values(validationErrors).some((error) => error !== '');
        return hasErrors

        
      };


    const onSubmit =async  (event) => {
        

      
        event.preventDefault();
        // Check if there are any validation errors

        if (!validateForm()) {
          // Invoke addCustomerData with the updated form data
        
          try {
         
            const res = await  addClient(customerValue)

            if(res){
                const data = await fetchClient()
                const customerOption = data && data.map((customer) => {
                    return { value: customer.id_client, label: `${customer.nom_client} ${customer?.prenom_client} (${customer?.tel_client || ""}) `};
                  });
                setAllClients(customerOption)
                addToast("success","Le client a été ajouté avec succès")
                setCustomerValue({})
                hide(false) 

                setSelectedCustomerOption(customerOption.find(c=> c.value ==  res.result))
            }
          } catch (error) {
            console.log(error)
            throw error
            
          }
      
        }else {
            return
        }
    };

     const numValidate = (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};

    



    return (
        <>
            <Modal
                show={show}
                onHide={() => hide(false)}
                // backdrop="static"
                keyboard={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un client :</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='card'>
                        <div className='card-body'>
                            <Form>
                                <div className='row'>
                                    <div className='col-md-6 mb-3'>
                                        <label className='form-label'>
                                           Nom :
                                        </label>
                                        <span className='required' />
                                        <input type='text' name='name'
                                             value={customerValue.name}
                                             placeholder={"Nom"}
                                             className='form-control' autoFocus={true}
                                             onChange={(e) => onChangeInput(e)} />
                                        <span
                                            className='text-danger d-block fw-400 fs-small mt-2'>{errors['name'] ? errors['name'] : null}</span>
                                    </div>
                                    <div className='col-md-6 mb-3'>

                                        <label className='form-label'>
                                           Prénom :
                                        </label>
                                        <span className='required' />
                                        <input type='text' name='prenom' 
                                            value={customerValue.prenom}
                                             placeholder={"prénom"}
                                            className='form-control' autoFocus={true}
                                            onChange={(e) => onChangeInput(e)} />
                                       </div>
                                    <div className='col-md-6 mb-3'>
                                        <label
                                            className='form-label'>
                                           Email :
                                        </label>
                                        <span className='required' />
                                        <input type='text' name='email' className='form-control'
                                             placeholder={"email"}
                                            onChange={(e) => onChangeInput(e)}
                                            value={customerValue.email} />
                                        <span
                                            className='text-danger d-block fw-400 fs-small mt-2'>{errors['email'] ? errors['email'] : null}</span>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <label
                                            className='form-label'>
                                           Numéro de téléphone :
                                        </label>
                                        <span className='required' />
                                        <input type='text' name='phone' className='form-control' pattern='[0-9]*'
                                             placeholder={"phone"}
                                            onKeyPress={(event) => numValidate(event)}
                                            onChange={(e) => onChangeInput(e)}
                                            value={customerValue.phone} />
                                        <span
                                            className='text-danger d-block fw-400 fs-small mt-2'>{errors['phone'] ? errors['phone'] : null}</span>
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                <label className='form-label'>
                                    Date de naissance :
                                </label>
                                <input
                                    type='date'
                                    name='dateOfBirth'
                                    className='form-control'
                                    placeholder='JJ-MM-AAAA'
                                    value={customerValue.dateOfBirth}
                                    onChange={(e) => onChangeInput(e)}
                                />
                                                                        <span
                                            className='text-danger d-block fw-400 fs-small mt-2'>{errors['dateOfBirth'] ? errors['dateOfBirth'] : null}</span>
                                </div>
                                   
                                    {/* <ModelFooter onEditRecord={singleCustomer} onSubmit={onSubmit} editDisabled={disabled}
                                        addDisabled={!customerValue.name} link='/app/pos' modelhide={hide} /> */}
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                 <Button onClick={onSubmit}>Enregistrer</Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default CustomerForm;
