import { faHand, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useContext } from 'react';
import { Nav, Col } from 'react-bootstrap';
import PosCalculator from './PosCalculator';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Context from '../../Context/context';
import { mainColor, lightColor, darkColor, LightColorDarker, lightGray } from '../constants/constans';
import { Pause, Timer } from '../svgs/allSvgs';
const HeaderAllButton = (props) => {
  const { setOpneCalculator, opneCalculator, goToDetailScreen, goToHoldScreen, setOpenPanel, openPanel, handleCloseCaisse, toOpen } = props
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [holdListData, setHoldListData] = useState([])
  const { isCloseCaisse, setIsCloseCaisse, productsPanier, setProductPanier,addToast } = useContext(Context)

  const fullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const handleOpenPanel = () => {
    setOpenPanel(!openPanel)
  }

  const opneCalculatorModel = () => {
    if (opneCalculator) {
      setOpneCalculator(false)
    } else {
      setOpneCalculator(true)
    }
  }


  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'holdPanier') {
        const holdPanier = JSON.parse(event.newValue);
        setHoldListData(holdPanier || []);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleGoToDetail = (e) => {
    if(toOpen){
      addToast("warning", "Vous devez ouvrir la caisse")
      return
    }
    e.stopPropagation();
    goToDetailScreen()
  }

  const handleHoldScreen = (e) => {
    if(toOpen){
      addToast("warning", "Vous devez ouvrir la caisse")
      return
    }
    e.stopPropagation();
    goToHoldScreen()
  }

  return (
    <>
      {/* <Col className='pos-header-btn'> */}
      <Nav>
        <div
          onClick={handleHoldScreen}
          style={{
            height: "60px",
            width: "60px",
            background: lightColor,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Center vertically
            marginRight: "5px"
          }}>
          {/* <FontAwesomeIcon icon={faList} className='fa-2x' style={{color:darkColor}}/> */}
          <Pause style={{ color: darkColor }} />
        </div>
        <div
          onClick={handleGoToDetail}
          style={{
            height: "60px",
            width: "60px",
            background: LightColorDarker,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            marginRight: "5px",
            alignItems: "center", // Center vertically
          }}>
          <Timer style={{ color: darkColor }} />
        </div>

       {!toOpen ?  <div
          onClick={(e) => {
            e.stopPropagation();
            handleCloseCaisse()
          }}
          style={{
            // height: "60px",
            // width: "60px",
            color: "white",
            fontWeight: 700,
            background: mainColor,
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            marginRight: "5px",
            alignItems: "center", // Center vertically
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ height: "30px", width: "30px", fontWeight: 700 }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>

          Cloturer ma caisse
        </div> : <div
          onClick={(e) => {
            e.stopPropagation();
            handleCloseCaisse()
          }}
          style={{
            // height: "60px",
            // width: "60px",
            color: "white",
            fontWeight: 700,
            background: "green",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            marginRight: "5px",
            alignItems: "center", // Center vertically
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ height: "30px", width: "30px", fontWeight: 700 }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>

          Ouvrir ma caisse
        </div> }


        {/* <Nav.Item className='ms-3 d-flex align-items-center justify-content-center'>
                {isFullscreen === true ?
                    <i className="bi bi-fullscreen-exit cursor-pointer text-white fs-1"
                       onClick={() => fullScreen()}/>
                    :
                    <i className="bi bi-arrows-fullscreen cursor-pointer text-white con fs-1"
                       onClick={() => fullScreen()}/>
                }
            </Nav.Item> */}
        {/* {Calculator} */}
        {/* <Nav.Item className='d-flex align-items-center justify-content-center ms-3'>
                <i class="bi bi-calculator cursor-pointer text-white fa-2x"
                   onClick={opneCalculatorModel}/>
            </Nav.Item> */}

        {/* panierLocal */}

        {/* <Nav.Item className='d-flex align-items-center position-relative justify-content-center ms-3 nav-green d-block d-md-none'>
  <Nav.Link className='pe-0 ps-1 text-white' onClick={handleOpenPanel}>
    <FontAwesomeIcon icon={faShoppingCart} className='fa-2x' style={{ color: '#1A6600' }}/>
  </Nav.Link>
  <div className='hold-list-badge'>{productsPanier.length ? productsPanier.length : 0}</div>
</Nav.Item> */}

        {/*{dashboard redirect icon}*/}
        {/* <Nav.Item className='d-flex align-items-center justify-content-center ms-3'>
                <Nav.Link href='/#/' className='pe-0 ps-1 text-white'>
                    <i className="bi bi-speedometer2 cursor-pointer fa-2x"/>
                </Nav.Link>
            </Nav.Item> */}
      </Nav>
      {/* </Col> */}
      {opneCalculator && <PosCalculator />}
    </>

  )
};

export default HeaderAllButton;
