
import React , {useState} from "react"
import { mainColor , lightColorLighter ,lightColor ,  darkColor } from "./constants/constans";
import moment from "moment"


const tableStyle = {
    // backgroundColor: lightColorLighter, // Change to the desired background color
    border: `2px solid black`, // Add a border with the main color
   
    fontWeight:800,
    with:"100%",
    fontSize:"16px",
    minWidth:"350px"
    // color:darkColor // Adjust padding as needed
  };
  
  const title= {
    color:"black"
  }

const TableComponent = ({data}) => {
    const [displayedKeys, setDisplayedKeys] = useState([]);

 
  
    if (data === undefined || data === null) {
        return null; // or a loading/error message, or any other fallback content
      }
    return (


    <div>
        <div className="container"> 
       <h2>Détails du registre ({moment(Date())?.format('DD MM YYYY')})</h2> 
        </div>
       
       {  data?.etat  && data?.etat.length > 0 && (
    <div className="container">
      <h4 style={title}>Etat</h4>
      <table style={tableStyle} className=" ">
        <tbody>
          {Object.entries(data.etat[0]).map(([key, value], index) => (
            <tr key={index}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}

  {data?.moyennes_paiement?.length > 0 && (
    <div className="container">
      <h4 style={title}>Moyennes Paiement</h4>
      <table  style={tableStyle} className=" ">
        <thead>
          <tr>
            <th>Type Paiement</th>
            <th>Montant</th>
          </tr>
        </thead>
        <tbody>
          {data.moyennes_paiement.map((item, index) => (
            <tr key={index}>
              <td>{item.type_paiement}</td>
              <td>{item.montant}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}


   {/* {data?.remise.length > 0  &&  <div className="container">
    <h4 style={title}>Remise</h4>
    <table  style={tableStyle} className=" ">
      <tbody>
        {Object.entries(data.remise[0]).map(([key, value], index) => (
          <tr key={index}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>} */}
  {data.remise?.length > 0 && (
  <div className="container">
    <h4 style={title}>Remise</h4>
    <table style={tableStyle} className=" ">
      <tbody>
        {data.remise.map((item, index) => (
          <React.Fragment key={index}>
            <tr>
              <td>{item.designation_remise} ( x {item.quantite})</td>
              <td>{`Type: ${item.type || ""}`}</td>
              <td>{`${item.montant} MAD`}</td>
        
            
            </tr>
            {/* Add the following line if you want a separator between rows */}
            {/* <tr><td colSpan="4"><hr /></td></tr> */}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)}


 { data?.fonds?.length > 0 && <div className="container">
    <h4 style={title}>Fonds</h4>
    <table  style={tableStyle} className=" ">
      <tbody>
        {Object.entries(data.fonds[0]).map(([key, value], index) => (
          <tr key={index}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>}

{/* { data.tva.length > 0 &&  <div className="container">
  <h4 style={title}>TVA</h4>
  <table  style={tableStyle} className=" ">
    <tbody>
      {data.tva.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          {<tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
      
    </tbody>
  </table>
</div>} */}
{data?.tva?.length > 0 && (
  <div className="container">
  <h4 style={title}>TVA</h4>
  <table style={tableStyle} className="">
    <thead>
      <tr>
        <th>TTC</th>
        <th>Taux</th>
        <th>TVA</th>
        <th>HT</th>
      </tr>
    </thead>
    <tbody>
      {data.tva.map((item, index) => (
        <tr key={index}>
          <td>{item.TTC}</td>
          <td>{item.taux}</td>
          <td>{item.TVA}</td>
          <td>{item.HT}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
)}


{data?.mouvement?.length > 0 && <div className="container">
  <h4 style={title}>Mouvements</h4>
  <table  style={tableStyle} className=" ">
    <tbody>
      {data.mouvement.length != 0 && data.mouvement?.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          { <tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>}

{data?.meilleures_articles?.length > 0 && (
  <div className="container">
    <h4 style={title}>Meilleures articles</h4>
    <table style={tableStyle} className=" ">
      <tbody>
        {data.meilleures_articles.map((item, index) => (
          <React.Fragment key={index}>
            <tr>
              <td>
                {`${item.designation || item.label} x ( ${item.quantite} )`}
              </td>
              <td>{`Prix: ${item.prix}`}</td>
            </tr>
            {/* <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr> */}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)}


{/* {data.meilleures_articles_par_categorie.length> 0 && <div className="container">
  <h4 style={title}>Meilleures articles par categorie</h4>
  <table   style={tableStyle} className=" ">
    <tbody>
      {data.meilleures_articles_par_categorie.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          { <tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>} */}
{data?.meilleures_articles_par_categorie?.length > 0 && (
  <div className="container">
    <h4 style={title}>Ventes par catégorie</h4>
    <table style={tableStyle} className=" ">
      <tbody>
        {data.meilleures_articles_par_categorie.map((item, index) => (
          <React.Fragment key={index}>
            <tr>
              <td>
                {`${item.designation || item.label} x ( ${item.quantite} )`}
              </td>
              <td>{`Prix: ${item.prix}`}</td>
            </tr>
            {/* <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr> */}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)}





{/* {data.article_annulee.length >0 && <div className="container">
  <h4 style={title}>Article annulee</h4>
  <table  style={tableStyle} className="  ">
    <tbody>
      {data.article_annulee.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          {<tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>
} */}
{data?.article_annulee?.length > 0 && (
  <div className="container">
    <h4 style={title}>Article annulée</h4>
    <table style={tableStyle} className=" ">
      <tbody>
        {data.article_annulee.map((item, index) => (
          <React.Fragment key={index}>
            <tr>
              <td >
                {`${item.designation || item.label} x ${item.quantite}`}
              </td>
              <td>{`Prix: ${item.prix} MAD`}</td>
            </tr>
            {/* <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr> */}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)}
{/* {data.indicateur.length > 0 && (
  <div className="container">
    <h4 style={title}>Indicateur</h4>
    <table  style={tableStyle} className="  ">
      <tbody>
        {data.indicateur.map((item, index) => (
          <React.Fragment key={index}>
            {Object.entries(item).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
               
              </tr>
            ))}
           
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
)} */}


{data?.statistique?.length > 0 && <div className="container">
      <h4 style={title}>Statistique</h4>
      <table  style={tableStyle} className="  ">
        <thead>
          <tr>
            <th> titre</th>
            <th>valeur</th>
          </tr>
        </thead>
        <tbody>
          {data.statistique.map((item, index) => (
            <tr key={index}>
              <td>{item.cle}</td>
              <td>{item.valeur}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>}
    </div>
   
    );

  };

  export default TableComponent