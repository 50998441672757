import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap-v5';
import { Button } from 'react-bootstrap';
import TabTitle from './components/TabTitle';
import PosHeader from './components/posHeader';


import ProductCartList from './components/ProductCartList';
import Category from './components/Category';
import Brands from './components/Brands';
import Product from './components/product';
import ProductDetailsModel from './components/ProductDetailsModel';
import CartItemMainCalculation from "./components/cartItemMainCalculation"
import PaymentButton from './components/paymentButton';
import ProductSearchbar from "./components/pos-dropdown/ProductSearchbar"
import HeaderAllButton from "./components/pos-dropdown/HeaderAllButton"
import HoldListModal from './components/Modal/HoldListModal';
import RegisterDetailsModel from "./components/Modal/RegisterDetailsModel"
import CustomerForm from './components/Modal/CustomForm';
// import CashPaymentModel from './components/Modal/CashPaymentModel';
import {
    fetchSeller,
    fetchAllProducts,
    fetchAllCategories,
    fetchAllFamilles,
    fetchDiscount,
    fetchClient,
    fetchCaisseOpen,
    fetchTicketZ
} from './Fetch/products';
import Provider from './Context/provider';
import Context from './Context/context';
import ModalDetailProduct from './components/Modal/ModalDetailProduct';
import FullScreenPanelModal from "./components/Modal/fullScreenPanelModal"
import loader from "./animaion/loader.json"
import NoData from "./animaion/noData.json"
import Closed from "./animaion/closed.json"
import Lottie from 'react-lottie';
import { FaSignOutAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as ButtonFloat, lightColors } from "react-floating-action-button";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Keyboard from './components/keyboard';
import ModalVendeur from "./components/Modal/ModalVendeur"
import ModalUpdateProduct from 'components/Modal/ModalUpdatePrice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ToastCard from "./components/ToastCard"
import ModalMiddle from "./components/Modal/ModalMiddle"
import { darkColor } from './components/constants/constans';
import FullScreenModal from "./components/Modal/fullScreenModal"
import AppLock from "./AppLock"

const logoutBarStyle = {
    backgroundColor: '#FFE6C7', // Red background with opacity
    padding: '2px', // Add some padding to the bar
    display: 'flex',
    justifyContent: 'space-between', // Align the button to the right
};

const floatingMessageContainer = {
    position: "fixed",
    bottom: 18,

    left: "calc(50% )",
    transform: "translateX(-50%)",
    zIndex: 1000 // Adjust the z-index as needed
}


const posAllProducts = [
    {
        id: 1,
        attributes: {
            name: 'Product 1',
            code: 'ABC123',
            stock: {
                quantity: 10
            },
            product_unit_name: {
                name: 'pcs'
            },
            product_price: 9.99
        }
    },
    {
        id: 2,
        attributes: {
            name: 'Product 2',
            code: 'DEF456',
            stock: {
                quantity: 5
            },
            product_unit_name: {
                name: 'kg'
            },
            product_price: 19.99
        }
    },
    // Add more product objects as needed
];

const holdListData = [
    {
        id: 1,
        attributes: {
            date: "2023-05-25",
            reference_code: "REF001"
        }
    },
    {
        id: 2,
        attributes: {
            date: "2023-05-26",
            reference_code: "REF002"
        }
    }
];

const posAllTodaySaleOverAllReport = {
    today_total_products_sold: [
        {
            reference_code: "PRO001",
            name: "Product 1",
            total_quantity: 5,
            grand_total: 100.0
        },
        {
            reference_code: "PRO002",
            name: "Product 2",
            total_quantity: 3,
            grand_total: 50.0
        }
    ],
    today_brand_report: [
        {
            name: "Brand 1",
            total_quantity: 8,
            grand_total: 200.0
        },
        {
            name: "Brand 2",
            total_quantity: 4,
            grand_total: 150.0
        }
    ],
    today_sales_cash_payment: 500.0,
    today_sales_cheque_payment: 200.0,
    today_sales_bank_transfer_payment: 300.0,
    today_sales_other_payment: 100.0,
    today_sales_total_amount: 1100.0,
    today_sales_total_return_amount: 100.0,
    today_sales_payment_amount: 1000.0,
    all_discount_amount: 50.0,
    all_tax_amount: 150.0,
    all_shipping_amount: 50.0,
    all_grand_total_amount: 900.0
};



function Caisse() {
    const componentRef = useRef();
    const registerDetailsRef = useRef();
    const { isCloseCaisse,
        setIsCloseCaisse,
        productsPanier,
        setMontantAppLock,
        setProductPanier,
        addToast,
        setAllSellersContext,
        isCaisseOpen_state,
        setIsCaisseOpen_state,
        getIsCaisseOpen,
        allProductsContext,
        ticketZ,
        setTicketZ,
        total_price,
        id_caisse_table,
        setAllProductsContext } = useContext(Context)
    // const posAllProducts = 17
    // const [play] = useSound('https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3');
    const [opneCalculator, setOpneCalculator] = useState(false)
    const [quantity, setQuantity] = useState(1);
    const [showHoldName, setShowHoldName] = useState(false)
    const [updateProducts, setUpdateProducts] = useState([

        {
            id: 1,
            name: 'Product 1',
            quantity: 2,
            code: 'shoes',
            price: 50
        },
        {
            id: 2,
            name: 'Product 2',
            quantity: 3,
            code: 'P002',
            price: 90
        },
        // Add more mock data as needed

    ]);
    const [showModal, setShowModal] = useState(false);
    const [isOpenCartItemUpdateModel, setIsOpenCartItemUpdateModel] = useState(false);
    const [AllProducts, setAllProducts] = useState([])
    const [AllCategries, setAllCategories] = useState([])
    const [AllFamilles, setAllFamilles] = useState([])
    const [product, setProduct] = useState(null);
    const [cartProductIds, setCartProductIds] = useState([]);
    const [newCost, setNewCost] = useState('');
    // const [paymentPrint, setPaymentPrint] = useState({});
    // const [cashPayment, setCashPayment] = useState(false);
    // const [modalShowPaymentSlip, setModalShowPaymentSlip] = useState(false);
    const [modalShowCustomer, setModalShowCustomer] = useState(false);
    const [productMsg, setProductMsg] = useState(0);
    const [brandId, setBrandId] = useState();
    const [categoryId, setCategoryId] = useState();
    const [selectedCustomerOption, setSelectedCustomerOption] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [updateHolList, setUpdateHoldList] = useState(false)
    const [hold_ref_no, setHold_ref_no] = useState('')
    const [holdShow, setHoldShow] = useState(false);
    const [productWithMultipleDeclainison, setProductWithMultipleDeclainison] = useState([])
    const [showProductDetail, setShowProductDeatail] = useState(false)
    const [allClients, setAllClients] = useState([])
    const [openPanel, setOpenPanel] = useState(false)
    const [cartItemValue, setCartItemValue] = useState({
        discount: 0,
        tax: 0,
        shipping: 0
    });
    const [openModalProductSeller, setOpenModalProductSeller] = useState(false)
    const [allClient, setAllClient] = useState([])
    const [lgShow, setLgShow] = useState(false);
    const [discountOption, setDiscountOption] = useState([])
    const [productToSeller, setProductToSeller] = useState({})
    const [loaderProduct, setLoaderProduct] = useState(false)
    // setItemToUpdatePrice(item)
    // setIsOpenModalUpdatePrice(true);
    const [itemToUpdatePrice ,setItemToUpdatePrice ] = useState(false)
    const [isOpenModalUpdatePrice ,setIsOpenModalUpdatePrice ] = useState(false)
    const [cashPaymentValue, setCashPaymentValue] = useState(
        { notes: '', payment_status: { label: "dashboard.recentSales.paid.label", value: 1 }, });

    const localCart = updateProducts.map((updateQty) => Number(updateQty.quantity));
    const totalQty = localCart.length > 0 && localCart.reduce((cart, current) => {
        return cart + current
    });
    





    //subtotal on cart item


    const [holdListId, setHoldListValue] = useState({
        referenceNumber: ''
    });

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptions_ = {
        loop: true,
        autoplay: true,
        animationData: NoData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOprtionClosed = {
        loop: true,
        autoplay: true,
        animationData: Closed,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }

    }




    //grand total on cart item
    const discountTotal = 0 - cartItemValue.discount
    const taxTotal = discountTotal * cartItemValue.tax / 100
    const mainTotal = discountTotal + taxTotal
    const grandTotal = (Number(mainTotal) + Number(cartItemValue.shipping)).toFixed(2);





    const getAllProducts = async () => {
        setLoaderProduct(true)
        const data = await fetchAllProducts(categoryId, brandId)
        console.log("data", data)
        const updatedData = data.map(d => {
            return {
                ...d, personnelSeller: "-1"
            }
        })
        setAllProducts(updatedData)
        setAllProductsContext(updatedData)
        setLoaderProduct(false)

    }

    const onClickProductSeller = (item) => {
        console.log("seeler ", item)
        setProductToSeller(item)
        setOpenModalProductSeller(!openModalProductSeller)

    }

    const toggleShowHoldName = () => {

        setShowHoldName(!showHoldName)

    }

    const toggleShowUpdatePrice = ()=>{
        setIsOpenModalUpdatePrice(!isOpenModalUpdatePrice)
    }

    const getAllCategories = async (id) => {
        const data = await fetchAllCategories(id)
        setAllCategories(data)

    }

    const getAllFamilles = async () => {
        const data = await fetchAllFamilles()
        setAllFamilles(data)

    }

    const toggleProductDetail = (item) => {

        setProductWithMultipleDeclainison(item)

        setShowProductDeatail(!showProductDetail)

    }

    const handleDiscount = async () => {
        const res = await fetchDiscount()
        console.log(res)
        const options = res.map(r => {

            return {

                label: r.designation_remise + `( ${r.valeur} ${r.type == "pourcentage" ? "%" : ""}) `,
                value: r.id_remise,
                type: r.type,
                discountValue: r.valeur

            }

        })
        setDiscountOption(options)

    }



    const getAllClients = async () => {
        const data = await fetchClient()
        setAllClient(data)

    }
    const getAllSellers = async () => {

        const data = await fetchSeller()
        const sellersOption = data && data.map((seller) => {
            return { value: seller.id_serveur, label: seller.nom_serveur };
        });
        setAllSellersContext(sellersOption)
    }

    const getTicketZ = async () => {

        const data = await fetchTicketZ(id_caisse_table)
        setTicketZ(data)

    }


    useEffect(() => {
        getIsCaisseOpen()
    }, [])

    useEffect(() => {
        getAllClients()
        handleDiscount()
        console.log("productsPanier", productsPanier)
        getAllFamilles()
        getAllCategories()
        getAllSellers()
        getTicketZ()
    }, []);

    useEffect(() => {
        // console.log("fetch" ,productsPanier)
        getAllProducts()

    }, [brandId, categoryId])

    useEffect(() => {

        getAllCategories(brandId)

    }, [brandId, categoryId])

    // useEffect(() => {
    //     setUpdateProducts(updateProducts);
    // }, [quantity, grandTotal]);

    useEffect(() => {
        const holdPanier = localStorage.getItem("holdPanier")
        if (!holdPanier) {
            localStorage.setItem("holdPanier", "[]")
        }

    }, [])

    useEffect(() => {

    }, [])

    const posFetchProduct = () => {
        console.log("hey")

    }

    const onUpdateItem = (item)=>{
        setItemToUpdatePrice(item)
        setIsOpenModalUpdatePrice(true);

    }


    const onClickUpdateItemInCart = (item) => {
        setProduct(item);
        setIsOpenCartItemUpdateModel(true);
    };

    const updateCart = (cartProducts) => {
        setUpdateProducts(cartProducts);
    };

    const setCategory = (item) => {
        setCategoryId(item)
    };

    const updatedQty = (qty) => {
        setQuantity(qty);
    };
    const updateCost = (item) => {
        setNewCost(item);
    };
    const setBrand = (item) => {
        setBrandId(item);
    };

    const addToCarts = (items) => {
        updateCart(items);
    };

    const onDeleteCartItem = (productId) => {
        const existingCart = updateProducts.filter((e) => e.id !== productId)
        updateCart(existingCart);
    };

    const openProductDetailModal = () => {
        setIsOpenCartItemUpdateModel(!isOpenCartItemUpdateModel);
    };
    const onProductUpdateInCart = () => {
        const localCart = updateProducts.slice();
        updateCart(localCart);
    };
    const onClickDetailsModel = (isDetails = null) => {
        getTicketZ(id_caisse_table)
        setLgShow(true)
    };

    const onClickHoldModel = (isDetails = null) => {
        setHoldShow(true)
    };
    const onChangeInput = (e) => {
        e.preventDefault();
        setCashPaymentValue(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    };
    const onPaymentStatusChange = (obj) => {
        setCashPaymentValue(inputs => ({ ...inputs, payment_status: obj }));
    };

    const customerModel = () => {
        setModalShowCustomer(!modalShowCustomer)

    }

    const addToCart = (item) => {
       
        // play();
        if (item.declinaison.length > 1) {
            console.log("categoryId", item)

            toggleProductDetail(item)


        } else 
        if (item.declinaison.length == 1) {
            const product =
            {
                id: item.declinaison[0].id_prix,
                id_produit: item.id,
                id_prix: item.declinaison[0].id_prix,
                name: item.attributes.name,

                personnelSeller: "-1",
                id_categorie: item.id_categorie,

                code: item.declinaison[0].code,
                price: item.declinaison[0].price,
                statut: 1
            }
            const existingProductIndex = productsPanier.findIndex((p) => p.id === product.id);
            const existingProduct = productsPanier.find((p) => p.id === product.id);



            if (existingProductIndex !== -1 && existingProduct.statut == 1) {
                const updatedPanel = [...productsPanier];

                updatedPanel[existingProductIndex].quantity += 1;


                setProductPanier(updatedPanel);
            }

            else if (existingProductIndex !== -1 && existingProduct.statut == 0) {
                const updatedPanel = productsPanier.filter(p => p.id != product.id);

                setProductPanier([...updatedPanel, { ...product, quantity: 1 }]);

            }

            else {


                setProductPanier([...productsPanier, { ...product, quantity: 1 }]);

            }

        }


    };

    const panelComponent = () => {
        return (
            <div>

                <PosHeader
                  setSelectedCustomerOption={setSelectedCustomerOption}
                  customerModel={customerModel}
                />
                <div className='left-content custom-card mb-3 ' style={{ height: " calc(100vh - 250px)" }}>
                    <div className='main-table '
                        style={{
                            overflowX: "hidden",
                            overflowY: "scroll"
                        }}

                    >
                        <Table className="mb-0 overflow-scroll">
                            <thead className='position-sticky top-0'>
                                <tr>
                                    <th>Title</th>
                                    <th
                                        colSpan='2'></th>
                                </tr>
                            </thead>
                            <tbody className='border-0'>
                                {updateProducts && updateProducts.length ? productsPanier.filter(p => p.statut != 0).map((updateProduct, index) => {
                                    return <ProductCartList
                                        discountOption={discountOption}
                                        onClickProductSeller={onClickProductSeller}
                                        category={AllCategries.find(c => c.id == updateProduct.id_categorie)?.attributes?.name || "sans catégorie"}
                                        //category = {AllCategries[0].attributes?.name}
                                        mobile={true}
                                        singleProduct={updateProduct}
                                        index={index}
                                        posAllProducts={posAllProducts}
                                        onUpdateItem={onUpdateItem}
                                        onClickUpdateItemInCart={onClickUpdateItemInCart}
                                        updatedQty={updatedQty} updateCost={updateCost}
                                        onDeleteCartItem={onDeleteCartItem} quantity={quantity}
                                        //frontSetting={frontSetting} 
                                        newCost={newCost}
                                        //allConfigData={allConfigData}
                                        setUpdateProducts={setUpdateProducts} />
                                }) : <tr>
                                    <td colSpan={4} className='custom-text-center text-gray-900 fw-bold py-5'></td>
                                </tr>}
                            </tbody>
                        </Table>
                    </div>
                    {/* <CartItemMainCalculation
                     mobile={true}
 
                        onClickUpdateItemInCart={onClickUpdateItemInCart}
                    /> */}

                    <PaymentButton
                        showModal={showModal}
                        setShowModal={setShowModal}
                        mobile={true}
                        onClickUpdateItemInCart={onClickUpdateItemInCart}


                    />
                </div>

            </div>
        )

    }


    const handleLogout = () => {
        // Clear specific items from localStorage
        localStorage.removeItem("caissier");
        localStorage.removeItem("caissier_id");

        // You can also add any additional logout-related actions here if needed

        // Redirect the user or perform any other actions after logout
        // For example, you can navigate the user to the login page
        window.location.replace("/caisseLogin"); // Replace with your actual login page URL
    };
    const handleOpenPanel = () => {
        setOpenPanel(!openPanel)
    }

    const handleCloseCaisse = () => {
        setIsCloseCaisse(!isCloseCaisse)
        setMontantAppLock(0)
    }


    return (

        !isCaisseOpen_state ?
            (<Container className=' px-3' fluid>

                <ToastCard />
                {/* <Keyboard /> */}
                <div style={floatingMessageContainer} className=" d-lg-none">
                    <div>
                        <ButtonFloat

                            styles={{
                                color: lightColors.white,
                                width: "250px",
                                backgroundColor: darkColor,
                                borderRadius: "20px",
                                display: "flex",
                                justifyContent: "space-between",

                            }}
                            onClick={handleOpenPanel}
                        >
                            <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
                                <FontAwesomeIcon icon={faShoppingCart}
                                    className='fa-2x' style={{ color: 'white', marginRight: "10px", width: "30%" }} />
                                <div style={{ color: "white", fontWeight: 500 }}>
                                    <div style={{ display: "flex" }}>Panier</div>
                                    <div>{parseFloat(total_price)} DH</div>
                                </div>
                            </div>
                            <div style={{
                                color: darkColor, fontSize: "18px"
                                , fontWeight: 500
                                , borderRadius: "50%"
                                , background: "white"
                                , padding: ""
                                , width: "30px"
                                , height: "30px"
                                , textAlign: "center"
                            }}>
                                {productsPanier.length ? productsPanier.length : 0}
                            </div>
                        </ButtonFloat>
                    </div>
                </div>
                <TabTitle title="POS" />

                <Row>
                    {/* <TopProgressBar /> */}
                    <Col lg={5} xxl={4} xs={6} className="pos-left-scs  ps-lg-0  d-none d-lg-block ">

                        <HeaderAllButton
                            getTicketZ={getTicketZ}
                            handleCloseCaisse={handleCloseCaisse}
                            showHoldName={showHoldName}
                            setShowHoldName={setShowHoldName}
                            openPanel={openPanel}
                            setOpenPanel={setOpenPanel}
                            holdListData={[]}
                            goToHoldScreen={onClickHoldModel}
                            goToDetailScreen={onClickDetailsModel}
                            // onClickFullScreen={onClickFullScreen}
                            opneCalculator={opneCalculator}
                            setOpneCalculator={setOpneCalculator} />
                        <PosHeader
                            setSelectedCustomerOption={setSelectedCustomerOption}
                            customerModel={customerModel}

                        />



                        <div className='left-content custom-card  p-3' style={{ height: " calc(100vh - 120px)" }}>
                            <div className='main-table ' style={{
                                overflowX: "hidden",
                                overflowY: "scroll"
                            }}>
                                <Table className="mb-0">
                                    <thead className='position-sticky top-0'>
                                        <tr>
                                            <th>Produit</th>
                                            {/* <th >Qtité</th> */}
                                            {/* <th>Prix</th> */}
                                            <th
                                                colSpan='2'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='border-0'>
                                        {updateProducts && updateProducts.length ? productsPanier.filter(p => p.statut != 0).map((updateProduct, index) => {
                                            return <ProductCartList
                                                discountOption={discountOption}
                                                onClickProductSeller={onClickProductSeller}
                                                category={AllCategries.find(c => c.id == updateProduct.id_categorie)?.attributes?.name || "sans catégorie"}
                                                //category = {AllCategries[0].attributes?.name}

                                                singleProduct={updateProduct}
                                                index={index}
                                                posAllProducts={posAllProducts}
                                                onUpdateItem={onUpdateItem}
                                                onClickUpdateItemInCart={onClickUpdateItemInCart}
                                                updatedQty={updatedQty} updateCost={updateCost}
                                                onDeleteCartItem={onDeleteCartItem} quantity={quantity}
                                                //frontSetting={frontSetting} 
                                                newCost={newCost}
                                                //allConfigData={allConfigData}
                                                setUpdateProducts={setUpdateProducts} />
                                        }) : <tr>
                                            <td colSpan={4} className='custom-text-center text-gray-900 fw-bold py-5'></td>
                                        </tr>}
                                    </tbody>
                                </Table>
                            </div>
                            {/* <CartItemMainCalculation
                 
                            onClickUpdateItemInCart={onClickUpdateItemInCart}
                        /> */}

                            <PaymentButton
                                showModal={showModal}
                                setShowModal={setShowModal}
                                onClickUpdateItemInCart={onClickUpdateItemInCart}

                            />
                        </div>
                    </Col>



                    <Col lg={7} xxl={8} xs={0} className='ps-lg-0 pos-right-scs min-h-screen'>
                        <div className='right-content '>
                            <div className="d-sm-flex align-items-center flex-xxl-nowrap flex-wrap">
                                <ProductSearchbar
                                    addToCart={addToCart}
                                    setUpdateProducts={setUpdateProducts}
                                    updateProducts={updateProducts}
                                    posAllProducts={AllProducts}
                                    setAllProducts={setAllProducts}
                                    handleLogout={handleLogout}
                                />
                            </div>

                            <div className='custom-card h-100 ' style={{
                                border: "1px solid black",
                                marginTop: "7px",
                                // borderBottom: "none",

                            }}>

                                <div className='custom-card h-100'>

                                    <div className='p-3' style={{ display: "flex", justifyContent: "space-between", }}>

                                        <Brands allFamilles={AllFamilles} categoryId={categoryId} setBrand={setBrand} selectedOption={selectedOption} />
                                        <Category AllCategries={AllCategries} setCategory={setCategory} brandId={brandId} selectedOption={selectedOption} />

                                    </div>

                                </div>
                                {AllProducts.length ? <Product cartProducts={updateProducts}
                                    addToCart={addToCart}
                                    categoryId={categoryId}
                                    brandId={brandId}
                                    posAllProducts={AllProducts}
                                    updateCart={addToCarts}
                                    toggleProductDetail={toggleProductDetail}
                                    showProductDetail={showProductDetail}
                                    //customCart={customCart}
                                    posFetchProduct={posFetchProduct}
                                    setCartProductIds={setCartProductIds} cartProductIds={cartProductIds}
                                    //settings={settings} 
                                    productMsg={productMsg} selectedOption={selectedOption} /> :



                                    <div style={{ height: "calc(100vh - 240px)" }}>
                                        {loaderProduct ?

                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%", width: "100%" }}>

                                                <Lottie
                                                    options={defaultOptions}
                                                    height={250}
                                                    width={250}



                                                />
                                            </div>

                                            : <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%", width: "100%" }}>

                                                <Lottie
                                                    options={defaultOptions_}
                                                    height={250}
                                                    width={250}
                                                />
                                            </div>


                                        }
                                    </div>
                                }
                            </div>
                        </div>

                    </Col>



                </Row>

                {isOpenCartItemUpdateModel &&
                    <ProductDetailsModel
                        openProductDetailModal={openProductDetailModal}
                        productModelId={product.id}
                        discountOption={discountOption}
                        // onProductUpdateInCart={onProductUpdateInCart} 
                        // updateCost={updateCost}
                        cartProduct={product}
                        isOpenCartItemUpdateModel={isOpenCartItemUpdateModel}

                    />
                }



                {lgShow && <RegisterDetailsModel
                    posAllTodaySaleOverAllReport={posAllTodaySaleOverAllReport}
                    // printRegisterDetails={printRegisterDetails} frontSetting={frontSetting}
                    lgShow={lgShow} setLgShow={setLgShow} />}
                {holdShow && <HoldListModal
                    setUpdateHoldList={setUpdateHoldList} setCartItemValue={setCartItemValue} setUpdateProducts={setUpdateProducts} updateProduct={updateProducts} showHoldName={showHoldName}
                    setShowHoldName={setShowHoldName}
                    toggleShowHoldName={toggleShowHoldName}
                    discountOption={discountOption}

                    //printRegisterDetails={printRegisterDetails} 
                    // frontSetting={frontSetting}

                    holdListData={holdListData}
                    setHold_ref_no={setHold_ref_no} holdShow={holdShow} setHoldShow={setHoldShow} addCart={addToCarts} updateCart={updateCart} setSelectedCustomerOption={setSelectedCustomerOption} setSelectedOption={setSelectedOption} />}
                {modalShowCustomer && <CustomerForm show={modalShowCustomer} hide={setModalShowCustomer} />}
                {/* {<ModalMiddle show={true}/>} */}

                {showProductDetail && <ModalDetailProduct
                    discountOption={discountOption}
                    showProductDetail={showProductDetail}
                    toggleProductDetail={toggleProductDetail}
                    products={productWithMultipleDeclainison}
                    categoryId={categoryId}
                    brandId={brandId}
                />}

            {isOpenModalUpdatePrice && <ModalUpdateProduct product={itemToUpdatePrice} isOpen={isOpenModalUpdatePrice} toggleShow={toggleShowUpdatePrice}/>}

                {openModalProductSeller && <ModalVendeur show={openModalProductSeller} hide={onClickProductSeller} setProductToSeller={setProductToSeller} productToSeller={productToSeller} />}

                <FullScreenPanelModal ModalContent={panelComponent} title={'Payment'} openPanel={openPanel} setOpenPanel={setOpenPanel} />
                <FullScreenModal
                    ModalContent={AppLock}
                    // ModalContent={mobile ?TeleCalcul : ChamgeCalcul}
                    title={'Encaisser'}
                    showModal={isCloseCaisse ? isCloseCaisse : isCaisseOpen_state}
                    AppLock={true}
                    setShowModal={isCloseCaisse ? setIsCloseCaisse : "setIsCaisseOpen_state"}
                    // price ={parseFloat(productsPanier.reduce((acc, object) => acc + ( (object.discountedPrice ? object.discountedPrice : object.price ) * object.quantity), 0) - (productsPanier[0]?.discountedValue ? productsPanier[0]?.discountedValue : 0)).toFixed(2)}
                    price={0}
                />
            </Container>) : 
            (<Container className=' px-3' fluid>

                <ToastCard />
                {/* <Keyboard /> */}
                <div style={floatingMessageContainer} className=" d-lg-none">
                    <div>
                        <ButtonFloat

                            styles={{
                                color: lightColors.white,
                                width: "250px",
                                backgroundColor: darkColor,
                                borderRadius: "20px",
                                display: "flex",
                                justifyContent: "space-between",

                            }}
                            onClick={handleOpenPanel}
                        >
                            <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
                                <FontAwesomeIcon icon={faShoppingCart}
                                    className='fa-2x' style={{ color: 'white', marginRight: "10px", width: "30%" }} />
                                <div style={{ color: "white", fontWeight: 500 }}>
                                    <div style={{ display: "flex" }}>Panier</div>
                                    <div>{parseFloat(total_price)} DH</div>
                                </div>
                            </div>
                            <div style={{
                                color: darkColor, fontSize: "18px"
                                , fontWeight: 500
                                , borderRadius: "50%"
                                , background: "white"
                                , padding: ""
                                , width: "30px"
                                , height: "30px"
                                , textAlign: "center"
                            }}>
                                {productsPanier.length ? productsPanier.length : 0}
                            </div>
                        </ButtonFloat>
                    </div>
                </div>
                <TabTitle title="POS" />

                <Row>
                    {/* <TopProgressBar /> */}
                    <Col lg={5} xxl={4} xs={6} className="pos-left-scs  ps-lg-0  d-none d-lg-block ">

                        <HeaderAllButton
                            toOpen={true}
                            getTicketZ={getTicketZ}
                            handleCloseCaisse={handleCloseCaisse}
                            showHoldName={showHoldName}
                            setShowHoldName={setShowHoldName}
                            openPanel={openPanel}
                            setOpenPanel={setOpenPanel}
                            holdListData={[]}
                            goToHoldScreen={onClickHoldModel}
                            goToDetailScreen={onClickDetailsModel}
                            // onClickFullScreen={onClickFullScreen}
                            opneCalculator={opneCalculator}
                            setOpneCalculator={setOpneCalculator} />
                        {/* <PosHeader
            setSelectedCustomerOption={setSelectedCustomerOption}
            customerModel={customerModel}

        /> */}



                        <div className='left-content custom-card  p-3' style={{ height: " calc(100vh - 80px)" }}>
                            <div className='main-table ' style={{
                                overflowX: "hidden",
                                overflowY: "scroll"
                            }}>
                                <Table className="mb-0">
                                    <thead className='position-sticky top-0'>
                                        <tr>
                                            <th>Produit</th>
                                            {/* <th >Qtité</th> */}
                                            {/* <th>Prix</th> */}
                                            <th
                                                colSpan='2'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='border-0'>
                                        {updateProducts && updateProducts.length ? productsPanier.filter(p => p.statut != 0).map((updateProduct, index) => {
                                            return <ProductCartList
                                                discountOption={discountOption}
                                                onClickProductSeller={onClickProductSeller}
                                                category={AllCategries.find(c => c.id == updateProduct.id_categorie)?.attributes?.name || "sans catégorie"}
                                                //category = {AllCategries[0].attributes?.name}

                                                singleProduct={updateProduct}
                                                index={index}
                                                posAllProducts={posAllProducts}
                                                onClickUpdateItemInCart={onClickUpdateItemInCart}
                                                onUpdateItem={onUpdateItem}
                                                updatedQty={updatedQty} updateCost={updateCost}
                                                onDeleteCartItem={onDeleteCartItem} quantity={quantity}
                                                //frontSetting={frontSetting} 
                                                newCost={newCost}
                                                //allConfigData={allConfigData}
                                                setUpdateProducts={setUpdateProducts} />
                                        }) : <tr>
                                            <td colSpan={4} className='custom-text-center text-gray-900 fw-bold py-5'></td>
                                        </tr>}
                                    </tbody>
                                </Table>
                            </div>
                            {/* <CartItemMainCalculation
     
                onClickUpdateItemInCart={onClickUpdateItemInCart}
            /> */}

                            <PaymentButton
                                showModal={showModal}
                                setShowModal={setShowModal}
                                onClickUpdateItemInCart={onClickUpdateItemInCart}

                            />


                        </div>
                    </Col>



                    <Col lg={7} xxl={8} xs={0} className='min-h-screen'>
                        <div className='right-content '>
                            <div className="d-sm-flex align-items-center flex-xxl-nowrap flex-wrap">
                                <ProductSearchbar
                                    addToCart={addToCart}
                                    setUpdateProducts={setUpdateProducts}
                                    updateProducts={updateProducts}
                                    posAllProducts={AllProducts}
                                    setAllProducts={setAllProducts}
                                    handleLogout={handleLogout}
                                />

                            </div>

                            <div className='custom-card h-100 ' style={{
                                border: "1px solid black",
                                marginTop: "7px",
                                // borderBottom: "none",

                            }}>

                                <div className='custom-card h-100'>

                                    <div className='p-3' style={{ display: "flex", justifyContent: "space-between", }}>



                                    </div>

                                </div>
                                {



                                    <div style={{ height: "calc(100vh - 180px)" }}>
                                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%", width: "100%" }}>

                                            <Lottie
                                                options={defaultOprtionClosed}
                                                height={250}
                                                width={250}
                                            />
                                        </div>



                                    </div>
                                }
                            </div>
                        </div>

                    </Col>



                </Row>

                   <FullScreenModal
                    ModalContent={AppLock}
                    // ModalContent={mobile ?TeleCalcul : ChamgeCalcul}


                    title={'Encaisser'}
                    showModal={ isCloseCaisse}
                    AppLock={true}
                    setShowModal={setIsCloseCaisse}
                    // price ={parseFloat(productsPanier.reduce((acc, object) => acc + ( (object.discountedPrice ? object.discountedPrice : object.price ) * object.quantity), 0) - (productsPanier[0]?.discountedValue ? productsPanier[0]?.discountedValue : 0)).toFixed(2)}
                    price={0}
                />

            </Container>)


    );
}



export default React.memo(Caisse);
