export const mainColor = "#D80032"
export const semiMainColor = "#"

export const lightColor = "#FFC3C3"
export const LightColorDarker = "#FFA6A6"
export const semiLightColor = "#FFDDDD"
export const lightColorLighter = "#FFEFEF"

export const darkColor = "#B73F3F"
export const semiDarkColor ="#C58F8F"

export const gray = "#858585"
export const lightGray = "#FAFAFA"







