import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {  Button } from 'react-bootstrap';
import {getFormattedMessage, placeholderText, numValidate}  from '../shared/sharedMethod';
import { addClient ,fetchClient} from '../../Fetch/products';
import Context from '../../Context/context';
import WarehouseDropDown from "../pos-dropdown/WarehouseDropDown"

const CustomerForm = (props) => {
    const { show, hide, setProductToSeller ,productToSeller } = props
    const { productsPanier, setProductPanier , addToast } = useContext(Context)

    const [seller , setSeller] = useState(null)
    const [allSellers , setAllSellers] = useState([])
 

   useEffect(()=>{
    console.log("seller" , seller ,productToSeller)


   },[seller])

   useEffect(() => {
    if (productToSeller.personnelSeller && allSellers.length > 0) {
        // Find the corresponding seller based on productToSeller.personnelSeller
        const correspondingSeller = allSellers.find(
            (seller) => seller.value === productToSeller.personnelSeller
        );

        // Update the seller state with the corresponding seller
        setSeller(correspondingSeller);
    }
}, [productToSeller, allSellers]);

   const handleSave = () => {
    // 1. Get the id_prix from the productToSeller
    const id_prix = productToSeller.id_prix;

    // 2. Find the corresponding item in productsPanier based on id_prix
    const updatedProductsPanier = productsPanier.map((item) => {
        if (item.id_prix === id_prix) {
            // 3. Update the personnelSeller property with seller.value
            return { ...item, personnelSeller: seller.value };
        }
        return item;
    });

    // Update the productsPanier state
    setProductPanier(updatedProductsPanier);
    addToast("success", "Votre vendeur a été ajouté")

    // Close the modal
    hide();
};

const handleRemove = ()=>{
    const id_prix = productToSeller.id_prix;

    // 2. Find the corresponding item in productsPanier based on id_prix
    const updatedProductsPanier = productsPanier.map((item) => {
        if (item.id_prix === id_prix) {
            // 3. Update the personnelSeller property with seller.value
            return { ...item, personnelSeller: "-1" };
        }
        return item;
    });

    // Update the productsPanier state
    setSeller(null)
    setProductPanier(updatedProductsPanier);
    addToast("success", "Votre vendeur a été supprimé")
   

}
 
 
    return (
        <>
            <Modal
                show={show}
                onHide={hide}
                // backdrop="static"
                keyboard={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>selectionner un vendeur pour l'article : {productToSeller.name} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div style={{ display:"flex" , justifyContent:"center"}}>
                    <WarehouseDropDown setSeller={setSeller} seller={seller} setAllSellers={setAllSellers} sellerToProduct={true}/>

                    </div>
             
                    
                </Modal.Body>
                <Modal.Footer>
               { productsPanier.find(p => p.id_prix == productToSeller.id_prix ).personnelSeller != "-1" && <Button variant='danger' onClick={handleRemove}>Remove vendeur</Button>}
                 <Button onClick={handleSave}>Enregistder</Button>
                 <Button onClick={hide} >Annuler</Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default CustomerForm;
