// Modal.js

import React , {useContext} from 'react';
import styled from 'styled-components';
import { darkColor, LightColorDarker, mainColor, semiDarkColor, semiLightColor, semiMainColor } from '../constants/constans';
import Context from '../../Context/context';

const ModalContainer = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow:scroll;
  width: ${(props) => (props.appLock ? '50vw' : props.mobile ? '90vw' : '75%')};
  height: ${(props) => (props.mobile ? '90vh' : 'auto')};
  background-color: white;
  border-radius: 20px;
  padding: 2px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
   box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  border:2px solid ${LightColorDarker};
`;



const Overlay = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  transform: translate(-20vw, -20vh);
  top: 0;
  left: 0;
  width: 120vw;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.6);
   backdrop-filter: ${(props)=>(props.appLock ? 'blur(30px)' :'blur(4px)')};
  z-index: 900;
`;

const CloseButton = styled.button`
  position: absolute;
 
  top: -0.9rem;
  right: 3rem;
  z-index:1901;
  font-size: 2rem;
  font-weight:700;
  height:50px;
   width:50px;
  border-radius:50%;
  // background:${mainColor};
  
  cursor: pointer;
  // border: solid 2px ${darkColor};
  color:${darkColor};
  transform: translateX(100%);
  text-align:center;
`;

const Modal = ({ show, onClose, children ,mobile}) => {
  const { isCaisseOpen_state , isCloseCaisse} = useContext(Context)

  return (
    <>
      <Overlay show={show} onClick={onClose}  mobile={mobile} appLock={isCaisseOpen_state || isCloseCaisse}/>
      <ModalContainer show={show} mobile={mobile} appLock={isCaisseOpen_state || isCloseCaisse}>
       <CloseButton mobile={mobile} onClick={onClose}>X</CloseButton>
        {children}
      </ModalContainer>
    </>
  );
};

export default Modal;
