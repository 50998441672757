import React, { useEffect, useState , useContext } from 'react'
import { currencySymbolHendling, getFormattedDate, getFormattedMessage } from '../shared/sharedMethod';
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
import Context from '../../Context/context';
// import { connect } from "react-redux";
// import { fetchTodaySaleOverAllReport } from "../../../store/action/pos/posRegisterDetailsAction";
import moment from "moment";
import { faEdit, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditHoldConfirmationModal from './EditHoldConfirmationModal';
import warning from "../../assets/images/warning.png"
import deleteItems from "../../assets/images/remove.png"
// import {fetchHoldLists, fetchHoldList, deleteHoldItem} from '../../../store/action/pos/HoldListAction';
import { v4 as uuidv4 } from 'uuid';
import {getAllTicketEnAttente} from "../../Fetch/products"
import { darkColor, semiDarkColor } from '../constants/constans';

function HoldListModal(props) {
    const { holdShow, setHoldShow, discountOption} = props;

    const [isEditHold, setIsEditHold] = useState(false)
    const [isPanierFull , setIsPanierFull] = useState(false)
    const [isDeteleHold, setIsDeteleHold] = useState(false)
    const [editHoldId, seteditHoldId] = useState(null)
    const [getEditHold, setGetEditHold] = useState(false)
    const [isDeteleHoldId, setIsDeteleHoldId] = useState(null)
    const [holdListData , setHoldListData] = useState()
    const {productsPanier , setProductPanier ,setSeller ,allSellersContext} = useContext(Context)

    const getHoldListData = async ()=>{
        try {
            const res = await getAllTicketEnAttente()
         
            setHoldListData(res)
            
        } catch (error) {
            console.log(error)
            
        }
       

    }
    useEffect(() => {
        getHoldListData()
    }, []);

    const onsetHoldShow = () => {
        setHoldShow(false)
    }

    const holdEditPaymentModel = (id) => {
        seteditHoldId(id)
        if(productsPanier.length > 0) {

            setIsPanierFull(true)

            return;
          
        }
        // Find the array that contains the matching UUID
        const ticket = holdListData.find((arr) => arr.id_list_ticket === id);
  
        if (ticket) {

       

            // ticket[0].alreadyHolded = true
            console.log("ticket en attente" , ticket)
            
            const updatedPanier = ticket.article_vendu.map(article => {
                
                return {
                    id_list_ticket : ticket.id_list_ticket,
                    code:article.code ,
                    holdname:ticket.nom_ticket , 
                    id:article.id_prix ,
                    id_prix:article.id_prix ,
                    id_produit : article.id_produit ,
                    name:article.produit,
                    price : article.prix_p,
                    quantity : article.quantity,
                    personnelSeller: article.id_user,
                    id_remise : article.id_remise,
                    discountedPrice :article.price,
                    id_ticket_cle: article.id_ticket_cle || "-1",
                    statut:1
                

                }
            })
            setProductPanier(updatedPanier || []);
            setSeller( allSellersContext.find((seller) => seller.value == ticket.id_serveur) || null)
            setIsEditHold(false);
            const filteredPanier = updatedPanier.filter((arr) => arr.id_list_ticket != id);
            setHoldListData(filteredPanier)
            // localStorage.setItem("holdPanier", JSON.stringify(filteredPanier));
            onsetHoldShow()

        }
        // onConfirm()
    }
    const holdDeletePaymentModel = (id) => {
        setIsDeteleHoldId(id)
        setIsDeteleHold(true)
    }

    const calculateDiscountedPrice = (product, discount) => {
        const discountValue = discountOption.find((option) => option.value === discount)
        const { type, value } = discountValue;
    
        if (type === "pourcentage") {
            if (value === 100) {
                return product.price;
            } else {
                const discountValue = discountOption.find((option) => option.value === value).discountValue;
                return product.price * (1 - discountValue / 100);
            }
        } else if (type === "remisel") {
            const discountedValue = parseInt(45, 10);
            return product.price - discountedValue;
        } else {
           
            return product.price; // Return the original price as a fallback
        }
    };

       
    const onConfirm = (uuid) => {
       
         const panierLocal = JSON.parse(localStorage.getItem("holdPanier")) || [];
      
        if (isEditHold) {

            if(productsPanier.length > 0) {

                setIsPanierFull(true)

                return;
              
            }
            // Find the array that contains the matching UUID
            const ticket = holdListData.find((arr) => arr.id_list_ticket === editHoldId);
      
            if (ticket) {

           

                // ticket[0].alreadyHolded = true
                
                const updatedPanier = ticket.article_vendu.map(article => {
                    
                    return {
                        id_list_ticket : ticket.id_list_ticket,
                        code:article.code ,
                        holdname:ticket.nom_ticket , 
                        id:article.id_prix ,
                        id_prix:article.id_prix ,
                        id_produit : article.id_produit ,
                        name:article.produit,
                        price : article.prix_p,
                        quantity : article.quantity,
                        personnelSeller: article.id_user,
                        id_remise : article.id_remise,
                        discountedPrice :article.price,
                        id_ticket_cle: article.id_ticket_cle || "-1",
                        statut:1
                    

                    }
                })
                setProductPanier(updatedPanier || []);
                setSeller( allSellersContext.find((seller) => seller.value == ticket.id_serveur) || null)
                setIsEditHold(false);
                const filteredPanier = updatedPanier.filter((arr) => arr.id_list_ticket != editHoldId);
                setHoldListData(filteredPanier)
                // localStorage.setItem("holdPanier", JSON.stringify(filteredPanier));
                onsetHoldShow()

            }

        } else {

          // Filter the panierLocal array based on the UUID
          const filteredPanier = panierLocal.filter((arr) => arr[0].uuid !== isDeteleHoldId);

          // Update the localStorage with the filtered panier
          localStorage.setItem("holdPanier", JSON.stringify(filteredPanier));
          setHoldListData(filteredPanier);
          setIsDeteleHold(false);

        }
      };

    const onCancel = () => {
            setIsEditHold(false)
            setIsPanierFull(false)
            setIsDeteleHold(false)
      }

    return (
        <>
            <div>
                <Modal
                    size="md"
                    aria-labelledby="example-custom-modal-styling-title"
                    show={holdShow}
                    onHide={() => onsetHoldShow()}
                    className='registerModel-content'
                >
                    <Modal.Header closeButton className='p-4'>
                        <Modal.Title id="example-modal-sizes-title-lg" style={{color:darkColor}}>
                        Liste des tickets en attente
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive bordered hover className='m-0 registerModel holdListModel'>
                            <tbody>
                                <tr style={{color:darkColor}}>
                                    {/* <th>#</th> */}
                                    <th></th>
                                    <th >Nom</th>
                                    <th >Prix </th>
                                    {/* <th >{getFormattedMessage("hold-list-ref-id.table.column.label")}</th> */}
                                    <th  colSpan={2}>Actions</th>
                                </tr>
                                {/* {holdListData && holdListData.length <= 0 ?
                                    <td colSpan={4} className={"custom-text-center"}>{getFormattedMessage("sale.product.table.no-data.label")}</td> : ''
                                } */}
                                {holdListData && holdListData.length > 0 && holdListData.map((items, index) => {
                                
                                    return (
                                        <tr key={items.id_list_ticket}>
                                            <td>{items.id_list_ticket}</td>
                                            <td>{items?.nom_ticket || "none"} { }</td>
                                            <td>{items?.prix} MAD</td>
                                            {/* <td>{items.attributes.reference_code}</td> */}
                                            <td>
                                                <FontAwesomeIcon onClick={() => holdEditPaymentModel(items?.id_list_ticket)} cursor={"pointer"} className={"me-3 edit"} icon={faPenToSquare} style={{height:"30x",width:"30px" , fontSize:"25px" , color:darkColor}}/>
                                                {/* <FontAwesomeIcon onClick={() =>holdDeletePaymentModel(items?.id_list_ticket)} cursor={"pointer"} className={"delete"} icon={faTrash} style={{height:"30x",width:"30px"}}/> */}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
            {isEditHold && <EditHoldConfirmationModal onConfirm={onConfirm} onCancel={onCancel} icon={warning} title={"Modifier le panier"} itemName={"globally.detail.product"} />}
            {isDeteleHold && <EditHoldConfirmationModal onConfirm={onConfirm} onCancel={onCancel} icon={deleteItems} title={"Supprimer le panier"} itemName={"globally.detail.product"} />}
            {isPanierFull && <EditHoldConfirmationModal onConfirm={onCancel} onCancel={onCancel} icon={warning} title={"Vous devez conserver ou vider le panier existant avant de le modifier. !"} itemName={"globally.detail.product"} />}
        </>
    )
}

const mapStateToProps = (state) => {
    const { allConfigData, holdListData, editholdListData } = state;
    return { allConfigData, holdListData, editholdListData }
};

export default HoldListModal;
