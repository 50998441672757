import React, { useEffect, useState,useCallback ,  useContext } from 'react';
import { InputGroup, Button } from 'react-bootstrap';
import Select from 'react-select';
import { fetchClient } from '../../Fetch/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Context from '../../Context/context';
import { darkColor, mainColor, lightGray } from '../constants/constans';

const CustomerDropDown = React.memo((props) => {
    const { setSelectedCustomerOption, selectedCustomerOption, allClients, setAllClients } = useContext(Context);
    const { customerModel } = props;

    const [option , setOptions] = useState([])

    const getAllClients = async () => {
        const data = await fetchClient();
        const customerOption = data && data.map((customer) => {
            return { value: customer.id_client, label: `${customer.nom_client} ${customer?.prenom_client} (${customer?.tel_client || ""})` };
        });
         setOptions(customerOption);
    };

    useEffect(() => {
      
        getAllClients();
    }, []);

    const onChangeWarehouse = useCallback((obj) => {
      setSelectedCustomerOption(obj);
  }, [setSelectedCustomerOption]);
  

    return (
        <div className='select-box col-6 pe-sm-1 position-relative px-2' style={{ height: '45px', borderRadius: '10px', background: lightGray, border: `solid 1px ${mainColor}`, width: "47%" }}>
            <InputGroup className='d-flex flex-nowrap align-items-center' style={{ height: '100%', display: 'flex' }}>
                <div
                    onClick={() => customerModel(true)}
                    id='basic-addon1'
                    style={{ height: '100%', backgroundColor: 'white', border: 'none' }}
                    className='bg-transparent position-absolute border-0 z-index-1 input-group-text py-4 px-3'
                >
                    <FontAwesomeIcon icon={faUserPlus} style={{ color: mainColor }} />
                </div>
                {<Select
                    className='w-100'
                    placeholder=' client'
                    defaultValue={selectedCustomerOption}
                    value={selectedCustomerOption}
                    onChange={onChangeWarehouse}
                    options={option}
                    isClearable={true}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: lightGray,
                            border: 'none',
                            boxShadow: 'none',
                            borderRadius: '0.25rem',
                            height: '80%',
                            paddingLeft: '35px', // Add padding to the left to accommodate the icon
                        }),
                        indicatorSeparator: () => ({
                            display: 'none',
                        }),
                    }}
                />}
            </InputGroup>
        </div>
    );
});

export default CustomerDropDown;
