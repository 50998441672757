// ToastContainer.js
import React, { useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Context from '../Context/context';

function ToastMessage({ type, content, removeToast }) {
  return (
    <div className={``}>
      {content}
    </div>
  );
}

export default function CustomToastContainer() {
  const { toasts, removeToast } = useContext(Context);

  useEffect(() => {
    toasts.forEach((toastData, index) => {
      // You can define your custom onClose function here
      const handleToastClose = () => {
        removeToast(index);
      };
console.log("toastData",toastData)
      toast[toastData.type](
        <ToastMessage
          type={toastData.type}
          content={toastData.content}
          removeToast={handleToastClose} // Use the custom onClose function
        />,
        {
          // position: toastData.data.position ? toastData.data.position : "top-right",
          // autoClose:toastData.data.autoClose || 3000 ,
          ...toastData.data ,
          // You can set an onClose function here as well if needed
          onClose: handleToastClose,
        }
      );
    });
  }, [toasts]);

  return <ToastContainer  closeOnClick={false} pauseOnHover={false} />;
}
