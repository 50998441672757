import React from 'react';
import Select from 'react-select';
import { darkColor, lightColor, lightGray, mainColor } from './constants/constans';

const Category = (props) => {
  const { setCategory, AllCategries, selectedOption } = props;

  // Create an array of options for react-select
  const options = AllCategries.map((productCategory) => ({
    value: productCategory.id,
    label: productCategory.attributes.name,
  }));

  console.log("options categ", options)
  // Handle selection change
  const handleSelectChange = (selectedOption) => {

    setCategory(selectedOption ? selectedOption.value : 0);
  };

  return (
    <div className='button-list d-flex flex-nowrap' style={{width:"96%" , marginLeft:"10px" , border:`1px solid ${mainColor}`  , borderRadius:"10px" }}>
      <div style={{ flex: 1, marginRight: '10px' }}>
        <Select
          options={[{ value: 0, label: 'All Categories' }, ...options]}
          //  value={selectedOption}
          onChange={handleSelectChange}
          isClearable
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius:"10px",
              backgroundColor: 'white',
              border: 'none',
              boxShadow: 'none',
              // borderRadius: '0.25rem',
              background : lightGray,
              height: '80%',
              paddingLeft: '10px',
          
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          isSearchable
          placeholder="Catégories"
        />
      </div>
    </div>
  );
};

export default Category;
