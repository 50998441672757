import React, { useEffect, useState, useContext } from 'react';
import {  Row } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
// import { addToast } from '../../../store/action/toastAction';
// import { toastType } from '../../../constants';
import { BsArrowClockwise } from "react-icons/bs";

import ResetCartConfirmationModal from "./pos-dropdown/ResetCartConfirmationModal";
import HoldCartConfirmationModal from './pos-dropdown/HoldCartConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ChamgeCalcul from "../ChangeCacul"
import TeleCalcul from '../TeleChangeCalcul';
import AppLock from "../AppLock"
import styled from 'styled-components';
import FullScreenModal from "./Modal/fullScreenModal"
import Context from '../Context/context';
import HoldName from "./Modal/holdName"
import EditHoldConfirmationModal from "./Modal/EditHoldConfirmationModal"
import warning from "../assets/images/warning.png"
import { darkColor, mainColor, semiDarkColor, lightColorLighter, semiLightColor, lightColor } from './constants/constans';
import ModalTele from './Modal/modalTele';
import { Cancel_icon, Pause, Percent } from './svgs/allSvgs';
import { annulerTicket } from '../Fetch/products';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  max-height: 150px;
  align-items: ${(props) => (props.mobile ? '' : 'end')};
  margin-bottom: 10px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 6px 2px 6px 2px;
  background: ${(props) => (props.delete ? lightColor : lightColorLighter)};
  flex-direction: column;
  margin: 0 5px 0 5px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: ${darkColor};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1550px) {
    height: 30px;
    width: 30px;
    /* Media query for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

const ButtonText = styled.span`
  text-align: center;
  font-size: 16px;
  color: ${darkColor};
  font-weight: 600;
  @media (max-width: 1550px) {
    /* Media query for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

const PaymentButton = (props) => {
    // const { updateProducts, setCashPayment, cartItemValue, grandTotal, subTotal, setCartItemValue, setUpdateProducts,
    //     holdListId, setHoldListValue, updateCart, selectedCustomerOption, selectedOption, cashPaymentValue, setUpdateHoldList } = props;
    // const dispatch = useDispatch();
    // const qtyCart = updateProducts.filter((a) => a.quantity === 0);
    // const [isReset, setIsReset] = useState(false)
    // const [isHold, setIsHold] = useState(false)
    // updateCart(prevSelected => [...prevSelected, newProduct]);
    const {total_price, setHoldListValue, showModal, setShowModal, showModalTele , setShowModalTele, mobile, onClickUpdateItemInCart } = props;
    const [updateProducts, setUpdateProducts] = useState([]);
    const [cashPayment, setCashPayment] = useState(false);
    const [cartItemValue, setCartItemValue] = useState({
        tax: 5,
        discount: 10,
        shipping: 7.99
    });
    const grandTotal = 123.49;
    const subTotal = 100.50;
    const [holdListId, setHoldListId] = useState({ referenceNumber: '' });
    const [updateHoldList, setUpdateHoldList] = useState(false);
    const selectedCustomerOption = null; // Replace with your selected customer option
    const selectedOption = null; // Replace with your selected option
    const cashPaymentValue = { notes: '' };

    const qtyCart = updateProducts.filter((a) => a.quantity === 0);
    const [isReset, setIsReset] = useState(false)
    const [isHold, setIsHold] = useState(false)
    const [showHoldName, setShowHoldName] = useState(false)
    const { productsPanier, setProductPanier ,addToast} = useContext(Context)
    const [isAnnuler, setIsAnnuler] = useState(false)


    const onAnnuler = () => {
        if(validatePainer()) {
            setIsAnnuler(!isAnnuler)
        }
       

    }


    const toggleShowHoldName = () => {
        if(validatePainer() ) {
            setShowHoldName(!showHoldName)
        }
  

    }



    //cash model open onClick
    const openPaymentModel = () => {
        if (!updateProducts.length > 0 || qtyCart.length > 0 || cartItemValue.tax > 100 || Number(cartItemValue.discount) > grandTotal || Number(cartItemValue.shipping) > Number(subTotal)) {

        } else if (updateProducts.length > 0 && !qtyCart.length) {
            setCashPayment(true);
        }
    };

    const resetPaymentModel = () => {
        if (updateProducts.length > 0 || qtyCart.length < 0 || cartItemValue.tax > 100 || Number(cartItemValue.discount) > grandTotal || Number(cartItemValue.shipping) > Number(subTotal)) {
            setIsReset(true)
        }
    }


    const holdPaymentModel = () => {

        let lastHoldPanier = JSON.parse(localStorage.getItem("holdPanier")) || [];
        console.log("lastHoldPanier", lastHoldPanier);
        lastHoldPanier.push(productsPanier);

        localStorage.setItem("holdPanier", JSON.stringify(lastHoldPanier));

    };

    // handle what happens on key press
    const handleKeyPress = (event) => {
        if (event.altKey && event.code === 'KeyR') {
            return resetPaymentModel()
        } else if (event.altKey && event.code === 'KeyS') {
            return openPaymentModel()
        }
    };

    useEffect(() => {
        // attach the event listener
        window.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const onConfirm = () => {
        setUpdateProducts([])
        setCartItemValue({
            discount: 0,
            tax: 0,
            shipping: 0
        })
        setIsReset(false)
        setIsHold(false)
    }

    const prepareFormData = () => {
        const formValue = {
            reference_code: holdListId.referenceNumber,
            date: moment(new Date()).format('YYYY-MM-DD'),
            customer_id: selectedCustomerOption && selectedCustomerOption[0] ? selectedCustomerOption[0].value : selectedCustomerOption && selectedCustomerOption.value,
            warehouse_id: selectedOption && selectedOption[0] ? selectedOption[0].value : selectedOption && selectedOption.value,
            hold_items: updateProducts ? updateProducts : [],
            tax_rate: cartItemValue.tax ? cartItemValue.tax : 0,
            discount: cartItemValue.discount ? cartItemValue.discount : 0,
            shipping: cartItemValue.shipping ? cartItemValue.shipping : 0,
            grandTotal: grandTotal,
            subTotal: subTotal,
            note: cashPaymentValue.notes,
        }
        return formValue
    };


    const onConfirmHoldList = () => {

        if (!holdListId.referenceNumber) {
            // dispatch(addToast({ text: getFormattedMessage('hold-list.reference-code.error'), type: toastType.ERROR }))
        } else {
            const datalist = prepareFormData()
            // dispatch(addHoldList(datalist))
            setIsHold(false)
            setUpdateProducts([])
            setCartItemValue({
                discount: 0,
                tax: 0,
                shipping: 0
            })
            setUpdateHoldList(true)
        }
    }

    const onCancel = () => {
        setIsReset(false)
        setIsHold(false)
    }


    const onChangeInput = (e) => {
        e.preventDefault();
        setHoldListValue(inputs => ({ ...inputs, referenceNumber: e.target.value }));
    };
    const resetPanier = async () => {
        // setProductPanier([])
        if(productsPanier[0]?.id_list_ticket){
            try {
                const res = await annulerTicket({
                    id_list_ticket :productsPanier[0]?.id_list_ticket ,
                    id_user: localStorage.getItem("caissier_id")
                })
                setProductPanier([])
            } catch (error) {
                console.log(error)
                
            }
           

        }else {
              setProductPanier([])
        }
      
        setIsAnnuler(!isAnnuler)
    }

    const validatePainer = ()=>{
        if(productsPanier.filter(p=>p.statut == 1).length == 0) {
            addToast("warning" , "Veuillez remplir le panier")
            return false
        }else {
            return true
        }

    }






    return (
        <div>
            <Container>

<Button onClick={toggleShowHoldName} >
        <IconContainer>
          <Pause />
        </IconContainer>
        <ButtonText>Ticket en attente</ButtonText>
      </Button>

      <Button onClick={() => {
       if(validatePainer())  {
        onClickUpdateItemInCart('all')

       }
       }
        } disabled={productsPanier.length == 0}>
        <IconContainer>
          <Percent />
        </IconContainer>
        <ButtonText>Ajouter une remise</ButtonText>
      </Button>

      <Button onClick={onAnnuler} delete={true} disabled={productsPanier.length == 0}>
        <IconContainer>
          <Cancel_icon />
        </IconContainer>
        <ButtonText>Annuler le ticket</ButtonText>
      </Button>
      {/* <button onClick={() => {
     var innerUrl = `https://api-legacy.lacaisse.ma/PosWeb/getTicket.php?id_ticket=21&id_caisse=2128&token_api=a936031a-a596-11ee-9241-fa163edb06d8`
     var encodedUrl = encodeURIComponent(innerUrl);
        window.location.href = 'myapp://click?url=' + encodedUrl+'&host=192.168.0.87&port=9100';
}}>test printing</button> */}
      </Container>
      
      {isAnnuler && <EditHoldConfirmationModal icon={warning} onConfirm={resetPanier} onCancel={onAnnuler} title={"Annuler le panier"} itemName={"product"} />}

{isReset && <ResetCartConfirmationModal onConfirm={onConfirm} onCancel={onCancel} itemName={"product"} />}
{isHold && <HoldCartConfirmationModal onChangeInput={onChangeInput} onConfirm={onConfirmHoldList} onCancel={onCancel} itemName={"product"} />}
<HoldName toggleShowHoldName={toggleShowHoldName} showHoldName={showHoldName} />


<FullScreenModal
            //ModalContent={AppLock}
              ModalContent={mobile ? TeleCalcul  : ChamgeCalcul }
             mobile={mobile}
             
                title={'Encaisser'}
                showModal={showModal} 
                setShowModal={setShowModal}
                // price ={parseFloat(productsPanier.reduce((acc, object) => acc + ( (object.discountedPrice ? object.discountedPrice : object.price ) * object.quantity), 0) - (productsPanier[0]?.discountedValue ? productsPanier[0]?.discountedValue : 0)).toFixed(2)}
                price={parseFloat(
                    productsPanier.filter(p => p.statut == 1)
                        .map((object) => {
                            const price = object.id_remise ? (object.discountedPrice ) : object.price;
                            return price * object.quantity;
                        })
                        .reduce((acc, total) => acc + total, 0)
                    //  - (productsPanier[0]?.discountedValue || 0)
                ).toFixed(2)
                }
                // price={total_price}
            />



          

        </div>


    )
};
export default PaymentButton;
