import React, { useState } from 'react';
import styled from 'styled-components';
import { BsPersonBoundingBox } from "react-icons/bs";
import { mainColor,darkColor ,semiDarkColor, semiMainColor, semiLightColor,LightColorDarker, lightColor } from './constants/constans';
import { Cancel, Cancel_icon, Percent, Percent_icon } from './svgs/allSvgs';

const FloatButtonContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  float: right;
  
  transition: 0.3s;
 
 .btn-triger {
    // z-index: 15;
    color:white;
    float: left;
  }

  .btn-list {
    position: absolute;
    right: 0;
     transform: translateX(-50px);
    
     
    transition: 0.3s;
    li {
      display: inline-block;
    }
  }
 
  }

  &.open {
    .btn-triger {
    //   background: #fff;
    background:${mainColor};
      .icon-bars::after {
        background:${mainColor};
        transform: rotate(0);
      }
    }

    .btn-list {
    //   background: rgba(0, 0, 0, 0.2);
    transform: translateX(50px);
    // display:none;
  
    // background:purple;
      width: 50px;
      height: 50px;
      overflow: hidden;
      
      left: 0;
      top: 0;
      opacity: 1;
      border-radius: 50%;
      &:hover{
        
          background: ${mainColor};
        
    }
  }
`;

// const FloatButton = styled.button`
//   width: 50px;
//   height: 50px;
//   line-height: 50px;
//   display: inline-block;
//   border: none;
//   margin: 0 2px 0 2px ;
//   font-size: 18px;
//   text-align: center;
//   position: relative;
//   border-radius: 12px;
//   cursor: pointer;
//   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
//   transition: 0.3s;
//   background:${mainColor};
//   color:white

//   i {
//     font-size: 24px;
//     transition: 0.3s;
//   }
//   .delete {
//     background:pink;

//   }

//   &:hover {
//     background:${semiDarkColor}
  
//     i {
//       color: ${mainColor};
      
     
//     }
//     // .icon {
//     //     // color: #F4BF96;
//     //       background:${semiDarkColor}
//     // }
//   }

//   & + & {
//     margin-top: 5px;
//   }

 
// `;
const FloatButton = styled.button`
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  border: none;
  margin: 0 2px 0 2px;
  font-size: 18px;
  text-align: center;
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  transition: background-color 0.3s, color 0.3s;
  background: ${mainColor};
  color: white;

  i {
    font-size: 24px;
    transition: color 0.3s;
  }

  .delete {
    background: pink;
  }

  &:hover {
    background: ${semiDarkColor};
    color: ${mainColor};

    i{
      color: ${mainColor};
    }
    .icon {
      color: ${mainColor};
    }
    .fa-bars{
      color: ${darkColor};

    }
  

  }

  &:focus {
    outline: none; /* Remove the default focus outline */
  }

  &:active {
    background: ${semiDarkColor};
  }

  & + & {
    margin-top: 5px;
  }
`;




function MyComponent({onClickProductSeller,onClickUpdateItemInCart,onDeleteCartItem , onUpdateItem}) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FloatButtonContainer className={isOpen ? 'open text-end' : 'text-end'}>
      <FloatButton className="btn-triger btn-float" style={{color:"white" , background:semiDarkColor  }} onClick={toggleMenu}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>

      </FloatButton>
      <ul className="btn-list"  style={{display:"flex"}}>

      <li>
          <FloatButton 
            style={{color:"white" , background:LightColorDarker }} 
          className="btn-float purple" onClick={onUpdateItem}>
          {/* <svg style={{color:darkColor}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg> */}
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
</svg>


          </FloatButton>
        </li>
     
        <li>
          <FloatButton className="btn-float blue"

          style={{color:"white" , background:LightColorDarker }} 
          onClick={onClickProductSeller}>
          <BsPersonBoundingBox className="fs-4  icon"  />
          </FloatButton>
        </li>
        <li>
          <FloatButton 
           style={{color:"white" , background:lightColor }} 
          className="btn-float green" onClick={onClickUpdateItemInCart}>
      <Percent_icon />

          </FloatButton>
        </li>
        <li>
          <FloatButton 
            style={{color:"white" , background:LightColorDarker }} 
          className="btn-float purple" onClick={onDeleteCartItem}>
          {/* <svg style={{color:darkColor}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg> */}
<Cancel_icon />

          </FloatButton>
        </li>

       
      </ul>
    </FloatButtonContainer>
  );
}

export default MyComponent;
