import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import Caisse from './Caisse';
import Provider from './Context/provider';
import Context from './Context/context';
import Change from './ChangeCacul';
import FullScreenModal from './components/Modal/fullScreenModal';
import Login from './login';
import CaissierLogin from './CaissierLogin';
import { fetchCaissier } from './Fetch/products';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [matchCaissier, setMatchCaissier] = useState(false);

  const checkCaisserExistence = async (caissier, caissier_id) => {
    const caissiers = await fetchCaissier();

    if (!caissiers) {
      return false;
    }

    const match = caissiers.some((obj) => obj.password === caissier && obj.id_serveur === caissier_id);
    console.log('match', match);
    return match;
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const data = qs.stringify({
          tokan_api: localStorage.getItem('tokan'),
          id_caisse: localStorage.getItem('id_caisse'),
        });

        const caissier = localStorage.getItem('caissier');
        const caissier_id = localStorage.getItem('caissier_id');

        const match = await checkCaisserExistence(caissier, caissier_id);
        setMatchCaissier(match);

        const config = {
          method: 'post',
          url: 'https://api-legacy.lacaisse.ma/PosWeb/verifyToken.php',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: data,
        };

        const response = await axios(config);
        console.log('response', response.data.result, isAuthenticated, matchCaissier);

        setIsAuthenticated(response.data.result === true);
        setIsLoading(false);
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (isLoading) {
    // Render a loading state if authentication check is in progress
    return <div>Loading...</div>;
  }

  return (
    // <Provider>
    //   <Router>
    //     <Routes>
    //       <Route path="/login" element={<Login />} />
          
    //       {/* Route to CaissierLogin when authenticated but doesn't match caissier */}
    //       {matchCaissier && isAuthenticated ? (
    //         <Route path="/caisseLogin" element={<CaissierLogin />} />
    //       ) : null}

    //       {/* Route to Caisse when authenticated and matches a caissier */}
    //       {matchCaissier && isAuthenticated ? (
    //         <Route path="/caisse" element={<Caisse />} />
    //       ) : null}

    //       {/* Redirect to CaissierLogin when authenticated but doesn't match caissier */}
    //       {matchCaissier && isAuthenticated ? null : <Navigate to="/caisseLogin" />}

    //       {/* Redirect to Login when not authenticated */}
    //       {isAuthenticated ? null : <Navigate to="/login" />}
    //       <Navigate to="/caisse" />
    //     </Routes>
    //   </Router>
    // </Provider>
    <Provider>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/caisse" element={<Caisse />} />
        <Route path="/change" element={<Change />} />
        {/* Add other routes here */}
        <Route path="/modal" element={<FullScreenModal />} />
        <Route path="/caisseLogin" element={<CaissierLogin />} />
        {/* Add other routes here */}
      </Routes>
      <Navigate to={isAuthenticated ? (matchCaissier ? '/caisse' : '/caisseLogin') : '/login'} />
    </Router>
  </Provider>
  );
}

export default App;
