import React, { useContext } from 'react';
import { Button, Badge } from 'react-bootstrap';
// import {connect, useDispatch} from 'react-redux';
import { currencySymbolHendling, decimalValidate, getFormattedMessage } from './shared/sharedMethod';

// import {addToast} from "../../../store/action/toastAction";
// import {toastType} from "../../../constants";
import Context from '../Context/context';
import { BsPersonBoundingBox } from "react-icons/bs";
import ToggleButton from 'react-bootstrap';
import ToggleMenu from './floatingButton';
import { darkColor ,lightColor, LightColorDarker, lightGray, mainColor} from './constants/constans';



const ProductCartList = (props) => {
    const {
        singleProduct,
        index,
        onClickUpdateItemInCart,
        onClickProductSeller,
        category,

        frontSetting,
        setUpdateProducts,
        onUpdateItem,
        posAllProducts, allConfigData, discountOption
    } = props;
    // const dispatch = useDispatch()
    // const totalQty = posAllProducts.filter((product) => product.id === singleProduct.id).map((product) => product.attributes.stock.quantity);
    const totalQty = 15

    const { productsPanier, setProductPanier, allSellersContext, setAllSellersContext } = useContext(Context)



    const onDeleteCartItem = (id) => {
        const panelFilterd = productsPanier.map(product => {

            if (product.id == id) {
                return { ...product, statut: 0 }
            } else {
               return  product
            }
        });
        setProductPanier(panelFilterd)
    }




    const handleIncrement = (productId) => {

        const panelArray = productsPanier.map(product => {
            if (product.id === productId) {
                return { ...product, quantity: product.quantity + 1 };
            }
            return product;
        });

        setProductPanier(panelArray)
    }

    const handleDecrement = (productId) => {
        const panelArray = productsPanier.map(product => {
            if (product.id === productId && product.quantity > 0) {
                return { ...product, quantity: product.quantity - 1 };
            }
            return product;
        });

        setProductPanier(panelArray)
    };

    //qty onChange
    const handleChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        // check if value includes a decimal point
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');
            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }


        setUpdateProducts(updateProducts =>
            updateProducts.map((item) => {
                if (item.id === singleProduct.id) {
                    if (totalQty[0] < Number(e.target.value)) {
                        // dispatch(addToast({
                        //     text: getFormattedMessage('pos.product-quantity-error.message'),
                        //     type: toastType.ERROR
                        // }))
                        return { ...item, quantity: totalQty[0] }
                    } else {
                        return {
                            ...item, quantity: Number(e.target.value)
                        }
                    }
                } else {
                    return item
                }
            })
        )
    };

    

    return (
        <tr key={index} className='align-middle'>
            <td className='text-nowrap text-nowrap ps-0'>
                <h4 className='product-name text-gray-900 mb-1 text-capitalize '>{singleProduct.name} </h4>

                <Badge bg={darkColor} text='white' style={{ maxWidth: "300px" , background:darkColor }} className='product-custom-card__card-badge'>{category}</Badge>
                {singleProduct.personnelSeller != "-1" && <Badge  bg={LightColorDarker} text='white' style={{ maxWidth: "300px", background:LightColorDarker ,marginLeft: "5px"}} className='product-custom-card__card-badge'>{allSellersContext.find(s => s.value == singleProduct.personnelSeller)?.label || ""}</Badge>}
                {singleProduct?.id_remise && <Badge  bg={LightColorDarker} text='white' style={{ maxWidth: "300px", background:"#C08261" ,marginLeft: "5px"}} className='product-custom-card__card-badge'>{discountOption.find(s => s.value == singleProduct.id_remise)?.label || ""}</Badge>}

                <br />
                <div className='counter d-flex align-items-center pos-custom-qty mt-2'>
                    <Button type='button' style={{background:lightColor , marginLeft:'3px'}} onClick={() => handleDecrement(singleProduct.id)}
                        className='counter__down d-flex align-items-center justify-content-center'>
                           <span style={{color:darkColor}}>-</span>
                    </Button>
                    <input type='text' value={singleProduct.quantity}
                        className="hide-arrow"
                        style={{margin:"0 5px 0 5PX"}}
                        onKeyPress={(event) => decimalValidate(event)}
                        onChange={(e) => handleChange(e)} />
                    <Button type='button' style={{background:lightColor}}  variant='primary' onClick={() => handleIncrement(singleProduct.id)}
                        className='counter__up d-flex align-items-center justify-content-center'>
                           <span style={{color:darkColor}}>+</span>
                    </Button>
                </div>
                {/* <span style={{ fontWeight: 'bold', marginRight: "5px" }}>
                    {singleProduct.quantity * parseFloat(singleProduct.price).toFixed(2)}
                </span> */}


                {/* {
                    singleProduct?.id_remise && (


                        <span style={{ color: 'red', textDecoration: 'line-through', marginRight: "5px" }}>
                            {singleProduct.quantity *
                                (singleProduct.discountedPrice
                                    ? parseFloat(singleProduct.price).toFixed(2) -
                                    parseFloat(singleProduct.discountedPrice).toFixed(2)
                                    : parseFloat(singleProduct.price).toFixed(2))}
                        </span>


                    )}


                {singleProduct.id_remise &&


                    <span style={{ color: 'green' }}>
                        {singleProduct.quantity *
                            (singleProduct.id_remise
                                ? parseFloat(singleProduct.discountedPrice).toFixed(2)
                                : parseFloat(singleProduct.price).toFixed(2))}
                    </span>

                } */}


            </td>



            <td className='text-end pe-0 '>
                <div style={{display:"flex"  , flexDirection:"column" }}>
                    <div className='text-end' style={{ marginRight:"5px"}}>
                    <ToggleMenu
                    key={index}
                    onDeleteCartItem={() => onDeleteCartItem(singleProduct.id)}
                    onClickUpdateItemInCart={() => onClickUpdateItemInCart(singleProduct)}
                    onClickProductSeller={() => onClickProductSeller(singleProduct)}
                    onUpdateItem={()=> onUpdateItem(singleProduct)}

                />

                    </div> 
                    
                    <div  className='text-end '  style={{  alignSelf:"end" , marginTop:"4px"}}>
               { !singleProduct.id_remise  ? 
               
                    <div style={{background:"red",
                     backgroundColor:lightColor,
                      fontWeight:600, color:darkColor ,
                      width : "fit-content" ,
                       padding:"1px 10px 1px 10px", 
                       borderRadius:"5px"}}> 
                    {singleProduct.quantity * parseFloat(singleProduct.price).toFixed(2)} MAD

                    </div> :  
                    
                    <span style={{ background:"red",
                    backgroundColor:lightColor,
                     fontWeight:600, color:darkColor ,
                     width : "fit-content" ,
                      padding:"1px 10px 1px 10px", 
                      borderRadius:"5px"}}>
                        {singleProduct.quantity *
                            (singleProduct.id_remise
                                ? parseFloat(singleProduct.discountedPrice).toFixed(2)
                                : parseFloat(singleProduct.price).toFixed(2))} MAD
                    </span>  
                    
                    } 
                    </div>

                      {/* {
                    singleProduct?.id_remise && (


                        <span style={{ color: 'red', textDecoration: 'line-through', marginRight: "5px" }}>
                            {singleProduct.quantity *
                                (singleProduct.discountedPrice
                                    ? parseFloat(singleProduct.price).toFixed(2) -
                                    parseFloat(singleProduct.discountedPrice).toFixed(2)
                                    : parseFloat(singleProduct.price).toFixed(2))}
                        </span>


                    )}


                {singleProduct.id_remise &&


                    <span style={{ color: 'green' }}>
                        {singleProduct.quantity *
                            (singleProduct.id_remise
                                ? parseFloat(singleProduct.discountedPrice).toFixed(2)
                                : parseFloat(singleProduct.price).toFixed(2))}
                    </span>

                }
                    */}
                {/* </div>
                </div> */}
                </div>
            

            </td>
        </tr>
    )
};

export default ProductCartList 
