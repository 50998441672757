import axios from "axios"
import qs, { stringify } from "qs"
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//const id_casse = 546
 const id_casse = localStorage.getItem("id_caisse")

const data = {
    'id_caisse': localStorage.getItem("id_caisse") ,
    'tokan_api': localStorage.getItem("tokan") 
  };

  const verifyToken = async () => {
    const data = qs.stringify({
      'tokan_api': 'f35c8ed9-d593-4eb-9b0a-abcbff351e7f',
      'id_caisse': '403' 
    });
    const config = {
      method: 'post',
      url: 'https://api-legacy.lacaisse.ma/PosWeb/verifyToken.php',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };
  
    try {
      const response = await axios(config);
      return response.data.isValidToken;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

//   axios.interceptors.request.use(async function (config) {
//     const isValidToken = await verifyToken();
  
//     if (!isValidToken) {
//       // Redirect to the login page or handle invalid token as per your requirement
//       window.location.href = '/login';
//       return Promise.reject('Invalid token');
//     }
  
//     return config;
//   }, function (error) {
//     return Promise.reject(error);
//   });


export const fetchAllProducts =async (idCategory , idFamille)=>{

    try {
        const object = {'id_caisse': id_casse }

        object.tokan_api = localStorage.getItem("tokan")
        // const data = {
        //     'id_caisse': localStorage.getItem("id_caisse") ,
        //     'tokan_api': localStorage.getItem("tokan") 
        //   };

        if(idCategory) {
            object.id_categorie = idCategory
        }

        if(idFamille) {
            object.id_famille = idFamille
        }


        const data = qs.stringify(object);
        console.log("data",object)
      
        const config = {
          method: 'post',
          url: 'https://api-legacy.lacaisse.ma/PosWeb/getProducts.php',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: object
        };
      
        const response = await axios(config);
        const jsonData = response.data;
      
        // Process the data as needed
       
        return jsonData;
      } catch (error) {
        // Handle any errors
        console.error(error);
    
        throw error;
      }

}


export const fetchAllFamilles = async ()=>{

    try {
        const data = qs.stringify({
          'id_caisse': id_casse 
        });
      
        const config = {
          method: 'post',
          url: 'https://api-legacy.lacaisse.ma/PosWeb/getFamille.php',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        };
      
        const response = await axios(config);
        const jsonData = response.data;
      
        // Process the data as needed
       
        return jsonData;
      } catch (error) {
        // Handle any errors
        console.error(error);
        throw error;
      }

}

export const fetchAllCategories = async (id)=>{

  console.log("id famille" , id)

    try {
        const data = {
          'id_caisse': id_casse ,
          'tokan_api': localStorage.getItem("tokan") 
        };

        if(id){
            data.id_famille = id
        }
      
        const config = {
          method: 'post',
          url: 'https://api-legacy.lacaisse.ma/PosWeb/getCategorie.php',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
        };
      
        const response = await axios(config);
        const jsonData = response.data;
        // Process the data as needed     
        return jsonData;
      } catch (error) {
        // Handle any errors
        console.error(error);
        throw error;
      }

}

export const fetchDiscount = async ()=>{
    try {
        const data = {
            'id_caisse': id_casse 
          };
     
      
        const config = {
          method: 'post',
          url: 'https://api-legacy.lacaisse.ma/PosWeb/getRemise.php',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data:data
        };
      
        const response = await axios(config);
        const jsonData = response.data;
      
        // Process the data as needed
        console.log("fetchDiscount",jsonData)
      
        return jsonData;
      } catch (error) {
        // Handle any errors
        console.error(error);
         throw error;
      }


}

export const fetchClient = async (productPanier , paymentPanier)=>{
  
      try {
        // const data = {
        //     'id_caisse': "403" ,
        //     'tokan_api': 'f35c8ed9-d593-46eb-9b0a-abcbff351e7f' 
        //   };
     
     
      
        const config = {
          method: 'post',
          url: 'https://api-legacy.lacaisse.ma/PosWeb/getClient.php',
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data:data
        };
      
        const response = await axios(config);
        const jsonData = response.data;
      
        // Process the data as needed
        console.log("fetchDiscount",jsonData)
      
        return jsonData;
      } catch (error) {
        // Handle any errors
        console.error(error);
        throw error;
      }
      
      
}

export const fetchSeller = async ()=>{
  
    try {
    //   const data = {
    //       'id_caisse': "403" ,
    //       'tokan_api': 'f35c8ed9-d593-46eb-9b0a-abcbff351e7f' 
    //     };
   
    
      const config = {
        method: 'post',
        url: 'https://api-legacy.lacaisse.ma/PosWeb/getVendeur.php',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:data
      };
    
      const response = await axios(config);
      const jsonData = response.data;
    
      // Process the data as needed
      console.log("fetchDiscount",jsonData)
    
      return jsonData;
    } catch (error) {
      // Handle any errors
      console.error(error);
      throw error;
    }
    
    
}


export const  fetchJournal = ()=>{
    // var data = JSON.stringify({
    //     "id_caisse": 403
    //   });
      
      var config = {
        method: 'get',
        url: 'https://api-legacy.lacaisse.ma/PosWeb/details_journal_web.php?id_caisse=403&user_name=said@lacaisse.ma&user_password=saidouapplite',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
}

export  const login = async ( email , pass )=>{


    var data = qs.stringify({
        'email': email,
        'password': pass
      });
      var config = {
        method: 'post',
        url: 'https://api-legacy.lacaisse.ma/PosWeb/connexionCaisse.php',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };

      try {
        const response = await axios(config);
       
        if(response.data[0].token_api && response.data[0].id_caisse) {
            
                localStorage.setItem("tokan",response.data[0].token_api)
                localStorage.setItem("id_caisse",response.data[0].id_caisse)
                localStorage.setItem("login" ,email )
                localStorage.setItem("password" ,pass)
              
                return response.data
    
            }
        
      } catch (error) {
        return error
        
      }

   
      
      // axios(config)
      // .then(function (response) {
      //   console.log(response.data)
      //   if(response.data[0].token_api && response.data[0].id_caisse) {
            
      //       localStorage.setItem("tokan",response.data[0].token_api)
      //       localStorage.setItem("id_caisse",response.data[0].id_caisse)
      //       localStorage.setItem("login" ,email )
      //       localStorage.setItem("password" ,pass)
          
      //       return response.data

      //   }
        // if(response.data.length > 0){
        //   return response.data

        // }
        // if(! (response.data.length > 0 )){
        //   return false

        // }
       
       
      // })
      // .catch(function (error) {
      //   return error
      // });


}

export const fetchCaissier = async ()=>{
  
    try {

      // const data = {
      //     'id_caisse': "403" ,
      //     'tokan_api': 'f35c8ed9-d593-46eb-9b0a-abcbff351e7f' 
      //   };

    //    const isValidToken = await verifyToken();
  
    //   if (!isValidToken) {
    //     // Redirect to the login page or handle invalid token as per your requirement
    //     window.location.href = '/login';
    //     return
      
    //   }

    if(!data.tokan_api) {
        return false
    }
         
      const config = {
        method: 'post',
        url: 'https://api-legacy.lacaisse.ma/PosWeb/getCaissier.php',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:data
      };
    
      const response = await axios(config);
      const jsonData = response.data;
    
      // Process the data as needed
      console.log("fetchDiscount",jsonData)
    
      return jsonData;
      
    
   
   
    } catch (error) {
      // Handle any errors
      console.error(error);
       throw error;
    }    
    
}

function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepCopy);
  }

  const newObj = {};
  for (const key in obj) {
    newObj[key] = deepCopy(obj[key]);
  }

  return newObj;
}

export const addTicket = async (productsPanier, panierPricing, ClientId, total_price) => {
  const products = productsPanier.filter(p => p.statut != 0).map((product) => {
    return {
      id_produit: product.id_produit,
      id_prix: product.id_prix,
      prix_catalogue : product.price ,
      prix_ttc: product.id_remise ? product.discountedPrice : product.price,
      count: product.quantity,
      id_remise: product?.id_remise ? product?.id_remise : "-1",
      id_ticket_cle : product.id_ticket_cle ? product.id_ticket_cle : "-1"
      // statut : product?.statut
    };
  });
  const cumulativePrice =panierPricing.reduce((acc, object) => acc + object.price , 0);
 const remainingPrice = total_price - cumulativePrice > 0 ? 0 : -(total_price - cumulativePrice);

 console.log("remainingPrice",remainingPrice)
 let updatedPricing = deepCopy(panierPricing);

 if (updatedPricing.length > 0) {
   // Update the last object's price in the copied array
   updatedPricing[updatedPricing.length - 1].price -= remainingPrice;
 }
 

  const paiments = updatedPricing.map((paiement) => {
   
    return {
      id_paiement: paiement?.type,
      montant: paiement.price,
    };
  });

  const total_sans_remise = productsPanier.filter(p=>p.statut != 0 ).reduce((acc, object) => acc + (object.price * object.quantity), 0)

  try {
    var data = {
      id_list_ticket : productsPanier.filter(p=>p.statut != 0 )[0]?.id_list_ticket || "-1",
      id_caisse: localStorage.getItem("id_caisse"),
      id_user: localStorage.getItem("caissier_id"),
      total: total_price,
      total_sans_remise : total_sans_remise ,
      id_client: ClientId ? ClientId : "-1",
      tokan_api: localStorage.getItem("tokan"),
      products: products,
      paiements: paiments,
    };

    var config = {
      method: "post",
      url: "https://api-legacy.lacaisse.ma/PosWeb/addTicket.php",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // return axios(config) // Return the promise from axios
    //   .then(function (response) {
    //     console.log("ticket", response.data);
    //     return response.data;
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     throw error;
    //   });
    const response = await axios(config)
    return response.data;

  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const handlePrint = async (data) => {
  console.log("hello data handle print " , data )
  try {
    var config = {
      method: 'post',
      url: 'http://localhost:8080/do_print',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addClient = async  (client)=>{

  try {
        var data = qs.stringify({
            "prenom_client" : client.prenom,
            'nom_client': client.name,
            'email_client': client.email,
            'tel_client': client.phone,
            'id_caisse':  localStorage.getItem("id_caisse"),
            'date_naissance': client.dateOfBirth,
            'tokan_api': localStorage.getItem("tokan") 
          });

          var config = {
            method: 'post',
            url: `https://api-legacy.lacaisse.ma/PosWeb/addClient.php`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
          };
          

          const response = await axios(config);
          const jsonData = response.data;
          return jsonData;
        
    } catch (error) {
        console.error(error);
        throw error;
        
    }

   

}

export const getAllTicketEnAttente = async  ()=>{
  try{
  var data = qs.stringify({
    'id_caisse':  localStorage.getItem("id_caisse"),
    'token_api': localStorage.getItem("tokan") 
  });

  var config = {
    method: 'post',
    url: 'https://api-legacy.lacaisse.ma/PosWeb/getTicketAttente.php',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
  };

  const response = await axios(config);
  const jsonData = response.data;
  return jsonData;

} catch (error) {
console.error(error);
throw error;

}}

export const  addTicketEnAttente =  async (ticket)=>{
  var data_j = JSON.stringify({
  ...data , ...ticket
   
  });
  
  var config = {
    method: 'post',
    url: 'https://api-legacy.lacaisse.ma/PosWeb/addTicketAttente.php',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data_j
  };
  
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
}

export const annulerTicket = async (ticket)=>{
  var data_j = JSON.stringify({
    ...data , ...ticket
     
    });
    
    var config = {
      method: 'post',
      url: 'https://api-legacy.lacaisse.ma/PosWeb/annulerTicket.php',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data_j
    };
    
    axios(config)
    .then(function (response) {
    return response.data
    })
    .catch(function (error) {
      return error
    });

} 

export const fetchCaisseOpen = async ()=>{

  try {
    var config = {
      method: 'post',
      url: 'https://api-legacy.lacaisse.ma/PosWeb/getCaisseOuverte.php',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Cookie': 'PHPSESSID=0f3c066f47ea284d428305f21ed6fb5f'
      },
      data : data
    };
    
    const res = await axios(config)
    return res.data
   
    
  } catch (error) {
    return error 
    
  }
}

export const addCaisseOverture = async (num)=>{

  try {
    var data_ = qs.stringify({
      ...data,
      'id_caissier':localStorage.getItem("caissier_id") ,
      'montant': num 
      });
      var config = {
        method: 'post',
        url: 'https://api-legacy.lacaisse.ma/PosWeb/addOuvertureFermeture.php',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Cookie': 'PHPSESSID=0f3c066f47ea284d428305f21ed6fb5f'
        },
        data : data_
      };
      
      // axios(config)
      // .then(function (response) {
      //   console.log(JSON.stringify(response.data));
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      const res = await axios(config)
      return res.data;
    
  } catch (error) {
    return error 
    
  }
 
}

export const fetchTicketZ =async  (id_caisse_table)=>{
  const email = localStorage.getItem("login")
  const password =  localStorage.getItem("password")
  const date_debut = localStorage.getItem("date_ouverture")
  console.log(email , password)

  var config = {
    method: 'get',
    url: `https://api-legacy.lacaisse.ma/PosWeb/details_journal.php?id_journal=${id_caisse_table}&id_caisse=${data.id_caisse}&date_debut=${date_debut}&date_fin=&user_name=${email}&user_password=${password}`,
    headers: { }
  };

  const res = await axios(config)
  return res.data;

  
} 

export const fetchFidiltePrice = async (idClient )=>{
  const data_ = 
  qs.stringify(
      {...data , id_client : idClient})

  var config = {
    method: 'post',
    url: `https://api-legacy.lacaisse.ma/PosWeb/getSoldeFidelite.php`,
    headers: { },
    data:data_
  };

  const res = await axios(config)
  return res.data;
  
}





