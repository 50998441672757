import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import ReactSelect from './shared/reactSelect';

import Context from '../Context/context';
// import { taxMethodOptions, discountMethodOptions } from '../../constants';

const productUnits = [
    { id: 1, attributes: { name: 'Unit 1' } },
    { id: 2, attributes: { name: 'Unit 2' } },
    { id: 3, attributes: { name: 'Unit 3' } },
    // Add more units as needed
];

const ProductDetailsModel = (props) => {
    const {
        openProductDetailModal,
        isOpenCartItemUpdateModel,
        cartProduct,
        onProductUpdateInCart,
        productModelId,
        updateCost,
        productUnitDropdown,
        discountOption
        // productUnits,

    } = props;

    console.log("discountOption", discountOption)
    const { productsPanier, setProductPanier ,addToast } = useContext(Context)
    const [product, setProduct] = useState(cartProduct);
    const [discount, setDiscount] = useState({});
    const [discoutInput, setDiscountInput] = useState()



    const onChangeUnitPrice = (e) => {
        //setProduct({ ...product, [e.target.name]: e.target.value })
        setDiscountInput(e.target.value)
    };
    
    //product details save button function
    // const onSaveDetailModal = () => {
    //     const type = discount.type
    //     if (product === "all") {

    //         // console.log("dissss", product , discount.value)

    //         let updatedPanel = [...productsPanier];

    //         for (let i = 0; i < productsPanier.length; i++) {
    //             let productIterated = productsPanier[i];
    //             let price;


    //             switch (type) {
    //                 case "pourcentage":
    //                     // productIterated.find( p => p.id_remise == type)[0].value
    //                     if(discount.value == 100 ){
    //                         price = productIterated.price 

    //                     } else {
    //                         price = productIterated.price * (1 - discountOption.find(p => p.value == discount.value).discountValue / 100);

    //                     }
                     
    //                     let existingProductIndex = updatedPanel.findIndex((p) => p.id === productIterated.id);
    //                     let existingProduct = updatedPanel.find((p) => p.id === productIterated.id);

    //                     if (existingProductIndex !== -1) {
    //                         updatedPanel[existingProductIndex] = { ...existingProduct, discountedPrice: price, id_remise: `${type}` };
    //                     }
    //                     setProductPanier(updatedPanel);

    //                     break;
    //                 case "remisel":

    //                     updatedPanel[0].discountedValue = parseInt(discoutInput, 10);
    //                     setProductPanier(updatedPanel);
    //                     break;

    //                 default:
    //                     console.log("Unknown type again.");
    //             }

    //         }

    //         return;
    //     }

    //     else {

    //         let price = product.price;
    //         switch (type) {
    //             case "pourcentage":
    //                 // productIterated.find( p => p.id_remise == type)[0].value
    //                 if(discount.value == 100 ){
    //                     price =  product.price 

    //                 } else {
    //                     price =  product.price * (1 - discountOption.find(p => p.value == discount.value).discountValue / 100);

    //                 }

    //                 break;
    //             case "remisel":
    //                 console.log("price", product.price, parseInt(discoutInput, 10))
    //                 price = product.price - parseInt(discoutInput, 10);
    //                 break;
    //             default:
    //                 console.log("Unknown type again.");
    //         }

    //         const newProduct = {
    //             name: product.name,
    //             discountedPrice: price
    //         };

    //         const existingProductIndex = productsPanier.findIndex((p) => p.id === product.id);
    //         const existingProduct = productsPanier.find((p) => p.id === product.id);

    //         if (existingProductIndex !== -1) {
    //             const updatedPanel = [...productsPanier];
    //             updatedPanel[existingProductIndex] = { ...existingProduct, ...newProduct, id_remise: `${type}` };
    //             setProductPanier(updatedPanel);
    //             console.log("updatedPanel", updatedPanel)
    //         } else {
    //             setProductPanier([...productsPanier, { ...product, quantity: 1 }]);
    //         }

    //     }


    // };

    const calculateDiscountedPrice = (product, discount ,length , type_) => {
        const { type, value } = discount;
       
        if (type === "pourcentage") {
            
            if (value === 100) {
                return product.price;
            } else {
                const discountValue = discountOption.find((option) => option.value === value).discountValue;
                return product.price * (1 - discountValue / 100);
            }
        } else if (type === "remisel") {

            if(type_){
                const discountedValue = parseInt(discoutInput, 10);
            return product.price - discountedValue/length;

            }else{
                const discountedValue = parseInt(discoutInput, 10);
            return product.price - discountedValue;

            }

            
        } else {
            console.log("Unknown type.");
            return product.price; // Return the original price as a fallback
        }
    };
    
    const updateProductInCart = (product, discountedPrice, id_remise) => {
        const existingProductIndex = productsPanier.findIndex((p) => p.id === product.id);
        if (existingProductIndex !== -1) {
            const updatedProductsPanier = [...productsPanier];
            updatedProductsPanier[existingProductIndex] = { ...product, discountedPrice, id_remise };
            setProductPanier(updatedProductsPanier);
        } else {
            setProductPanier([...productsPanier, { ...product, discountedPrice, id_remise, quantity: 1 }]);
        }
    };
    
    const onSaveDetailModal = () => {
        if (product === "all") {
            let updatedProductsPanier = [...productsPanier];
    
            for (let i = 0; i < productsPanier.length; i++) {
                const productIterated = productsPanier[i];
                const discountedPrice = calculateDiscountedPrice(productIterated, discount ,productsPanier.length ,product === "all");
    
                // Accumulate the updated product in the new array
                updatedProductsPanier[i] = {
                    ...productIterated,
                    discountedPrice,
                    id_remise: discount.value,
                };
            }
    
            // Set the cart to the updated array after the loop
            setProductPanier(updatedProductsPanier);
            addToast('success', "Votre remise a été appliquée." ,{ autoClose :3000})
            openProductDetailModal(false)
        } else {
            const discountedPrice = calculateDiscountedPrice(product, discount);
            updateProductInCart(product, discountedPrice, discount.value);
            addToast('success', "Votre remise a été appliquée.",{ autoClose :3000} )
            openProductDetailModal(false)
        }
    };

    const removeDiscount = ()=>{
        const existingProductIndex = productsPanier.findIndex((p) => p.id === product.id);
        if (existingProductIndex !== -1) {
            const updatedProductsPanier = [...productsPanier];
            updatedProductsPanier[existingProductIndex] = { ...product, id_remise : null };
            setProductPanier(updatedProductsPanier);
            addToast('success', "Votre remise a été supprimée.",{ autoClose :3000} )
        }

    }

    const removeAllDiscounts = () => {
        // Loop through each product in productsPanier and set id_remise to null
        const updatedProductsPanier = productsPanier.map((product) => ({
            ...product,
            id_remise: null,
        }));
        
        // Update the productsPanier state with the modified array
        setProductPanier(updatedProductsPanier);
        addToast('success', "Votre remise a été supprimée." ,{ autoClose :3000})
    };
    
    return (
        
        <Modal show={isOpenCartItemUpdateModel} onHide={() => openProductDetailModal(false)} className="pos-modal">
            <Modal.Header closeButton>
                <Modal.Title className="text-capitalize">{product.name} {` ( ${product =="all" ?  parseFloat(productsPanier.reduce((acc, object) => acc + ( (object.discountedPrice ? object.discountedPrice : object.price ) * object.quantity), 0) - (productsPanier[0]?.discountedValue ? productsPanier[0]?.discountedValue : 0)).toFixed(2) : product.price} MAD )`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className='col-12'>
                        <div className='col-md-12 mb-3'>
                            <Form.Label>Type de remise: </Form.Label>
                            <Select
                               
                                //  title={getFormattedMessage('purchase.product-modal.select.discount-type.label')}
                                //  multiLanguageOption={discountTypeFilterOptions} onChange={onDiscountTypeChange} 
                                errors={''}
                                options={discountOption}
                                 defaultValue={product =="all" ? (productsPanier.every((p) => p.id_remise)  ? discountOption.find(d => d.value == productsPanier.filter(p => p.statut == 1)[0].id_remise ) :"")  :  discountOption.find(d => d.value == product.id_remise && product.statut == 1)}
                                placeholder="Type de remise"
                                onChange={(e) => { 
                                    // onChangeUnitPrice(discountOption.find((option) => option.value === e?.value)?.discountValue)
                                    setDiscount(e) }}
                            />
                        </div>
                        <Form.Group className='col-md-12 mb-3' controlId='formBasicDiscount'>
                            <Form.Label>Valeur de la remise  :</Form.Label>
                            <Form.Control
                                type='number'
                                name='discountFidilete'
                                max={product.price}
                                //   onKeyPress={(event) => decimalValidate(event)}
                                className='form-control-solid'
                                disabled={!(discount.type == "remisel")}
                                onChange={(e) => onChangeUnitPrice(e)}
                                value={discoutInput}
                            />
                        </Form.Group>

                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className="pt-0">
           {productsPanier.find((p) => p.id === product.id)?.id_remise  &&  
                <Button variant='danger'
                    onClick={() => removeDiscount()}
                >
                    Annuler la remise
                </Button>}

                {productsPanier.every((p) => p.id_remise) && !productsPanier.filter(p => p.statut == 1).find((p) => p.id === product.id)?.id_remise && productsPanier.length >0   && (
                    <Button variant='danger'
                    onClick={() => removeAllDiscounts()}
                    >
                        Annuler toutes les remises
                    </Button>
                )}

              
                <Button variant='primary'
                    onClick={() => onSaveDetailModal()}
                >
                    Appliquer la remise
                </Button>
                <Button variant='secondary' className='me-0'
                    onClick={() => openProductDetailModal(false)}

                >
                    Retour
                </Button>
            </Modal.Footer>
        </Modal>
    )
};


export default ProductDetailsModel;
