import React, { useEffect, useState, useContext } from 'react'
import { currencySymbolHendling, getFormattedMessage } from '../shared/sharedMethod';
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
import moment from "moment";
import { darkColor, lightColor, lightColorLighter, mainColor, semiDarkColor } from '../constants/constans';
import ReactPrint from "../../components/ReactToPrint"
import TableComponentToPrint from 'components/TableJourneToPrint';
import Context from 'Context/context';

const tableStyle = {
  backgroundColor: lightColorLighter, // Change to the desired background color
  border: `2px solid ${lightColor}`, // Add a border with the main color
  padding: '10px',
  color: darkColor // Adjust padding as needed
};

const title = {
  color: mainColor
}

const TableComponent = ({ data }) => {
  const [displayedKeys, setDisplayedKeys] = useState([]);


  if (data === undefined || data === null) {
    return null; // or a loading/error message, or any other fallback content
  }
  return (


    <div>
      {data?.etat.length > 0 && (
        <div className="container">
          <h2 style={title}>Etat</h2>
          <table style={tableStyle} className="table table-striped">
            <tbody>
              {Object.entries(data.etat[0]).map(([key, value], index) => (
                <tr key={index}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {data?.moyennes_paiement.length > 0 && (
        <div className="container">
          <h2 style={title}>Moyennes Paiement</h2>
          <table style={tableStyle} className="table table-striped">
            <thead>
              <tr>
                <th>Type Paiement</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              {data.moyennes_paiement.map((item, index) => (
                <tr key={index}>
                  <td>{item.type_paiement}</td>
                  <td>{item.montant}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      {/* {data?.remise.length > 0  &&  <div className="container">
    <h2 style={title}>Remise</h2>
    <table  style={tableStyle} className="table table-striped">
      <tbody>
        {Object.entries(data.remise[0]).map(([key, value], index) => (
          <tr key={index}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>} */}
      {data.remise.length > 0 && (
        <div className="container">
          <h2 style={title}>Remise</h2>
          <table style={tableStyle} className="table table-striped">
            <tbody>
              {data.remise.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item.designation_remise} ( x {item.quantite})</td>
                    <td>{`${item.type || ""}`}</td>
                    <td>{`${item.montant} MAD`}</td>


                  </tr>
                  {/* Add the following line if you want a separator between rows */}
                  {/* <tr><td colSpan="4"><hr /></td></tr> */}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}


      {data.fonds.length > 0 && <div className="container">
        <h2 style={title}>Fonds</h2>
        <table style={tableStyle} className="table table-striped">
          <tbody>
            {Object.entries(data.fonds[0]).map(([key, value], index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>}

      {/* { data.tva.length > 0 &&  <div className="container">
  <h2 style={title}>TVA</h2>
  <table  style={tableStyle} className="table table-striped">
    <tbody>
      {data.tva.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          {<tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
      
    </tbody>
  </table>
</div>} */}
      {data.tva.length > 0 && (
        <div className="container">
          <h2 style={title}>TVA</h2>
          <table style={tableStyle} className="table table-striped">
            <thead>
              <tr>
                <th>Taux</th>
                <th>HT</th>
                <th>TVA</th>
                <th>TTC</th>
              </tr>
            </thead>
            <tbody>
              {data.tva.map((item, index) => (
                <tr key={index}>
                  <td>{item.taux}</td>
                  <td>{item.HT}</td>
                  <td>{item.TVA}</td>
                  <td>{item.TTC}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      {data.mouvement.length > 0 && <div className="container">
        <h2 style={title}>Mouvements</h2>
        <table style={tableStyle} className="table table-striped">
          <tbody>
            {data.mouvement.length != 0 && data.mouvement?.map((item, index) => (
              <React.Fragment key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
                {<tr><td colSpan="2"><hr /></td></tr>}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>}


      {/* {data.meilleures_articles_par_categorie.length> 0 && <div className="container">
  <h2 style={title}>Meilleures articles par categorie</h2>
  <table   style={tableStyle} className="table table-striped">
    <tbody>
      {data.meilleures_articles_par_categorie.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          { <tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>} */}
      {data.meilleures_articles_par_categorie.length > 0 && (
        <div className="container">
          <h2 style={title}>Ventes par catégorie</h2>
          <table style={tableStyle} className="table table-striped">
            <tbody>
              {data.meilleures_articles_par_categorie.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      {`${item.designation || item.label} x ( ${item.quantite} )`}
                    </td>
                    <td>{`Prix: ${item.prix} MAD`}</td>
                  </tr>
                  {/* <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr> */}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {data.meilleures_articles.length > 0 && (
        <div className="container">
          <h2 style={title}>Ventes par articles</h2>
          <table style={tableStyle} className="table table-striped">
            <tbody>
              {data.meilleures_articles.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      {`${item.designation || item.label} x ( ${item.quantite} )`}
                    </td>
                    <td>{`Prix: ${item.prix} MAD`}</td>
                  </tr>
                  {/* <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr> */}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}





      {/* {data.article_annulee.length >0 && <div className="container">
  <h2 style={title}>Article annulee</h2>
  <table  style={tableStyle} className="table  table-striped">
    <tbody>
      {data.article_annulee.map((item, index) => (
        <React.Fragment key={index}>
          {Object.entries(item).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
          {<tr><td colSpan="2"><hr /></td></tr>}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>
} */}
      {data.article_annulee.length > 0 && (
        <div className="container">
          <h2 style={title}>Article annulée</h2>
          <table style={tableStyle} className="table table-striped">
            <tbody>
              {data.article_annulee.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td >
                      {`${item.designation || item.label} x ${item.quantite}`}
                    </td>
                    <td>{`Prix: ${item.prix} MAD`}</td>
                  </tr>
                  {/* <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr> */}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {data.indicateur.length > 0 && (
        <div className="container">
          <h2 style={title}>Indicateur</h2>
          <table style={tableStyle} className="table  table-striped">
            <tbody>
              {data.indicateur.map((item, index) => (
                <React.Fragment key={index}>
                  {Object.entries(item).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                      {/* {key === "CA HT" && <td>{item.montant}</td>} */}
                    </tr>
                  ))}

                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}


      {data.statistique.length > 0 && <div className="container">
        <h2 style={title}>Statistique</h2>
        <table style={tableStyle} className="table  table-striped">
          <thead>
            <tr>
              <th> titre</th>
              <th>valeur</th>
            </tr>
          </thead>
          <tbody>
            {data.statistique.map((item, index) => (
              <tr key={index}>
                <td>{item.cle}</td>
                <td>{item.valeur}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>}
    </div>

  );

};




function RegisterDetailsModel(props) {
  const { lgShow, setLgShow, posAllTodaySaleOverAllReport, printRegisterDetails, fetchTodaySaleOverAllReport, frontSetting, allConfigData } = props;

  const { ticketZ, addToast } = useContext(Context)
  const data = ticketZ




  const handlePrintingError = () => {
    onsetLgShow()

    addToast('error', "Erreur d'impression");


  }

  // useEffect(()=> {
  //    fetchTodaySaleOverAllReport()
  // },[]);

  const onsetLgShow = () => {
    setLgShow(false)
  }

  const currencySymbol = frontSetting && frontSetting.value && frontSetting.value.currency_symbol
  const sumOfProductQuantity = posAllTodaySaleOverAllReport?.today_total_products_sold?.reduce((acc, o) => acc + parseInt(o.total_quantity), 0)
  const sumOfBrandQuantity = posAllTodaySaleOverAllReport?.today_brand_report?.reduce((acc, o) => acc + parseInt(o.total_quantity), 0)

  return (
    <div>
      <Modal
        // size="xl"
        aria-labelledby="example-custom-modal-styling-title"
        show={lgShow}
        onHide={() => onsetLgShow()}
      // className='registerModel-content'
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" style={{ color: darkColor, fontWeight: 800 }}>
            Détails du registre {localStorage.getItem("date_ouverture")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "scroll" }}>
            <TableComponent data={data} />

          </div>

        </Modal.Body>
        <Modal.Footer className='justify-content-end pt-2 pb-3'>
          {/* <button className='btn btn-primary text-white'
                         onClick={printRegisterDetails}
                 >Print</button> */}
          <ReactPrint contentToPrint={<TableComponentToPrint data={data} />} handlePrintingError={handlePrintingError} label="Imprimer" />
          <button className='btn btn-danger'
            onClick={() => setLgShow(false)}>Annuler
          </button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}

const mapStateToProps = (state) => {
  const { posAllTodaySaleOverAllReport, allConfigData } = state;
  return { posAllTodaySaleOverAllReport, allConfigData }
};

export default RegisterDetailsModel;
