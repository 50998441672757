import React, { useEffect, useState, useContext } from 'react'

import { Modal, Button } from 'react-bootstrap';

import Context from '../../Context/context';
import useSound from 'use-sound';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import {addTicketEnAttente} from "../../Fetch/products"


function ModalUpdateProduct(props) {
    const [play] = useSound('https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3');

    const { products,product, isOpen, toggleShow } = props
    const { productsPanier, setProductPanier,addToast ,setSeller , seller ,client ,total_price} = useContext(Context)
    const [price, setPrice] = useState(product.price)

    // useEffect(() => {

    //  setHoldName(productsPanier[0]?.holdname)
       
    // }, [productsPanier])

    const handleHoldName = (e) => {
        setPrice(e.target.value)
    }

    const handleSaveName = async () => {
        // Create a new array with the updated price for the matched product
        const updatedProductsPanier = productsPanier.map((item) => {
            if (item.id === product.id) { // Check if it's the product to update
                return { ...item, price: price , discountedPrice: price , id_remise:null }; // Update the price
            }
            return item; // Return the item unchanged if it's not the one to update
        });
    
        setProductPanier(updatedProductsPanier); // Update the state with the new array
    
        // Optionally, if you have a backend call to update the product in the database
        // you can call it here. For example:
        // await updateProductPrice(product.id, price);
    
        toggleShow();
        addToast("success", "le prix a été modifié avec succès")
        
        // Close the modal
    };
    



    return (
        <>
            <div>
                <Modal
                    size="md"
                    aria-labelledby="example-custom-modal-styling-title"
                    show={isOpen}
                    onHide={() => toggleShow()}
                    className='registerModel-content'
                >
                    <Modal.Header closeButton className='p-4'>
                        <Modal.Title id="example-modal-sizes-title-lg">
                       Modifier le prix de : {product.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='card-body'>
                            <div className='row'>
                                <div >
                                    <label className='form-label'>
                                    Prix  :
                                    </label>
                                    <span className='required' />
                                    <input type='number' name='name'
                                        value={price}
                                        placeholder={"Prix de produit"}
                                        className='form-control'

                                        onChange={handleHoldName}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                        <Button disabled={!price} onClick={handleSaveName} style={{ width: "300px" }}>Enregistrer</Button>
                    </div>
                </Modal>
            </div>

        </>
    )
}



export default ModalUpdateProduct;
