import React, {useEffect , useState ,useContext} from 'react';
import {InputGroup} from 'react-bootstrap';
import Select from 'react-select';
import { fetchSeller } from '../../Fetch/products';
// import {connect} from 'react-redux';
// import {fetchAllWarehouses} from '../../../store/action/warehouseAction';
// import { getFormattedMessage } from '../shared/shared1/sharedMethod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Context from '../../Context/context';
import context from 'react-bootstrap/esm/AccordionContext';
import { darkColor, mainColor,lightGray } from '../constants/constans';
const WarehouseDropDown = (props) => {

 
    const {  seller, setSeller ,allSellersContext , setAllSellersContext  ,productsPanier ,setProductPanier , productPanier} = useContext(Context)
    const [allSellers , setAllSellers] = useState([])
  


    const getAllSellers = async ()=>{
    
        const data = await fetchSeller()
        const sellersOption = data && data.map((seller) => {
            return { value: seller.id_serveur, label: seller.nom_serveur };
          });
    
       
          if( props?.setAllSellers) {
            props?.setAllSellers(sellersOption)
            setAllSellersContext(sellersOption)

          }
             
       }


      //  useEffect(()=>{
      //   getAllSellers()
      //  },[])




    const onChangeWarehouse = (obj) => {
      if(props.sellerToProduct ){
        props.setSeller(obj)

      }else {
        setSeller(obj);
       
      }
      
        // const updatedData = [...productsPanier];
        // updatedData[0].seller = obj
        // setProductPanier(updatedData)
    };

    return (
        // <div className='select-box col-6 pe-sm-1 position-relative' style={{ height: '60px', borderRadius: '20px', background: 'white'  }}>
        // <InputGroup className='d-flex flex-nowrap align-items-center' style={{ height: '100%', display: 'flex' , overflow: 'hidden' }}>
        //         <InputGroup.Text id='basic-addon1' className='bg-transparent position-absolute border-0 z-index-1 input-group-text py-4 px-3'>
        //             <i className="bi bi-house fs-3 text-gray-900" />
        //         </InputGroup.Text>
        //         <Select
        //             placeholder='Choose Seller'
        //             defaultValue={selectedOption}
        //             value={selectedOption}
        //             onChange={onChangeWarehouse}
        //             options={allSellers}
        //             styles={{
        //                 control: (provided) => ({
        //                   ...provided,
        //                   backgroundColor: 'white',
        //                   border: 'none',
        //                   boxShadow: 'none',
        //                   borderRadius: '0.25rem',
        //                   height: '100%',
        //                   paddingLeft: '40px', // Add padding to the left to accommodate the icon
        //                 }),
        //                 indicatorSeparator: () => ({
        //                   display: 'none',
        //                 }),
        //               }}
        //             //noOptionsMessage={() => getFormattedMessage('no-option.label')}
        //         />
        //     </InputGroup>
        // </div>
        <div className='select-box col-6 pe-sm-1 position-relative px-2' style={{ height: '45px', borderRadius: '10px', background: lightGray ,  border:`solid 1px ${mainColor}`, width:`${ props.sellerToProduct ? "80%" : "50%"}` }}>
        <InputGroup className='d-flex flex-nowrap align-items-center' style={{ height: '100%', display: 'flex' }}>
          <div
            id='basic-addon1'
            style={{ height: '100%', backgroundColor: 'white', border: 'none' }}
            className='bg-transparent position-absolute border-0 z-index-1 input-group-text py-4 px-3'
          >
            <FontAwesomeIcon icon={faUser}  style={{ color: mainColor }}/>
          </div>
          { <Select
            className='w-100'
            placeholder='vendeur'
            defaultValue={ props.sellerToProduct ? props.seller : seller}
            value={ props.sellerToProduct ? props.seller : seller}
            onChange={onChangeWarehouse}
            options={allSellersContext}
            isClearable={true}
            styles={ props.sellerToProduct ? {
                control: (provided) => ({
                  ...provided,
                  backgroundColor: lightGray,

                  border: 'none',
                  boxShadow: 'none',
                  borderRadius: '0.25rem',
                  height: '80%',
                  paddingLeft: '35px', 
                  // paddingLeft: '40px',
                   // Add padding to the left to accommodate the icon
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
              } : {
                control: (provided) => ({
                  ...provided,
                  backgroundColor: lightGray,

                  border: 'none',
                  boxShadow: 'none',
                  borderRadius: '0.25rem',
                  height: '80%',
                  paddingLeft: '35px',  // Add padding to the left to accommodate the icon
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
              } }
          />}
       
        </InputGroup>
      </div>
    )
};

const mapStateToProps = (state) => {
    const {warehouses} = state;
    return {warehouses}
};
export default WarehouseDropDown;
