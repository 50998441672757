import React, { useEffect, useState, useContext } from 'react'
import { currencySymbolHendling, getFormattedDate, getFormattedMessage } from '../shared/sharedMethod';
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
// import { connect } from "react-redux";
// import { fetchTodaySaleOverAllReport } from "../../../store/action/pos/posRegisterDetailsAction";
import moment from "moment";
import { faEdit, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditHoldConfirmationModal from './EditHoldConfirmationModal';
import warning from "../../assets/images/warning.png"
import deleteItems from "../../assets/images/remove.png"
// import {fetchHoldLists, fetchHoldList, deleteHoldItem} from '../../../store/action/pos/HoldListAction';
import Context from '../../Context/context';
import useSound from 'use-sound';
import Select from 'react-select';


  

function ModalDetailProduct(props) {
    const [play] = useSound('https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3');

    const { products, showProductDetail, toggleProductDetail , categoryId , brandId } = props
    const { productsPanier, setProductPanier } = useContext(Context)

    const addToCart = (item , products) => {
        console.log("decl" ,item )
        // play();
        const product =
        {
            personnelSeller : "-1",
            id:item.id_prix ,
            id_produit :products.id,
            id_prix: item.id_prix,
            name: `${products.attributes.name}- ${item?.label}`,
            code: item.code,
            price: item.price,
            id_categorie:item.id_categorie,
            statut:1
        }

        const existingProductIndex = productsPanier.findIndex((p) => p.id === product.id);
        const existingProduct = productsPanier.find((p) => p.id === product.id);



        if (existingProductIndex !== -1 && existingProduct.statut == 1) {
            const updatedPanel = [...productsPanier];

            updatedPanel[existingProductIndex].quantity += 1;


            setProductPanier(updatedPanel);
            toggleProductDetail()
        } 
        
        else if(existingProductIndex !== -1 && existingProduct.statut == 0){
            const updatedPanel = productsPanier.filter(p => p.id != product.id );
         
            setProductPanier([...updatedPanel, { ...product, quantity: 1 }]);
            toggleProductDetail()
        }
        else {
            setProductPanier([...productsPanier, { ...product, quantity: 1 }]);
            toggleProductDetail()

        }
    };


    return (
        <>
            <div>
                <Modal
                    size="md"
                    aria-labelledby="example-custom-modal-styling-title"
                    show={showProductDetail}
                    onHide={() => toggleProductDetail()}
                    className='registerModel-content'
                >
                    <Modal.Header closeButton className='p-4'>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Déclinaisons
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {products && products.declinaison.length > 0 && products.declinaison.map((items, index) => {
                            return (

                                <div key={index} onClick={() => { addToCart(items , products) }} className="card-Item-click">

                                <div>{items.label}</div>

                                <div><span className='price-Item'>{items.price}</span> MAD</div>

                            </div>
                               
                

                            )
                        })}
                       
                    </Modal.Body>
                </Modal>
            </div>

        </>
    )
}



export default ModalDetailProduct;
