import React, { useContext, useState } from 'react';
import { Button, Badge } from 'react-bootstrap';
import Context from '../Context/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Badge from 'react-bootstrap';
import { faHand } from '@fortawesome/free-solid-svg-icons';
import { darkColor, lightColor, LightColorDarker, mainColor, semiDarkColor } from './constants/constans';
import { Cancel_icon } from './svgs/allSvgs';

const labelPayment = [
    {
        type: 0, label: "espece"
    },

    { type: 1, label: "CB" },

    { type: 2, label: "Cheque" },
    { type: 4, label: "Virement" },
    { type: 5, label: "Fidélité" }
]

const styles = {
    container: {
        marginBottom: '10px',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px',

    },
    evenContainer: {
        backgroundColor: '#f5f5f5',
    },
    headerPayment: {
        display: "flex",
        justifyContent: "space-between",
        border: `1px ${darkColor} solid`,
        padding: "10px 20px 10px 20px",
        background: lightColor,
        borderRadius: "9px",
        fontSize:"14px",
        fontWeight:600,
        color: darkColor,
        height: "60px"


    },
    paymentItem: {
        display: "flex",
        // flexDirection: "column",
        justifyContent: "space-between",
        border: `1px solid ${mainColor} `,
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "9px",
        backgroundColor: semiDarkColor,
        marginTop: "10px"
    },


};

const ProducPanierPayment = (props) => {



    const { typePayment, setTypePayment,
        productsPanier, setProductPanier,
        total_price, setTotal_price,total_price_copi ,
        panierPricing, setPanierPricing ,setFideletePerClient } = useContext(Context)
        const [total_price_Copied , setTotal_price_Copied] = useState(total_price)

        const onDeletePaymentItem = (index) => {
            const deletedItem = panierPricing[index];
          
            // Check if the deleted item has type 5
            if (deletedItem.type == 5) {
              // Add the price to fideletePerClient
              setFideletePerClient((prev) => prev + deletedItem.price);
            }
          
            // Remove the item from panierPricing
            const filteredArray = panierPricing.filter((item, i) => i !== index);
            setPanierPricing(filteredArray);
          };


    return (
        <div className='align-middle' display={{ width: "100%" }}>
            {/* <h1>Total {total_price} Dh</h1> */}

            <div style={styles.headerPayment}>
                <div>Type de paiement</div>
                <div>À rendre </div>
                <div>Actions</div>
            </div>

            <div style={{
                overflow: 'scroll',
             height: "70vh",
                // scrollbarWidth: 'none', // Hide scrollbar in Firefox
                // '-ms-overflow-style': 'none', // Hide scrollbar in IE and Edge
            }}>
                {
                    panierPricing.map((p, i) => {

                        const paymentLabel = labelPayment.find(item => item.type === p.type)?.label;
                        // Calculate the cumulative price up to the current index
                        const cumulativePrice = panierPricing.slice(0, i + 1).reduce((sum, item) => sum + item.price, 0);

                        // Calculate the remaining price
                        const remainingPrice = total_price_copi - cumulativePrice > 0 ? 0 : -(total_price_copi - cumulativePrice);

                        return (
                            <div style={styles.paymentItem}>

                                <div style={{
                                     display: "flex",
                                     width: "25%" ,
                                     marginRight:"10px" 
                                     }}>

                                    <Badge bg={lightColor} style={{ background: darkColor,color:"white" , fontWeight:600 ,  display:"flex",flexDirection:"column", justifyContent:"center", marginRight: "20px", width: "fit-content" }}>{paymentLabel} </Badge>
                                    <div className='d-flex' style={{color:"white", fontWeight:600 }}>  <div>{parseFloat(p.price).toFixed(2)}</div> <div>{" "} DH</div></div>
                                    
                                </div>


                                <div style={{ display: "flex", justifyContent: "space-between", color:"white", fontWeight:550 }}>
                                    {/* <div>left : </div> */}
                                    <div>{parseFloat(remainingPrice).toFixed(2)} DH</div>
                                </div>


                                <Button className='p-0 border-0' style={{ marginRight: "10px" , height:"30px" , width:"40px" , borderRadius:"35%" , background:semiDarkColor }} onClick={() => onDeletePaymentItem(i)}>
                                    <Cancel_icon />
                                </Button>



                            </div>
                        )
                    })
                }

            </div>




            {/* { total_price - panierPricing.reduce((sum, item) => sum + item.price, 0) <= 0 && <div style={{display: "flex"}}>
<Button type='button' variant='anger' className='text-white bg-btn-pink btn-rounded btn-block me-2 w-100 py-3 rounded-10 px-3'
                        >{getFormattedMessage('pos.hold-list-btn.title')} <FontAwesomeIcon icon={faHand} className='ms-2 fa'/> </Button>

</div>} */}


        </div>
    )
};

export default ProducPanierPayment 