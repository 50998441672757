import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Row, Table, Button, Jumbotron } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHand } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calculator from "./components/calculator_tele"
import Context from './Context/context';
import ProducPanierPayment from "./components/panierPayment"
import { addTicket, handlePrint } from './Fetch/products';
import ButtonPanelPayment from "./components/ButtonPanelPayment"
import { isLabelWithInternallyDisabledControl } from '@testing-library/user-event/dist/utils';
import { lightColor, LightColorDarker } from "./components/constants/constans"
import { darkColor } from "./components/constants/constans"
import ReactToPrint from './components/ReactToPrint';
import PosTicket from 'components/TicketData';
import printing_ from "./animaion/printing.json"
import Lottie from 'react-lottie';
import { Bars, Espece, BarsTele, Virement, Cheque, CR, Autre, Partage, Ticket } from './components/svgs/allSvgs';



function TeleCalcul({ handleCloseModal }) {

    const panierSumStyle = { fontSize: "18px",
     padding:"2px 5px 2px 5px",color: "white",
     borderRadius:"10px",background:darkColor ,
      alignSelf: "center",fontWeight: 600}

    const {

        productsPanier, setProductPanier,
        typePayment, setTypePayment,
        total_price, setTotal_price,
        panierPricing, setPanierPricing,ticketToPrint,
        setSelectedCustomerOption, selectedCustomerOption,setTicketToPrint,
        seller, setSeller, addToast  , fideletePerClient , getIsCaisseOpen} = useContext(Context)

        const [clickedMethod , setClickedMethod] = useState("")
        const [showCalcul , setShowCalcul] = useState(false)
        const [methohType  ,setMethod] = useState(null)
        const [printing, setPrinting] = useState(false)

    const changeTypePayment = (type) => {

       

    }

   const price =  parseFloat(
        productsPanier
            .map((object) => {
                const price = object.id_remise ? (object.discountedPrice ) : object.price;
                return price * object.quantity;
            })
            .reduce((acc, total) => acc + total, 0)
        // - (productsPanier[0]?.discountedValue || 0)
    ).toFixed(2)
    

    const handleClickMethod = (type)=>{
     
            setShowCalcul(!showCalcul)
            setMethod(type)
    }

    // useEffect(()=>{
     

    // },[panierPricing])

    const sumOfPanierPricing = (type)=>{
        const sum = panierPricing.reduce((total, obj) => {
            if (obj.type == type) {
              return total + obj.price;
            }
            return total;
          }, 0);

          return sum

    }

    const restApayer = () => {
        const sum = panierPricing.reduce((total, obj) => {
          return total + obj.price;
        }, 0);
      
        return price - sum;
      }


  const handleOnBeforeGetContent = () => {
    console.log("handleOnBeforeGetContent" ,ticketToPrint)
  //  const res =  handleAddTicket()
  //  if(res){
    if(ticketToPrint){
      setPrinting(true)

    }
  
  //  }
    

  }
  const handleBeforePrint = () => {
    console.log("handleBeforePrint")
    if(ticketToPrint){
      setPrinting(true)

    }
    // const res = handleAddTicket()
    // if (res) {
    //   setPrinting(true)
    // }
  }

  const handlePrintingError = () => {
    handleCloseModal();
    addToast('error', "Erreur d'impression");
    setPanierPricing([]);
    setProductPanier([]);
    setSeller({});
    setSelectedCustomerOption({});

  }



  const handleAfterPrint = () => {
    console.log("handleAfterPrint")
    setPrinting(false)
    setProductPanier([]);
    setSeller(null);
    setSelectedCustomerOption();
    handleCloseModal();
    setTicketToPrint(null)
    setTicketToPrint(null)


  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: printing_,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const handleAddTicket = async () => {
    const isCaisseOpen = await getIsCaisseOpen()

    try {
      let res_imp
      const res = await addTicket(productsPanier, panierPricing, selectedCustomerOption?.value, total_price);
      console.log("res" , res)
      if (res) {
        setTicketToPrint(res)
        setProductPanier([]);
        setSeller(null);
        setSelectedCustomerOption(null);
        if(ticketToPrint){
        
          return res
        }}
      if (!res) {
        addToast('error', 'Erreur lors de la création du ticket.', { autoClose: 3000 });
        setPanierPricing([]);

      }

    } catch (error) {
      console.log("Ticket error", error);

    }
  };

  
      
   
    
    return (

        <div style={{marginTop:"30px" }}>
           {!showCalcul ? <Container className='' style={{}} fluid>

                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ fontSize: "18px", color: darkColor, fontWeight: 600,  display:"flex" , flexDirection:"column" , justifyContent:"center" }}>Montant à encaisser :</div>

                        <div style={{ fontSize: "18px", color: darkColor, fontWeight: 600, display:"flex" , flexDirection:"column" , justifyContent:"center" }}>{price} MAD</div>
                    </div>

                    <div style={{ display: "flex", color: darkColor, justifyContent: "space-between" , padding:"10px 0 10px 0 " }}>
                        <div className="display_montant">Reste a payer :</div>

                        <div className="display_montant_dh">{restApayer()> 0 ? restApayer() : 0} MAD</div>
                    </div>

                 { restApayer() < 0 &&  <div style={{ display: "flex", color: darkColor, justifyContent: "space-between" , padding:"10px 0 10px 0 " }}>
                        <div className="display_montant">A rendre :</div>

                        <div className="display_montant_dh">{-restApayer()} MAD</div>
                    </div> }

                    {/* <span className="dashed-line" /> */}

                

                </div>







              { !printing  ?  <div className='payment_method'>


                    <div className='payment_method_item' onClick={() => {handleClickMethod(1)}}>
                        <div className='payment_method_item-left'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Espece style={{ width: "45px", height: "45px", alignSelf: "center" }} />
                            </div>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "7px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                Espèces
                            </div>


                        </div>
                        <div className='payment_method_item-right'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginRight: "10px", display: "flex", justifyContent: "end" }}>
                      {   sumOfPanierPricing(1) ==0 ?                               
                                <BarsTele style={{ width: "35px", height: "35px", color: darkColor, alignSelf: "center" }} />:
                                <div style={panierSumStyle}> {sumOfPanierPricing(1)} MAD</div> }
                               
                            </div>


                        </div>
                    </div>

                    <div className='payment_method_item'  onClick={() => {handleClickMethod(4)}}>
                        <div className='payment_method_item-left'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Virement style={{ width: "45px", height: "45px", alignSelf: "center" }} />
                            </div>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "7px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                Virement
                            </div>


                        </div>
                        <div className='payment_method_item-right'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginRight: "10px", display: "flex", justifyContent: "end" }}>
                            {   sumOfPanierPricing(4) ==0 ?                               
                                <BarsTele style={{ width: "35px", height: "35px", color: darkColor, alignSelf: "center" }} />:
                                <div style={panierSumStyle}> {sumOfPanierPricing(4)} MAD</div> }
                            </div>


                        </div>
                    </div>

                    <div className='payment_method_item'  onClick={() => {handleClickMethod(2)}}>
                        <div className='payment_method_item-left'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <CR style={{ width: "45px", height: "45px", alignSelf: "center" }} />
                            </div>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "7px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                CB
                            </div>


                        </div>
                        <div className='payment_method_item-right'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginRight: "10px", display: "flex", justifyContent: "end" }}>
                            {   sumOfPanierPricing(2) ==0 ?                               
                                <BarsTele style={{ width: "35px", height: "35px", color: darkColor, alignSelf: "center" }} />:
                                <div style={panierSumStyle}> {sumOfPanierPricing(2)} MAD</div> }
                            </div>


                        </div>
                    </div>

                    <div className='payment_method_item'  onClick={() => {handleClickMethod(3)}}>
                        <div className='payment_method_item-left'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Cheque style={{ width: "45px", height: "45px", alignSelf: "center" }} />
                            </div>
                            <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "7px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                Cheque
                            </div>


                        </div>
                        <div className='payment_method_item-right'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginRight: "10px", display: "flex", justifyContent: "end" }}>
                            {   sumOfPanierPricing(3) ==0 ?                               
                                <BarsTele style={{ width: "35px", height: "35px", color: darkColor, alignSelf: "center" }} />:
                                <div style={panierSumStyle}> {sumOfPanierPricing(3)} MAD</div> }
                            </div>


                        </div>
                    </div>

                    <div className='payment_method_item' onClick={() => {handleClickMethod(5)}}>
                        <div className='payment_method_item-left'>
                            {/* <div style={{ textAlign: "center", fontSize: "20px", marginLeft: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Autre style={{ width: "35px", height: "35px", color: darkColor, alignSelf: "center" }} />
                            </div> */}
                            <div style={{ textAlign: "center", fontSize: "16px", marginLeft: "7px", display: "flex", flexDirection: "column", justifyContent: "center" , fontWeight:600}}>
                               <span className="display_montant" style={{fontSize: "20px" , marginRight:"2px" , color:darkColor}}>Fidélité</span>  (solde : {fideletePerClient} DH)
                            </div>


                        </div>
                        <div className='payment_method_item-right'>
                            <div style={{ textAlign: "center", fontSize: "20px", marginRight: "10px", display: "flex", justifyContent: "end" }}>
                            {   sumOfPanierPricing(5) ==0 ?                               
                                <BarsTele style={{ width: "35px", height: "35px", color: darkColor, alignSelf: "center" }} />:
                                <div style={{ fontSize: "18px", padding:"2px 5px 2px 5px",color: darkColor,borderRadius:"10px",background:lightColor , alignSelf: "center",fontWeight: 600}}> {sumOfPanierPricing(5)} MAD</div> }
                            </div>


                        </div>
                    </div>

                </div> :   <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%", width: "100%" }}>

<Lottie
  options={defaultOptions}
  height={250}
  width={250}



/>
</div>}

                <div className='tele_button' >

                    {/* <div className='tele_button_item'>
                        <div className='tele_button_item_icon'>
                            <Partage style={{ color: darkColor }} />
                        </div>
                        <div className='tele_button_item_text'>Partager</div>
                    </div> */}

                    {  !ticketToPrint  ?  <Button disabled={restApayer() >0} type='button' style={{ background: darkColor,}}  onClick={handleAddTicket} variant='anger' className='text-white btn-rounded btn-block me-2 w-100 py-3 rounded-10 px-3'
                  >Terminer <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{height:"20px" , width:"20px"}}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>


                
                 </Button> :   
                   <ReactToPrint
                    contentToPrint={  <PosTicket data={ticketToPrint} /> } 
                    handleAfterPrint={handleAfterPrint}
                    handleBeforePrint={handleBeforePrint}
                    handleOnBeforeGetContent={handleOnBeforeGetContent}
                    handlePrintingError={handlePrintingError}
                    handleAddTicket={handleAddTicket}
                    style={{}} 
                    label="Imprimer_Ticket_tele" />}


                </div>
            </Container>
            :
            <Container>
                <Calculator mobile={true} handleClickMethod={handleClickMethod} methohType={methohType}/>
            </Container>
            
            }

        </div>




    );
}



export default TeleCalcul;
