import React , {useContext , useEffect} from 'react';
import { Logo } from './svgs/allSvgs';
import Context from 'Context/context';

const PosTicket = ({ data }) => {
  
  
  const styles = {
    body: {
      fontFamily: 'Arial, sans-serif',
      width: '350px',
      lineHeight: '1.5',
      margin: '0',
      padding: '0',
      fontSize: '12pt',
    },
    h1: {
      color: '#333',
      fontSize: '25px',
      marginBottom: '10px',
      textAlign: 'center',
      fontWeight: '700',
    },
    // p: {
    //   margin: '5px 0',
    // },
    table: {
      width: '100%',
      marginTop: '20px',
      borderCollapse: 'collapse',
    },
    tr_:{
        fontWeight:700,
        fontSize:"20px" ,
        borderTop:"2px solid black",
        borderBottom : "2px solid black"


    },
    tr :{
        fontWeight:700,
        fontSize:"20px" ,
    },
    td: {
      padding: '8px',
      textAlign: 'left',
      border: '1px solid #ddd',
    },
    separator: {
      height: '10px',
    },
    total: {
  
      fontWeight: 'bold',
      fontSize:"16px",
      fontWeight:700
    },
    total_: {
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize:"28px",
        fontWeight:800
      },
    nomBoutique: {
      fontSize: '30pt',
      textAlign: 'center',
      background: 'black',
      padding: '5px',
      color: 'white',
    },

    title :{
        fontSize:"15px",
        fontWeight:700,
        // marginLeft:"5px"
    },

    title_ :{
        marginTop:"5px",
        fontSize:"24px",
        fontWeight:700,
        // marginLeft:"5px"
    }


  };

  return (
    <div style={styles.body}>
      <h1 style={styles.h1}>POS Ticket</h1>

      <div style={styles.nomBoutique}>{data?.nom_boutique}</div>
      <div  style={styles.title}>{data?.nemero}</div>
     

      <div style={styles.title}>Ticket ID: {data?.id_ticket}</div>
      <div style={styles.title}>Date de creation: {data?.date_creation || ""}</div>
      <dov style={styles.title}>Date d'encaissement: {data?.date_fin}</dov>
  
      <div style={styles.title}>Client: {data?.nom_client || ""}</div>
      <div style={styles.title}>Caissier: {data?.caissier || ""}</div>

      <div style={styles.separator}></div>

      <table style={styles.table}>
        <tr style={styles.tr_}>
          <td>QTE</td>
          <td style={{textAlign:"center"}}>* ARTICLE *</td>
          <td>PRIX</td>
        </tr>
        {data?.article_vendus.map((article, index) => {
          const articleName = article?.designation?.replace(/[é]/g, 'e').slice(0, 27).padEnd(24) || "";
          return (
            <tr key={index} style={styles.tr}>
              <td>{article.quantity}</td>
              <td>{articleName}</td>
              <td>{article.price}</td>
            </tr>
          );
        })}
      </table>

<div>-----------------------------------------------------------------</div>
<div style={{...styles.total , display:"flex", justifyContent:"space-between"}}>
       <div>Sous-total</div> 
       <div>{data?.sous_total} MAD</div>
      </div>

      {data?.pourcentage_reduction != 0 && <div style={{...styles.total , display:"flex", justifyContent:"space-between"}}>
        <div> Réduction ({data?.pourcentage_reduction.toFixed(2)}%) </div>
        <div>-{data.reduction} MAD</div>                 
         </div>}


      



      <div style={styles.separator}>*******************************************************</div>

      <p style={styles.total_}>Total : {data?.montant} MAD</p>
    

      <div style={styles.separator}>*******************************************************</div>

      <p style={styles.title_}>TVA :</p>
      <table style={styles.table}>
      <tr style={styles.tr_}>
          <td>Taux</td>
          <td>HT</td>
          <td>TVA</td>
          <td>TTC</td>
        </tr>
        {data?.tva?.map((tax, index) => (
          <tr key={index} style={styles.tr}>
            <td>{tax.TAUX}%</td>
            <td>{tax.HT}</td>
            <td>{tax.TVA}</td>
            <td>{tax.TTC}</td>
          </tr>
        ))}
      </table>

      <div >*******************************************************</div>
      <p style={styles.title_}>Moyens de paiement :</p>
      <table style={styles.table}>
        {data?.moyen_paiement?.map((article, index) => (
         <tr style={styles.tr_}>
            <td>{article.designation}</td>
            <td>{article.prix} MAD</td>
          </tr>
        ))}
      </table>

    </div>
  );
};

export default PosTicket;
