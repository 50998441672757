import React, { useState , memo } from 'react';
import { Modal, Button } from 'react-bootstrap';

const BottomSheetModalPanel = ({ModalContent ,title ,openPanel,setOpenPanel }) => {


  const handleShowModal = () => {
    setOpenPanel(true);
  };

  const handleCloseModal = () => {
    setOpenPanel(false);
  };

  const modalStyle = {
    position: 'fixed',
    left: 0,
    bottom: openPanel ? 0 : '-100%',
    zIndex: 1050,
    width: '100%',
    height: 'auto',
    margin: 0,
    padding: '1rem',
    border: 'none',
    borderRadius: 0,
    backgroundColor: '#fff',
    transition: 'bottom 0.3s ease-in-out',
   
    maxWidth: "none !important"
  };

  const contentStyle = {
    width: '100%',
  
  };

  return (
    <>
      {/* <Button onClick={handleShowModal} className='text-white bg-btn-pink btn-rounded btn-block me-2 w-100 py-3 rounded-10 px-3'>{title}</Button> */}

      <Modal
        show={openPanel}
        onHide={handleCloseModal}
        backdrop={false}
        style={modalStyle}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Panier</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <div style={contentStyle}>
          <h1>Panier</h1>
            <ModalContent handleCloseModal={handleCloseModal} />
          </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={handleCloseModal}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

// function areEqual(prevProps, nextProps) {
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */

//   // return prevProps.openPanel === nextProps.openPanel
//   //   && prevProps.title === nextProps.title
//   return true 
//     // Add any other prop comparisons you deem necessary
// }

export default BottomSheetModalPanel;
