import React, { Component } from "react";
import { render } from "react-dom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'; // Import the keyboard icon
const styles = {
    input: {
      width: '100%',
      height: '100px',
      padding: '20px',
      fontSize: '20px',
      border: 'none',
      boxSizing: 'border-box',
    },
    keyboardContainer: {
      maxWidth: '850px',
      minWidth: '750px',
    },
    keyboardTheme: {
      border: '5px solid rgba(255, 0, 0, 0.7)',
      borderRadius: '10px',
      margin: '10px',
      width: 'calc(100% - 20px)',
    },
    keyboardButtonRed: {
      background: 'rgba(255, 0, 0, 0.7)',
      color: 'white',
    },
    keyboardButtonHighlight: {
      boxShadow: '0px 0px 40px 5px rgba(255, 255, 0, 0.5)',
      zIndex: 1,
    },
  };

  const closeIconStyle = {
    position: 'absolute',
   // Adjust the horizontal position to the right

    transform: 'translateY(-50%)',
    fontSize: '25px', // Adjust the icon size
    color: 'red', // Set the color to red
    cursor: 'pointer', // Add a pointer cursor for the close icon
};
  

  


class KeyboardApp extends Component {
  state = {
    layoutName: "default",
    input: ""
  };

  onChange = input => {
    this.setState({
      input: input
    });
    console.log("Input changed", input);
    this.props.onProductSearch(input)
  };

  onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = event => {
    let input = event.target.value;
    this.setState(
      {
        input: input
      },
      () => {
        this.keyboard.setInput(input);
        this.props.onProductSearch(input)
      }
    );
  };

  render() {
    return (
      <div style={styles.keyboardContainer}>
         {/* <FontAwesomeIcon icon={faTimesCircle} style={closeIconStyle} onClick={this.props.closeKeyboard} /> */}
      
        <Keyboard
        style={styles.keyboardTheme }
          keyboardRef={r => (this.keyboard = r)}
          onChange={input => this.onChange(input)}
          onKeyPress={button => this.onKeyPress(button)}
          theme={"hg-theme-default hg-layout-default myTheme"}
          layoutName={this.state.layoutName}
          layout={{
            default: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              "{tab} q w e r t y u i o p [ ] \\",
              "{lock} a s d f g h j k l ; ' {enter}",
              "{shift} z x c v b n m , . / {shift}",
              ".com @ {space}"
            ],
            shift: [
              "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
              "{tab} Q W E R T Y U I O P { } |",
              '{lock} A S D F G H J K L : " {enter}',
              "{shift} Z X C V B N M < > ? {shift}",
              ".com @ {space}"
            ]
          }}
          buttonTheme={[
            {
              class: "hg-red",
              buttons: "Q W E R T Y q w e r t y"
            },
            {
              class: "hg-highlight",
              buttons: "Q q"
            }
          ]}
        />
      </div>
    );
  }
}

export default KeyboardApp


