import React from 'react';
import Select from 'react-select';
import { darkColor, lightColor, lightGray, mainColor } from './constants/constans';

const Brands = (props) => {
  const { setBrand, allFamilles ,selectedOption} = props;



  // Create an array of options for react-select
  const options = allFamilles.map((brand) => ({
    value: brand.id,
    label: brand.attributes.name,
  }));
  
  console.log("famille option" , options ,allFamilles)
  // Handle selection change
  const handleSelectChange = (selectedOption) => {
    setBrand(selectedOption ? selectedOption.label : 0);
  };

  return (
    <div className='brand-container' 
    
    style={{width:"96%" ,
    //  marginLeft:"10px" ,
    
     border:`1px solid ${mainColor}` , 
     borderRadius:"10px",overflow:"hidden"}}>
    <Select
      options={[{ value: 0, label: 'All familles' }, ...options]}
      onChange={handleSelectChange}
      isClearable
    //   value={selectedOption}
      menuPortalTarget={document.body} // Render dropdown outside the parent container
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: 'white',
          border: 'none',
          boxShadow: 'none',
          borderRadius: '0.25rem',
          background : lightGray,
          height: '80%',
          paddingLeft: '10px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
      }}
      isSearchable
      placeholder="Familles"
    />
  </div>
  );
};

export default Brands;

// import React, {useEffect, useState} from 'react';
// import Swiper from 'react-id-swiper';
// // import 'swiper/swiper.scss';
// import {Nav, Button} from 'react-bootstrap';
// // import {connect} from 'react-redux';
// // import {fetchAllBrands} from '../../store/action/brandsAction';
// // import {fetchBrandClickable} from '../../store/action/pos/posAllProductAction';
// import { getFormattedMessage } from './shared/sharedMethod';

// const swiperParams = {
//     slidesPerView: 'auto',
//     observer: true,
// };

// const brands = [
//     {
//         id:1,
//         attributes :{
//             name:"hamza"
//         }
//     },
//     {
//         id:2,
//         attributes :{
//             name:"hamza"
//         }
//     },
//     {
//         id:3,
//         attributes :{
//             name:"hamza"
//         }
//     }
// ]

// const Brands = (props) => {
//     const { fetchAllBrands, setBrand, categoryId, selectedOption , allFamilles} = props;
//     const [productBrandName, setProductBrandName] = useState(0);

//     // useEffect(() => {
//     //     fetchAllBrands();
//     // }, []);

//     //filter brand function
//     const onSelectBrand = (brand) => {
//         console.log("famille", allFamilles)
//         setBrand(brand);
//         setProductBrandName(brand);
//     };

//     let brandsItem = allFamilles && allFamilles.map((brand, index) => {
//         return (
//             <Nav.Item className='button-list__item' key={index}>
//                 <Button variant='light'
//                     className={`custom-btn-size w-100 ${productBrandName === brand.id ? 'button-list__item-active text-white' : ''}`}
//                     onClick={() => onSelectBrand(brand.id)}>
//                     {brand.attributes.name}
//                 </Button>
//             </Nav.Item>
//         )
//     });

//     return (
//         <Nav className='button-list d-flex flex-nowrap'>
//             <Nav.Item className='button-list__item me-2'>
//                 <Button variant='light'
//                     className={`text-nowrap custom-btn-size ${productBrandName === 0 ? 'button-list__item-active text-white' : ''}`}
//                     onClick={() => onSelectBrand(0)}>
//                     All familles
//                 </Button>
//             </Nav.Item>
//             <div style={{
//                 display:"flex", marginRight: "10px",overflow:"scroll"
//             }}>
//                 {brandsItem}
//             </div>
//         </Nav>
//     )
// };

// const mapStateToProps = (state) => {
//     const {brands} = state;
//     return {brands}
// };

// export default Brands;