
import React, { useState , useEffect }from "react";
import Context from "./context";
import _ from "lodash";
import axios from "axios"
import moment from "moment"
import { addCaisseOverture, fetchCaisseOpen ,fetchFidiltePrice } from "Fetch/products";
//import Context from 'views/Context/Context'

const Provider = (props) => {

const [productsPanier , setProductPanier] = useState([])
const [typePayment , setTypePayment] = useState("")
const [allClients , setAllClients] = useState([])
const [ allSellersContext , setAllSellersContext] = useState([])

const [total_price , setTotal_price ] = useState()
const [panierPricing , setPanierPricing ] = useState([])
const [ selectedCustomerOption , setSelectedCustomerOption] = useState()
const [seller, setSeller] = useState()
const [client , setClient] = useState();
const [toasts, setToasts] = useState([]);
const [isCaisseOpen_state , setIsCaisseOpen_state] = useState(false)
const [isCloseCaisse , setIsCloseCaisse] = useState(false)
const [montantAppLock , setMontantAppLock] = useState(0)
const [allProductsContext , setAllProductsContext ] = useState([])
const [ticketZ , setTicketZ] = useState()
const [ticketToPrint , setTicketToPrint] = useState(null)
const [id_caisse_table , setId_caisse_table] = useState()
const [fideletePerClient , setFideletePerClient] = useState()
const [fideletePerClient_first , setFideletePerClient_first] = useState()
const [total_price_copi , setTotal_price_copi ] = useState()

const addToast = (type, content , data ) => {
  setToasts((prevToasts) => [...prevToasts, { type, content , data : data ? data : [] }]);
 
};

const showArrayCalculation = (calculation) => {
  if (calculation.length > 1 && calculation[0] == 0) {
    return calculation.slice(1);
  } else {
    return calculation;
  }
};

function concatenateNumbersFromArray() {
  const result = montantAppLock.length >0 ? parseInt(montantAppLock?.join(''), 10) : 0;
  return isNaN(result) ? '' : result.toString();
}


const removeToast = (index) => {
  setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
};

const getIsCaisseOpen = async ()=>{
  const data = await fetchCaisseOpen()
  localStorage.setItem("caisse_open" ,data[0]?.date_fermeture !=1 ? true : false )
  localStorage.setItem("date_ouverture" ,data[0]?.date_ouverture  )
  setId_caisse_table(data[0]?.id_caisse_table)
  if(data.length == 0 ) {
    setIsCaisseOpen_state(true)
    console.log("caisse open ss",data[0]?.date_fermeture)

  }else {
    setIsCaisseOpen_state(data[0]?.date_fermeture ? true : false)
    console.log("caisse open",data[0]?.date_fermeture)
  }



}

const addCaisseClose = async ()=>{
  const num = concatenateNumbersFromArray()

    const res = await addCaisseOverture(num || 0)
    if(res.result == "ok"){
      await getIsCaisseOpen()
      setMontantAppLock(0)
      setProductPanier([])

      return true }  
}

const getPriceFidelete =async  ()=>{
  const res = await fetchFidiltePrice(selectedCustomerOption?.value)
  setFideletePerClient(res.solde)
  setFideletePerClient_first(res.solde)

}

useEffect(()=>{

  if(selectedCustomerOption?.value){
    getPriceFidelete()

  }else {
    setFideletePerClient(0)

  }


},[selectedCustomerOption])

useEffect(()=>{

  if(productsPanier.length != 0) {
    setTotal_price_copi(productsPanier.filter(p=>p.statut != 0 ).reduce((acc, object) => acc + ( (object.id_remise ? object.discountedPrice : object.price ) * object.quantity), 0))
  }

  setTotal_price(productsPanier.filter(p=>p.statut != 0 ).reduce((acc, object) => acc + ( (object.id_remise ? object.discountedPrice : object.price ) * object.quantity), 0))
  setProductPanier(productsPanier)
},[productsPanier])

  return (
    <Context.Provider
      value={{
        productsPanier , setProductPanier,
        typePayment , setTypePayment ,
        total_price , setTotal_price ,
        panierPricing , setPanierPricing ,
        setSelectedCustomerOption, selectedCustomerOption,
        seller, setSeller ,
        allClients , setAllClients,
        allSellersContext , setAllSellersContext,
        allProductsContext , setAllProductsContext ,
        toasts, setToasts ,addToast ,removeToast,
        client , setClient,getIsCaisseOpen,addCaisseClose,
        isCaisseOpen_state , setIsCaisseOpen_state,
        isCloseCaisse , setIsCloseCaisse,
        montantAppLock , setMontantAppLock,
        ticketZ , setTicketZ,
        ticketToPrint , setTicketToPrint,
        fideletePerClient , setFideletePerClient,
        total_price_copi , setTotal_price_copi,
        fideletePerClient_first , setFideletePerClient_first,
        getPriceFidelete
       
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default Provider;