import React, { useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios"
import { darkColor } from '../constants/constans';
import ModalMiddle from './ModalMiddle';
import ModalTele from './modalTele';
import { addToast } from 'components/shared/toast';
// import {sendPrintJob }from "../print"
import Context from 'Context/context';

const BottomSheetModal = ({ ModalContent, title, AppLock, showModal, price, setShowModal, mobile }) => {
  //   const [showModal, setShowModal] = useState(false);
  const {

    productsPanier, setProductPanier,
    typePayment, setTypePayment,
    total_price, setTotal_price,
    panierPricing, setPanierPricing,
    setSelectedCustomerOption, selectedCustomerOption, setTicketToPrint,
    seller, setSeller, setMontantAppLock, addToast, getIsCaisseOpen, isCloseCaisse, isCaisseOpen_state, addCaisseClose, setIsCloseCaisse,getPriceFidelete } = useContext(Context)



  const handleShowModal = () => {
    if (productsPanier.length == 0 || productsPanier.filter(p => p.statut != 0).length == 0) {
      addToast("warning", "Veuillez remplir le panier")
      return

    } else {
      setShowModal(true);

    }

  };

  const handleCloseModal = () => {
  
      setShowModal(false);
      setMontantAppLock(0)
      setPanierPricing([])
      setTicketToPrint(null)
      getPriceFidelete()

   

  };


  const modalStyle = {
    position: 'fixed',
    left: 0,
    bottom: showModal ? 0 : '-100%',
    zIndex: 1050,
    width: '100%',
    height: 'auto',
    margin: 0,
    padding: '1rem',
    border: 'none',

    borderRadius: 0,
    backgroundColor: '#7C81AD',
    transition: 'bottom 0.3s ease-in-out',
    maxWidth: "none !important"
  };

  const contentStyle = {
    width: '100%',
    // background:"red"
    backgroundColor: "#7C81AD",

  };

  return (
    <>
      {

        !AppLock &&

        <Button onClick={handleShowModal} className='text-white btn-danger btn-rounded btn-block me-2  py-3 rounded-10 px-3 mb-2' style={{
          color: 'white',
          backgroundColor: darkColor, display: "flex", borderRadius: "7px", justifyContent: "space-between", width: "100%"
        }}>

          <div style={{ fontWeight: 700 }}>{title} </div>

          <div>{price} MAD</div>

          {/* <i className='ms-2 fa fa-money-bill'/> */}
        </Button>}


      <ModalMiddle show={showModal} onClose={handleCloseModal} children={<ModalContent handleCloseModal={handleCloseModal} />} mobile={mobile} />

    </>
  );
};

export default BottomSheetModal;
