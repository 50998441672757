import React, { useEffect, useState, useContext } from 'react'

import { Modal, Button } from 'react-bootstrap';

import Context from '../../Context/context';
import useSound from 'use-sound';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import {addTicketEnAttente} from "../../Fetch/products"


function ModalDetailProduct(props) {
    const [play] = useSound('https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3');

    const { products, showHoldName, toggleShowHoldName } = props
    const { productsPanier, setProductPanier ,setSeller , seller ,client ,total_price} = useContext(Context)
    const [holdName, setHoldName] = useState(productsPanier[0]?.holdName || null)

    useEffect(() => {
     console.log("jejejejejejejejeje",productsPanier)
     setHoldName(productsPanier[0]?.holdname)
       
    }, [productsPanier])

    const handleHoldName = (e) => {
        setHoldName(e.target.value)
    }

    const handleSaveName = async () => {

        // const panierLocal = JSON.parse(localStorage.getItem("holdPanier"));


        // if (productsPanier[0]?.uuid) {
        //     // Find the index of the array that contains the UUID
        //     const index = panierLocal.findIndex((arr) => arr[0].uuid === productsPanier[0].uuid);

        //     if (index !== -1) {
        //         // Create a copy of the array
        //         const updatedData = [...productsPanier];

        //         // Update the "holdName" property of the first object
        //         setHoldName(productsPanier[0].name)
        //         updatedData[0].seller = seller;

        //         // Replace the array at the found index
        //         panierLocal[index] = updatedData;

        //         // Update the local storage with the modified array
        //         localStorage.setItem("holdPanier", JSON.stringify(panierLocal));
        //         toggleShowHoldName();
        //         setHoldName("");
        //         setProductPanier([])
        //         setSeller({})
        //     }
        // }
        // // Check if the array has any objects
        // if (productsPanier.length > 0 && !productsPanier[0]?.uuid) {

        //     // Create a copy of the array
        //     const updatedData = [...productsPanier];

        //     // Update the "holdName" property of the first object
        //     updatedData[0].holdName = holdName;
        //     updatedData[0].uuid = uuidv4();
        //     updatedData[0].seller = seller;

        //     // Update the state with the modified array
        //     setProductPanier([]);
        //     localStorage.setItem("holdPanier", JSON.stringify([...panierLocal, updatedData]))
        //     setSeller({})
        //     toggleShowHoldName()
        
        //     setHoldName("")
            

        // }
        // if (productsPanier[0]?.alreadyHolded ) {

        //     // Create a copy of the array
        //     const updatedData = [...productsPanier];

        //     // Update the "holdName" property of the first object
        //     updatedData[0].holdName = holdName;
        //     updatedData[0].seller = seller;

        //     setProductPanier([]);
        //     localStorage.setItem("holdPanier", JSON.stringify([...panierLocal, updatedData]))
        //     toggleShowHoldName()
        //     setHoldName("")
        //     setSeller({})
          

        // }
        const products = productsPanier.map(p =>{
            return {
                id_remise : p.id_remise || "-1",
                id_produit : p.id_produit ,
                id_prix : p.id_prix ,
                id_user : p.personnelSeller || "-1",
                count : p.quantity ,
                prix_ttc :p.quantity *
                (p.id_remise
                    ? parseFloat(p.discountedPrice).toFixed(2)
                    : parseFloat(p.price).toFixed(2)),
                prix_p : parseFloat( p.price),
                id_ticket_cle : p.id_ticket_cle || "-1",
                statut : p.statut

            }
        })



        const ticket = {
            id_list_ticket : productsPanier[0]?.id_list_ticket || "-1",
            id_user : seller?.value || "-1", 
            total : total_price
             ,
            id_client:"-1" ,
            id_client:client?.value || "-1",
            nom_ticket:holdName,
            products : products

        }

        console.log("ticket : ",ticket)

        try {
            const res = await addTicketEnAttente(ticket)
            setProductPanier([]);
            setSeller({})
            toggleShowHoldName()
            setHoldName("")
            
        } catch (error) {
            console.log(error)
            
        }
        

    };



    return (
        <>
            <div>
                <Modal
                    size="md"
                    aria-labelledby="example-custom-modal-styling-title"
                    show={showHoldName}
                    onHide={() => toggleShowHoldName()}
                    className='registerModel-content'
                >
                    <Modal.Header closeButton className='p-4'>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        Donnez un titre à votre ticket : {}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='card-body'>
                            <div className='row'>
                                <div >
                                    <label className='form-label'>
                                    Titre du ticket :{productsPanier[0]?.holdName}
                                    </label>
                                    <span className='required' />
                                    <input type='text' name='name'
                                        value={holdName}
                                        placeholder={"Titre ticket"}
                                        className='form-control'

                                        onChange={handleHoldName}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
                        <Button disable={!holdName} onClick={handleSaveName} style={{ width: "300px" }}>Enregistrer</Button>
                    </div>
                </Modal>
            </div>

        </>
    )
}



export default ModalDetailProduct;
