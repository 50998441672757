import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { fetchCaissier } from "./Fetch/products"
// import md5 from 'md5';
import { SHA256 } from 'crypto-js';
import { FaSignOutAlt } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

import md5 from 'js-md5';
function CaisseLogin() {
    const [selectedOption, setSelectedOption] = useState(null);
    const [allCaissier, setAllCaissier] = useState([]);
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({ email: '', password: '' });
  
    const getAllCaissier = async () => {
      const res_caissier = await fetchCaissier();
      console.log("fetchCaissier", res_caissier);
  
      if (res_caissier.length > 0) {
        const options = res_caissier?.map((item) => {
          return {
            label: item.nom_serveur,
            value: item.id_serveur,
            pass: item.password,
          };
        });
        setAllCaissier(options);
      }
    };
  
    const handleLogin = () => {
      console.log(selectedOption);
  
      const md5Hash = md5(password);
  
      if (!selectedOption) {
        setErrors({ ...errors, email: 'Please select an option.' });
        return;
      }
  
      if (!password) {
        setErrors({ ...errors, password: 'Please enter your password.' });
        return;
      }
  
      if (md5Hash === selectedOption.pass) {
        localStorage.setItem("caissier", selectedOption.pass);
        localStorage.setItem("caissier_id", selectedOption.value);
        window.location.replace("/caisse");
      } else {
        setErrors({ ...errors, password: 'Invalid password.' });
      }
  
      console.log(md5Hash);
    };
  
    const handleChangePass = (e) => {
      setPassword(e.target.value);
    };
  
    useEffect(() => {
      getAllCaissier();
    }, []);

    const gradientBackground = {

        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const selectStyle = {
        width: '350px',
        marginBottom: '10px',
    };
    const logoutBarStyle = {
        // backgroundColor: 'rgba(255, 0, 0, 0.5)', // Red background with opacity
        padding: '10px', // Add some padding to the bar
        display: 'flex',
        justifyContent: 'flex-end', // Align the button to the right
      };

    const  errorText= {
        color: 'red',
        fontSize: '14px',
        marginTop: '5px',
      }


    const wrapperStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        color: '#eeeeee',
        background: '#222831',
        maxWidth: '550px',
        borderRadius: '8px',
        boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.75)',

    };

    const h2Style = {
        margin: '35px 0',
        fontWeight: 'bold',
        fontSize: '20px',
    };

    const groupStyle = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        marginTop: '35px',
        padding: '0 35px',
    };

    const labelStyle = {
        position: 'absolute',
        transition: 'all 0.5s ease',
        pointerEvents: 'none',
    };

    const inputStyle = {
        width: '350px',
        border: 'none',
        borderRadius: "5px",
        paddingLeft: "4px",
        borderBottom: '1px solid #ced4da', // Use Bootstrap's default input border color
        outline: 'none',
        padding: '5px 0 5px 8px', // Add some padding for better visual appearance
        // color: '#495057', // Set text color to Bootstrap's default
    };

    const btnStyle = {
        color: '#eeeeee',
        backgroundColor: 'transparent',
        outline: 'none',
        padding: '3px',
        paddingBottom: '0',
        margin: '35px 0',
        border: 'none',
        borderBottom: 'solid 1px white',
        transition: 'all 1s linear',
    };

    const footerStyle = {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        width: '100%',
        height: '100px',
        backgroundColor: 'rgb(176, 19, 16)',
        clipPath: 'polygon(63% 68%, 100% 25%, 100% 100%, 0 100%, 0 0)',
    };
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
            color: state.isSelected ? 'white' : 'black',
            padding: 10,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '4px',
            border: '1px solid',
            borderColor: state.isFocused ? '#007bff' : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null,
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '4px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        }),
    };
    const handleLogout = () => {
        // Clear the localStorage
        localStorage.clear();
      
        // You can also add any additional logout-related actions here if needed
      
        // Redirect the user or perform any other actions after logout
        // For example, you can navigate the user to the login page
        window.location.replace("/login"); // Replace with your actual login page URL
      };
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleLogin();
        }
      };

    return (
        <div>
        {/* Log Out button bar */}
        <div style={logoutBarStyle}>
          <Button
            variant="danger"
            className="deconnect-button"
            onClick={handleLogout}
          >
            <FaSignOutAlt /> Se deconnecter
          </Button>
        </div>
  
        <div style={gradientBackground} onKeyPress={handleKeyPress}>
          <form style={wrapperStyle}>
            <h2 style={h2Style}>Caissier</h2>
            <section style={groupStyle} className="group">
              <div style={selectStyle}>
                <ReactSelect
                  value={selectedOption}
                  onChange={(option) => {
                    setSelectedOption(option);
                    setErrors({ ...errors, email: '' });
                  }}
                  options={allCaissier}
                  styles={customStyles}
                  autoComplete="new-password"
                />
              </div>
              {errors.email && <p style={errorText}>{errors.email}</p>}
            </section>
            <section style={groupStyle} className="group">
              <input
                type="password"
                style={{
                  ...inputStyle,
                  borderBottomColor: errors.password ? 'red' : inputStyle.borderBottomColor,
                }}
                name="password"
                value={password}
                placeholder="Password"
                onChange={handleChangePass}
                autoComplete="new-password"
                required
              />
              {errors.password && <p style={errorText}>{errors.password}</p>}
            </section>
  
            <div style={{ display: "flex", justifyContent: "center", width: "70%" }}>
              <button
                type="button"
                onClick={handleLogin}
                className="btn"
                style={{
                  ...btnStyle,
                //   padding: "5px 5px 5px 5px",
                //   backgroundColor: 'red',
                //   borderRadius: '10px',
                //   marginRight: '10px', // Add spacing between buttons
                }}
              >
                Se connecter
              </button>
{/*   
              <button
                type="button"
                style={{
                  ...btnStyle,
                  padding: "5px 5px 5px 5px",
                  backgroundColor: 'red',
                  borderRadius: '10px',
                  marginRight: '10px', // Add spacing between buttons
                }}
                onClick={handleLogin}
                className="btn"
              >
                Se deconnecter
              </button> */}
            </div>
  
            <span style={footerStyle} className="footer"></span>
          </form>
        </div>
      </div>
      );
}

export default CaisseLogin;