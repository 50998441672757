import React, { useEffect, useState, useRef ,useContext} from 'react';
import { Col, Button } from 'react-bootstrap';
import { fetchAllProducts } from '../../Fetch/products';
import useSound from "use-sound";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faKeyboard, faTimesCircle } from '@fortawesome/free-solid-svg-icons'; // Import the keyboard icon
import KeyboardApp from '../keyboard';
import { darkColor, mainColor, lightGray, semiDarkColor, LightColorDarker } from '../../components/constants/constans';
import { FaSignOutAlt } from 'react-icons/fa';
import Context from '../../Context/context';
import { BsChevronDoubleLeft } from 'react-icons/bs';

const ProductSearchbar = (props) => {
    const {
        posAllProducts,
        setAllProducts,
        handleLogout,
        addToCart
    } = props;

    const [searchString, setSearchString] = useState('');
    const [keyDown, setKeyDown] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false); // State to track keyboard visibility
    const [play] = useSound('https://s3.amazonaws.com/freecodecamp/drums/Heater-4_1.mp3');

    const searchContainerRef = useRef(null);
    const keyboardRef = useRef(null); // Define keyboardRef
    const {allProductsContext,setAllProductsContext , addToast,setProductPanier,productsPanier} = useContext(Context)

    const addToCart_dec = (item , products) => {
        console.log("decl" ,item )
        // play();
        const product =
        {
            personnelSeller : "-1",
            id:item.id_prix ,
            id_produit :products.id,
            id_prix: item.id_prix,
            name: `${products.attributes.name}- ${item?.name}`,
            code: item.code,
            price: item.price,
            id_categorie:item.id_categorie,
            statut:1
        }

        const existingProductIndex = productsPanier.findIndex((p) => p.id === product.id);
        const existingProduct = productsPanier.find((p) => p.id === product.id);



        if (existingProductIndex !== -1 && existingProduct.statut == 1) {
            const updatedPanel = [...productsPanier];

            updatedPanel[existingProductIndex].quantity += 1;


            setProductPanier(updatedPanel);
            // toggleProductDetail()
        } 
        
        else if(existingProductIndex !== -1 && existingProduct.statut == 0){
            const updatedPanel = productsPanier.filter(p => p.id != product.id );
         
            setProductPanier([...updatedPanel, { ...product, quantity: 1 }]);
            // toggleProductDetail()
        }
        else {
            setProductPanier([...productsPanier, { ...product, quantity: 1 }]);
            // toggleProductDetail()

        }
    };


   

    const onProductSearch = async (code) => {
        setSearchString(code);
        // Fetch all products if the search bar is empty
        if (code.trim() === '') {
        
            setAllProducts(allProductsContext);
        } else {
            
            if(allProductsContext && allProductsContext.length > 0){
                let filteredProducts = allProductsContext.filter((product) =>
            (product.attributes.name.toLowerCase().includes(code.toLowerCase()) ||
                product.declinaison[0].code.includes(code))
            );
            setAllProducts(filteredProducts);

            }
           
if (allProductsContext.some(product => product.declinaison.some(decl => decl.code === code))) {
    const foundProduct = allProductsContext.find(product => product.declinaison.some(decl => decl.code === code));
    console.log("foundProduct",foundProduct)
    if (foundProduct) {
        const declinaisonWithCode = foundProduct.declinaison.find(declinaison => declinaison.code === code);
      
        const foundProductObject = {
          id: declinaisonWithCode.id_prix,
          id_produit: foundProduct.id,
          id_prix: declinaisonWithCode.id_prix,
          name:`${declinaisonWithCode?.label}`,
          personnelSeller: "-1",
          id_categorie: foundProduct.id_categorie,
          code: declinaisonWithCode.code,
          price: declinaisonWithCode.price,
          statut: 1
        };
        addToCart_dec(foundProductObject ,foundProduct );
        addToast("success", `Produit : ${foundProduct.attributes.name} a été ajouté avec succès`);
      
        // Now, you have the desired object in foundProductObject
        console.log("Found Product Object:", foundProductObject);
      } 



    setAllProducts(allProductsContext);
    setSearchString("");
} 
            // Your logic to filter the products based on the search code/name and declinaison      
        }
    }

    const searchIconStyle = {
        // position: 'absolute',
        // top: '50%',
        // left: '10px',
        // transform: 'translateY(-50%)',
        fontSize: '25px',
        color: mainColor,
    };
    const keyboardIconStyle = {
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        fontSize: '25px',
        color: '#d4d4d4',
    };
    const closeIconStyle = {
        position: 'absolute',
        top: '50%',
        right: '40px',
        transform: 'translateY(-50%)',
        fontSize: '25px',
        color: 'red',
        cursor: 'pointer',
    };

    const inputFocus = () => {
        let searchInput = document.querySelector('input[data-test="search-input"]');
        searchInput.focus();
    };

    const toggleKeyboard = () => {
        setShowKeyboard(!showKeyboard); // Toggle the keyboard visibility
    };

    const closeKeyboard = () => {
        setShowKeyboard(false);
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.altKey && event.code === 'KeyQ') {
                event.preventDefault();
                inputFocus();
            }
        };

        const clickOutsideHandler = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                // Clicked outside of the search container
                if (keyboardRef.current && keyboardRef.current.keyboardDOM && !keyboardRef.current.keyboardDOM.contains(event.target)) {
                    // Clicked outside of the keyboard
                    closeKeyboard();
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        document.addEventListener('mousedown', clickOutsideHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener('mousedown', clickOutsideHandler);
        };
    }, []);

    return (
        <Col className='position-relative d-flex  col-lg12 col-12' style={{

            display: "flex",
            justifyContent: 'space-between'
        }}>

            <div class="searchBar">
                <input 
                    id="searchQueryInput"
                    placeholder="Scanner ou rechercher un produit par nom ou code barre"
                    value={searchString}
                    onChange={(e) => onProductSearch(e.target.value)}
                    type="text" name="searchQueryInput" />
                <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">

                    <FontAwesomeIcon icon={faSearch} className="search-icon" style={searchIconStyle} />
                </button>


            </div>

            <div>
                <Button
                    variant="danger"
                    className="logout-button"

                    onClick={handleLogout}
                >
                    <FaSignOutAlt className='fa-sign-out-alt' />
                    <div className="mobile-hide-text">Se déconnecter</div>
                </Button>


            </div>



            {/* {showKeyboard && 
            <div style={{ display: "flex", justifyContent: "end" }}>
                <div  style={{ display: "flex", justifyContent: "end" }}>
                {showKeyboard && (
                    <FontAwesomeIcon icon={faTimesCircle} style={closeIconStyle} onClick={closeKeyboard} />
                )}
                </div>
                <KeyboardApp 
                    onProductSearch={onProductSearch}
                    closeKeyboard={closeKeyboard}
                    keyboardRef={keyboardRef} // Pass the keyboardRef to KeyboardApp
                />
            </div>
            } */}
            {/* Render the keyboard based on showKeyboard state */}
        </Col>
    );
};

export default ProductSearchbar;
